import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';
import { ApiUserObject } from '../api/admin/users';
import { ApiResponse, ApiList } from '../api/api';
import localforage from 'localforage';

export interface UsersState {
  users: { [key: number]: ApiUserObject },
  whitelist: { [key: number]: ApiUserObject },
  isUsersFetching: boolean;
  isWhitelistFetching: boolean;
  hasError: boolean;
  error: any;
};

// Action creators
export const storeUsers = createAction('storeUsers');
export const storeWhitelist = createAction('storeWhitelist');
export const resetUsersError = createAction('resetUsersError');
export const setUsersError = createAction('setUsersError', null, () => ({ isError: true }));
export const filterUser = createAction('filterUser');
export const setUsersFetchingState = createAction('setUsersFetchingState');
export const setWhitelistFetchingState = createAction('setWhitelistFetchingState');

// Async action creators
export function fetchUsers(request: ApiResponse<ApiList<ApiUserObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => {
        dispatch(storeUsers(res.data))
      })
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)));
  }
}

export function fetchWhitelist(request: ApiResponse<ApiList<ApiUserObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setWhitelistFetchingState(true));
    return request
      .then(res => dispatch(storeWhitelist(res.data)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setWhitelistFetchingState(false)));
  }
}

export function updateUser(request: ApiResponse<ApiUserObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(storeUsers([res])))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)));
  };
}

export function updateWhitelistUser(request: ApiResponse<ApiUserObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setWhitelistFetchingState(true));
    return request
      .then(res => dispatch(storeWhitelist([res])))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setWhitelistFetchingState(false)));
  }
}

interface DeleteUserProps {
  id: number,
  request: ApiResponse<{}>,
};
export function deleteUser({ id, request }: DeleteUserProps) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(filterUser(id)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)))
  }
}

export async function fetchUserUsers(request: ApiResponse<ApiUserObject[]>) {
  let res = await Promise.resolve(request)
  return {
    type: storeUsers.toString(),
    payload: res
  }
}

export function setUsers(users: any[]) {
  return {
    type: storeUsers.toString(),
    payload: users
  }
}

const usersReducer = handleActions({
  [storeUsers.toString()]: (state, action) => {
    const users: ApiUserObject[] = action.payload;
    const data = state
    // Map each new user to the current state users. The ID will be used for key.
    users.forEach(user => {
      data.users[user.id] = user;
    });

    localforage.setItem('users', users);
    return data
  },

  [filterUser.toString()]: (state, action) => {
    // // A user can be present in the whitelist or the users list. This will filter them from both.
    // state.users = pickBy<ApiUserObject>(state.users, (v: ApiUserObject, k: string) => {
    //   return parseInt(k) !== action.payload;
    // });
    // state.whitelist = pickBy<ApiUserObject>(state.whitelist, (v: ApiUserObject, k: string) => {
    //   return parseInt(k) !== action.payload;
    // });
  },

  [setUsersError.toString()]: (state, action) => {
    let data = state
    data.hasError = true;

    // Try parsing the error message, it might be json.
    try {
      data.error = JSON.parse(action.payload)
    } catch(e) {
      data.error = action.payload;
    }
    return {
      ...data
    }

  },

  [resetUsersError.toString()]: (state) => {
    return {
      ...state,
      hasError: false,
      error: undefined
    }
  },

  [storeWhitelist.toString()]: (state, action) => {
    const whitelist: ApiUserObject[] = action.payload;
    const data = state
    whitelist.forEach(user => {
      data.whitelist[user.id] = user;
    });
    return {
      ...data
    }
  },

  [setUsersFetchingState.toString()]: (state, action) => {
    // When something changes with the user, the error is no longer valid.
    const data =state
    if (action.payload) {
      data.hasError = false;
      data.error = undefined;
    }
    data.isUsersFetching = action.payload;
    return {
      ...data
    }
  },
  
  [setWhitelistFetchingState.toString()]: (state, action) => {
    // When something changes with the whitelist, the error is no longer valid.
    const data= state
    if (action.payload) {
      data.hasError = false;
      data.error = undefined;
    }
    data.isWhitelistFetching = action.payload;
    return {
      ...data
    }
  },
},
{
  users: {},
  whitelist: {},
  isUsersFetching: false,
  isWhitelistFetching: false,
  hasError: false,
  error: null,
});

export default usersReducer;
