import React from "react";
import "./grid.scss";

const Grid = ({
	children,
	columns,
	className,
	gap,
}: {
	children?: any;
	columns: number | string;
	className?: string;
	gap?: number;
}) => {
	let style: any = {
		gridTemplateColumns:
			typeof columns === "string" ? columns : `repeat(${columns}, 1fr)`,
	};

	if (gap) style.gridGap = gap;

	return (
		<div style={style} className={`grid ${className || ""}`}>
			{children || false}
		</div>
	);
};

Grid.defaultProps = {
	columns: 1,
	gap: 30,
};

export default Grid;
