import React, { useState, useEffect } from "react";
import { Segment, Table, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import Layout from "../../../components/Layout";
import api from "../../../api/api";

const Squads = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchSquads().then(() => setLoading(false));
	}, []);

	const fetchSquads = async () => {
		api.listSquads().then((res: any) => {
			setData(res);
		});
	};

	const renderData = () => {
		return Object.values(data).map((o: any) => {
			if (o.drive_url) {
				return (
					<Table.Row key={o.id}>
						<Table.Cell>
							<a
								href={o.drive_url}
								rel="noopener noreferrer"
								target="_blank"
							>
								{o.name}
							</a>
						</Table.Cell>
						<Table.Cell collapsing textAlign="right">
							<a
								href={o.drive_url}
								rel="noopener noreferrer"
								target="_blank"
							>
								<i className="fal fa-eye"></i>
							</a>
						</Table.Cell>
					</Table.Row>
				);
			}

			return (
				<Table.Row key={o.id}>
					<Table.Cell>
						<Link to={`/value-propositions/${o.id}`}>{o.name}</Link>
					</Table.Cell>
					<Table.Cell collapsing textAlign="right">
						<Link to={`/value-propositions/${o.id}`}>
							<i className="fal fa-eye"></i>
						</Link>
					</Table.Cell>
				</Table.Row>
			);
		});
	};

	return (
		<Layout loading={loading}>
			<Segment>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Header as="h1">Value propositions</Header>
				</div>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Squad</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>{renderData()}</Table.Body>
				</Table>
			</Segment>
		</Layout>
	);
};

export default Squads;
