import React, { useState } from "react";
import Switch from "../Form/Switch";
import { patch } from "../../api/utils";
import "./api-hide-switch.scss";

interface ApiHideSwitchProps {
	value: boolean;
	url: string;
}

const ApiHideSwitch = ({ value, url }: ApiHideSwitchProps) => {
	const [_value, setValue] = useState(value || false);
	const classes = ["api-hide-switch"];

	const handleChange = async () => {
		await patch(url, {
			hide: !_value,
		});
		setValue(!_value);
	};

	return (
		<div className={classes.join(" ")}>
			<Switch
				label={
					_value
						? "Option is not showing in list"
						: "Option is showing in list"
				}
				onChange={handleChange}
				value={_value}
			/>
		</div>
	);
};

export default ApiHideSwitch;
