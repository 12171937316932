import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useCallback,
} from "react";
import { Container } from "react-smooth-dnd";

import "./column.scss";

type ColumnProps = {
	status: string;
	children: any;
	total?: number;
	onDragEnter?: () => void;
	onDragLeave?: () => void;
	onDrop: (e: any) => void;
	getChildPayload: (index: number) => void;
	onDragStart?: (e: any) => void;
	onDragEnd?: (e: any) => void;
	disabled?: boolean;
	layout?: string;
	head?: any;
	loading?: boolean;
};

const Column = ({
	status,
	total,
	children,
	onDragEnter,
	onDragLeave,
	onDrop,
	getChildPayload,
	onDragStart,
	onDragEnd,
	disabled,
	layout,
	head,
	loading,
}: ColumnProps) => {
	const [height, setHeight] = useState("auto");
	const headHeight = 53;

	const calculateWindowHeight = () => {
		return window.innerHeight;
	};

	const calculateHeight = useCallback(() => {
		const windowHeight = calculateWindowHeight();
		const kanban = document.querySelector(".kanban") as any;
		let top = kanban.offsetTop;
		let h = windowHeight - top - headHeight - 22;
		setHeight(layout === "list" ? "auto" : `${h}px`);
	}, [layout]);

	useEffect(() => {
		calculateHeight();
	}, [layout, calculateHeight]);

	useLayoutEffect(() => {
		// calculateHeight();
		// window.addEventListener("resize", calculateHeight);
		// return () => {
		// 	window.removeEventListener("resize", calculateHeight);
		// };
	});

	const renderItems = (ref: any, layout: any) => {
		return (
			<div
				ref={ref || null}
				style={{
					maxHeight: layout === "list" ? "1000vh" : height,
				}}
				className="column-items"
			>
				{children}
			</div>
		);
	};

	return (
		<div className="column">
			<div style={{ height: `${headHeight}px` }} className="column-head">
				<h2>
					{status} ({total ? total : 0})
				</h2>
				{head || false}
			</div>
			{disabled ? (
				renderItems(null, layout)
			) : (
				<Container
					autoScrollEnabled
					groupName="col"
					render={(ref) => renderItems(ref, layout)}
					{...{
						onDragEnter,
						onDragLeave,
						onDrop,
						getChildPayload,
						onDragStart,
						onDragEnd,
					}}
					onDropReady={(p) => {}}
					dropPlaceholder={{
						animationDuration: 150,
						showOnTop: false,
						className: "column-placeholder",
					}}
				/>
			)}
		</div>
	);
};

export default Column;
