import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Table, Button, Header, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { storeSquads, fetchSquads } from "../../../reducers/squads";
import { ApiSquadObject } from "../../../api/admin/squads";
import api, { ApiResponse, ApiList } from "../../../api/api";
import Layout from "../../../components/Layout";
import ApiHideSwitch from "../../../components/ApiHideSwitch";
import { ApplicationState } from "../../../reducers";

interface SquadsProps {
	squads: { [key: number]: ApiSquadObject };
	isSquadsFetching: boolean;
	// updateSquads: (squad: ApiSquadObject[]) => void;
	// fetchSquads: () => void;
	fetchSquads: (request: ApiResponse<ApiList<ApiSquadObject>>) => any;
}

// let searchTimer: any;

class Squads extends Component<SquadsProps> {
	state = {
		isSearching: false,
	};

	async componentDidMount() {
		this.props.fetchSquads(api.admin.listSquads());
	}

	renderData() {
		return Object.values(this.props.squads).map((o: any) => (
			<Table.Row key={o.id}>
				<Table.Cell>
					<Link to={`/squads/${o.id}/edit`}>{o.name}</Link>
				</Table.Cell>

				<Table.Cell collapsing textAlign="right">
					<div style={{ display: "flex" }}>
						<ApiHideSwitch
							url={`admin/squads/${o.id}`}
							value={o.hide}
						/>

						<Link to={`/squads/${o.id}/edit`}>
							<Button>Edit</Button>
						</Link>
					</div>
				</Table.Cell>
			</Table.Row>
		));
		// return this.props.squads.map((o: ApiSquadObject) => {
		//   return (
		//     <Table.Row key={o.id}>
		//       <Table.Cell>
		//         <Link to={`/squads/${o.id}/edit`}>
		//           {o.name}
		//         </Link>
		//       </Table.Cell>
		//       <Table.Cell collapsing textAlign="right">
		//         <Link to={`/squads/${o.id}/edit`}>
		//           <Button basic icon="pencil" />
		//         </Link>
		//       </Table.Cell>
		//     </Table.Row>
		//   );
		// });
	}

	// searchSquads = (query: string) => {
	//   clearTimeout(searchTimer);
	//   searchTimer = setTimeout(async () => {
	//     this.setState({ isSearching: true });
	//     const result = await listSquads(query);
	//     this.props.updateSquads(result.data);
	//     this.setState({ isSearching: false });
	//   }, 300);
	// }

	render() {
		return (
			<Layout>
				<Segment>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Header as="h1">Squads</Header>
						<Link to="/squads/create">
							<Button
								primary
								icon="plus"
								style={{ marginBottom: "1rem" }}
							/>
						</Link>
					</div>
					<Input
						fluid
						loading={this.state.isSearching}
						placeholder="Search..."
					/>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell textAlign="right">
									Actions
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.renderData()}</Table.Body>
					</Table>
				</Segment>
			</Layout>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({
		squads: state.squads.squads,
		isSquadsFetching: state.squads.isFetching,
	}),
	{ updateSquads: storeSquads, fetchSquads }
)(Squads);
