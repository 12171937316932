import React, { useState, useEffect } from "react";
import "./editable-title.scss";

const EditableTitle = ({
	value,
	onBlur,
	icon
}: {
	value: string;
	onBlur: (val: string) => void;
	icon?: any;
}) => {
	const [title, setTitle] = useState(value);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setTitle(value);
	}, [value]);

	return (
		<div onClick={() => setVisible(true)} className="editable-title">
			{visible ? (
				<input
					autoFocus
					type="text"
					onChange={e => setTitle(e.target.value)}
					onBlur={() => {
						onBlur(title);
						setVisible(false);
					}}
					value={title || ""}
				/>
			) : (
				<h1>
					{title || ""}
					{icon ? icon : false}
				</h1>
			)}
		</div>
	);
};

export default EditableTitle;
