import React, { useState, useEffect } from "react";
import { Radio } from "semantic-ui-react";
import "./duplicate-list.scss";
import Button from "../../Button";
import api from "../../../../api/api";

type DuplicateListProps = {
	onSubmit: (accounts: any[]) => void;
	onClose: () => void;
};

const DuplicateList = ({ onSubmit, onClose }: DuplicateListProps) => {
	const [fetching, setFetching] = useState(true);
	const [accounts, setAccounts] = useState([]);
	const [value, setValue] = useState([] as number[]);

	useEffect(() => {
		api.getDropdownOptions("accounts", "", true).then((res: any) => {
			setAccounts(res);
			setFetching(false);
		});
	}, []);

	const handleAccount = (id: number) => {
		if (value.includes(id)) {
			setValue(value.filter((v) => v !== id));
		} else {
			setValue([...value, id]);
		}
	};

	return (
		<div className="duplicate-list">
			{!fetching ? (
				accounts.map((account: any) => (
					<div className="duplicate-list_item">
						<div className="info">
							{account.image ? (
								<img src={account.image.src} alt="" />
							) : (
								false
							)}
							<p className="label">{account.text || ""}</p>
						</div>
						<Radio
							toggle
							defaultChecked={value.includes(account.value)}
							onChange={() => handleAccount(account.value)}
						/>
					</div>
				))
			) : (
				<div className="duplicate-list_loading">
					<i className="fal fa-spin fa-spinner-third"></i>
				</div>
			)}
			<div className="buttons">
				<Button type="gray" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={() => onSubmit(value)}>Duplicate</Button>
			</div>
		</div>
	);
};

export default DuplicateList;
