import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { ApplicationState } from "../reducers";
import { useState, useEffect } from "react";
import { needScopes } from '../api/filters';
import api from "../api/api";

const useDropdownFetch = ({
	type,
	query,
	focused,
	ids,
	all,
	needId,
	isCreate,
}: {
	type?: string;
	query: string;
	focused: boolean;
	all?: boolean;
	ids?: number[];
	needId?: number;
	isCreate?: boolean;
}) => {
	const users = useSelector((state: ApplicationState) => state.filters.users);
	const [fetching, setFetching] = useState(false as boolean);
	const [options, setOptions] = useState([] as any[]);
	const [allItems, setAllItems] = useState([] as any[]);

	const fetchOptions = (q?: string) => {
		if (!type) {
			return;
		}
		
		if (type === 'need_scopes') {
			if (!allItems.length) {
				setAllItems(needScopes);
			}
			setOptions(needScopes);
		} else {
			setFetching(true);
			
			api.getDropdownOptions(type, q || query, all, ids, needId, isCreate)
				.then((res: any) => {
					if (!allItems.length) {
						setAllItems(res.data as any[]);
					}

					if (res && res.data) {
						setOptions(res.data as any[]);
					}
				})
				.finally(() => setFetching(false));
		}
	};
	const [debounceFetchOptions] = useState(() =>
		debounce((q) => fetchOptions(q), 300)
	);

	// useEffect(fetchOptions, [type]);
	useEffect(() => {
		if (!type) {
			return;
		}
		if (
			type === "users" ||
			type === "assignees" ||
			type === "collaborators"
		) {
			setOptions(users);
		} else if (query || focused) {
			debounceFetchOptions(query);
		}
	}, [query, focused]);
	return [fetching, options, allItems];
};
export default useDropdownFetch;
