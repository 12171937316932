import React, { useState, useEffect, useCallback } from "react";
import { Input } from "semantic-ui-react";
import "./search.scss";
import useFilterChange from "../../../lib/useFilterChange";
import useIsNeed from "../../../lib/useIsNeed";

const Search = () => {
	const isNeed = useIsNeed();
	const [value, onChange] = useFilterChange("query", isNeed);
	const [query, setQuery] = useState(value || "");

	const updateQuery = useCallback(() => {
		setQuery(value);
	}, [value, setQuery]);

	useEffect(() => {
		updateQuery();
	}, [isNeed, updateQuery]);

	const handleChange = (event: any, data: any) => {
		setQuery(data.value);
		onChange(data.value);
	};

	const handleClear = () => {
		onChange("");
		setQuery("");
	};

	return (
		<div className="search-input">
			<Input
				value={query || ""}
				onChange={handleChange}
				iconPosition="left"
				icon="search"
				placeholder="Search..."
				clearable="true"
			/>

			{value ? (
				<div onClick={handleClear} className="search-input_icon">
					<i className="fal fa-times"></i>
				</div>
			) : (
				false
			)}
		</div>
	);
};

export default Search;
