import React, { useState } from "react";
import queryString from "query-string";
import Modal from "../../../Modal";
import DropdownOptionV2 from "../../../Filter/DropdownOptionV2";
import Tags from "../../../Filter/Tags";
import { filterColors as colors } from "../../../../api/filters";
import Grid from "../../Grid";
import "./export-modal.scss";
import Button from "../../../Form/Button";
import { getToken } from "../../../../api/utils";
const config = require("../../../../config.json");

const ExportModal = ({
	visible,
	onClose,
}: {
	visible: boolean;
	onClose: () => void;
}) => {
	const [filter, setFilter] = useState({
		squads: [],
		chapters: [],
		needs: [],
		assignees: [],
		accounts: [],
		query: "",
		archived: false,
		users: [],
		need_scopes: [],
		labels: [],
		collaborators: [],
		preset: null,
		products: [],
		timeframes: [],
	});

	const handleSubmit = () => {
		let link = `${config.apiBaseUrl}/export/outputs?_token=${getToken()}`;
		const qs = queryString.stringify(filter, {
			arrayFormat: "bracket",
		});
		window.open(`${link}&${qs}`, "_new");
	};

	const renderDropdown = (
		type: string,
		placeholder: string,
		multiple: boolean = true
	) => {
		return (
			<DropdownOptionV2
				{...{ multiple, type }}
				placeholder={placeholder}
				defaultValue={filter[type]}
				handleChange={(value) =>
					setFilter({
						...filter,
						[type]: value,
					})
				}
			/>
		);
	};

	return (
		<Modal
			className="export-modal"
			title={<h2>Export</h2>}
			{...{ visible, onClose }}
		>
			<Grid gap={15} columns={2}>
				{renderDropdown("timeframes", "Select timeframe")}
				{renderDropdown("pillars", "Select pillar")}
				{renderDropdown("pages", "Select pages")}
				{renderDropdown("assignees", "Select assignee")}
				{renderDropdown("squads", "Select squads")}
				{renderDropdown("chapters", "Select chapter")}
				{renderDropdown("products", "Select product")}
				{renderDropdown("accounts", "Select account")}
			</Grid>
			<Tags
				{...{ colors }}
				value={filter}
				type="transparent"
				handleRemove={setFilter}
				onFiltersChange={setFilter}
			/>
			<div className="button-center">
				<Button onClick={handleSubmit}>Export data</Button>
			</div>
		</Modal>
	);
};

export default ExportModal;
