import React from "react";
import "./layout.scss";
import Notification from "./Notification";
import Fetching from "./Fetching";

type LayoutProps = {
	children: any;
	hasFilter?: boolean;
	loading?: boolean;
	isNeed?: boolean;
	fetching?: boolean;
	className?: string;
};

const Layout = ({
	children,
	hasFilter,
	loading,
	isNeed,
	fetching,
	className,
}: LayoutProps) => {
	return (
		<>
			{loading && <Fetching />}
			<section className={`content ${className || ""}`}>
				{!loading ? children : <div></div>}
			</section>
			<Notification />
		</>
	);
};

export default Layout;
