import React, { Component, FormEvent, ChangeEvent } from "react";
import { Form, Button, DropdownItemProps, Message } from "semantic-ui-react";

import { fetchChapters } from "../../../reducers/chapters";
import { fetchSquads } from "../../../reducers/squads";
import { roleLabels, UserRole } from "../../../lib/roles";
import { connect } from "react-redux";
import { history, unblockRouter, blockRouter } from "../../../Routes";
import SegmentActions from "../../../components/SegmentActions/SegmentActions";
import { toast } from "react-semantic-toasts";
import {
	updateUser,
	setUsersFetchingState,
	updateWhitelistUser,
} from "../../../reducers/users";
import { get } from "lodash";
import { Action } from "redux";
import { mapObjectToId } from "../../../lib/objectToId";
import { ApiUserObject } from "../../../api/admin/users";
import { ApiSquadObject } from "../../../api/admin/squads";
import { ApiChapterObject } from "../../../api/admin/chapters";
import api from "../../../api/api";
import { ApplicationState } from "../../../reducers";

interface StateProps {
	user?: ApiUserObject;
	squads: { [key: number]: ApiSquadObject };
	chapters: { [key: number]: ApiChapterObject };
	isFetching: boolean;
	errors: any;
}

interface OwnProps {
	id?: number;
	user?: ApiUserObject;
}

interface DispatchProps {
	updateUser: (user: any) => Promise<Action<ApiUserObject[]> | any>;
	updateWhitelistUser: (user: any) => any;
	fetchSquads: (squad: any) => any;
	fetchChapters: (chapter: any) => any;
	setUsersFetchingState: (request: any) => any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface UserEditState {
	errors?: {
		[key: string]: string;
	};
	emailAddress?: string;
	selectedRoles: any[];
	selectedPages: any[];
	selectedSquads: ApiSquadObject[] | number[];
	selectedChapters: ApiChapterObject[] | number[];
	rolesChanged: boolean;
	squadsChanged: boolean;
	chaptersChanged: boolean;
	pagesChanged: boolean;
	user: ApiUserObject;
	pagesOptions: any[];
}

//
const types = Object.keys(roleLabels).map((key: any) => {
	const value = roleLabels[key as UserRole];
	return { value: key, key, text: value };
});

const emptyUser = Object.freeze({
	id: -1,
	first_name: "",
	last_name: "",
	email_address: "",
	avatar: null,
});

class UserEditView extends Component<Props, UserEditState> {
	roles?: any;
	state: UserEditState = {
		errors: {},
		selectedRoles: [],
		selectedSquads: [],
		selectedChapters: [],
		selectedPages: [],
		rolesChanged: false,
		squadsChanged: false,
		chaptersChanged: false,
		pagesChanged: false,
		user: emptyUser,
		pagesOptions: [],
	};

	async componentWillMount() {
		const {
			id,
			user,
			setUsersFetchingState,
			fetchSquads,
			fetchChapters,
			updateUser,
		} = this.props;

		// Load the squads so they can be prefilled.
		await fetchSquads(api.admin.listSquads());
		await fetchChapters(api.admin.listChapters());

		let pages = await api.admin.pages.list();
		const pagesOptions: DropdownItemProps[] = Object.values(pages).map(
			(s) => ({
				key: s.id,
				value: s.id,
				text: s.name,
			})
		);
		this.setState({ pagesOptions });

		if (user && user.roles) {
			this.setState({
				user,
				selectedRoles: user.roles ? mapObjectToId(user.roles) : [],
				selectedSquads: user.squads ? mapObjectToId(user.squads) : [],
				selectedPages: user.pages ? mapObjectToId(user.pages) : [],
				selectedChapters: user.chapters
					? mapObjectToId(user.chapters)
					: [],
			});
			setUsersFetchingState(false);
		} else if (id) {
			const request = api.admin.showUser(id);
			updateUser(request);
			const response = await request;
			this.setState({
				user: response,
				selectedRoles: response.roles
					? mapObjectToId(response.roles)
					: [],
				selectedSquads: response.squads
					? mapObjectToId(response.squads)
					: [],
				selectedChapters: response.chapters
					? mapObjectToId(response.chapters)
					: [],
				selectedPages: response.pages
					? mapObjectToId(response.pages)
					: [],
			});
			setUsersFetchingState(false);
		}
		// If both where not defined, create a new user.
	}

	processForm = async (e: FormEvent<HTMLFormElement>) => {
		const formData = new FormData(e.currentTarget);
		const { user } = this.state;

		let names = {
			first_name: user.first_name,
			last_name: user.last_name,
		};
		// if (this.props.id || this.props.user) {
			names.first_name = formData.get("first_name") as string;
			names.last_name = formData.get("last_name") as string;
		// }

		const data: ApiUserObject = {
			...user,
			first_name: names.first_name,
			last_name: names.last_name,
			email_address: formData.get("email_address") as string,
			roles: this.state.selectedRoles,
			squads: this.state.selectedSquads,
			chapters: this.state.selectedChapters,
			pages: this.state.selectedPages,
		};

		let isSaved = true;
		if (this.props.id) {
			const res = await this.props.updateUser(
				api.admin.updateUser(user.id, data)
			);

			isSaved = !get(res, "meta.isError");
		} else {
			const res = await this.props.updateWhitelistUser(
				api.admin.storeUser(data)
			);
			isSaved = !get(res, "meta.isError");
			if (isSaved) {
				this.setState({
					selectedRoles: [],
					rolesChanged: false,
					selectedSquads: [],
					squadsChanged: false,
					selectedChapters: [],
					chaptersChanged: false,
					pagesChanged: false,
					selectedPages: [],
					user: { ...emptyUser },
				});
			}
		}

		if (isSaved) {
			toast({
				type: "success",
				description: "User saved succesfully",
				time: 2500,
			} as any);
			unblockRouter();
			history.push("/users");
		}
	};

	onSubmit = (e: FormEvent<HTMLFormElement>) => {
		this.processForm(e);
		e.preventDefault();
	};

	onSelectionChange = (e: any, value: any, name: string) => {
		this.onFieldChange();
		if (name === "roles") {
			this.setState({ selectedRoles: value, rolesChanged: true });
		} else if (name === "squads") {
			this.setState({ selectedSquads: value, squadsChanged: true });
		} else if (name === "chapters") {
			this.setState({ selectedChapters: value, chaptersChanged: true });
		} else if (name === "pages") {
			this.setState({ selectedPages: value, pagesChanged: true });
		}
	};

	onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.onFieldChange();
		const value = e.currentTarget.value;
		this.setState((state) => ({
			user: {
				...state.user,
				email_address: value,
			},
		}));
	};

	onFieldChange = () => {
		blockRouter();
	};

	renderNameFields() {
		// if (!this.props.id) {
		// 	return null;
		// }

		const { user } = this.state;

		return (
			<>
				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.first_name !== undefined
					}
					name="first_name"
					label="First name"
					required
					defaultValue={user.first_name}
					onChange={this.onFieldChange}
				/>
				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.last_name !== undefined
					}
					name="last_name"
					label="Last name"
					required
					defaultValue={user.last_name}
					onChange={this.onFieldChange}
				/>
			</>
		);
	}

	renderForm() {
		const { squads } = this.props;
		const { chapters } = this.props;
		const { pagesOptions } = this.state;
		const squadOptions: DropdownItemProps[] = Object.values(squads).map(
			(s) => ({
				key: s.id,
				value: s.id,
				text: s.name,
			})
		);

		const chapterOptions: DropdownItemProps[] = Object.values(chapters).map(
			(c) => ({
				key: c.id,
				value: c.id,
				text: c.name,
			})
		);

		const flatOptions = (arr: any[]) =>
			arr.map((item) => (typeof item === "number" ? item : item.id));

		const isEdit = this.props.id || this.props.user;
		const selectedSquads: number[] = flatOptions(
			this.state?.selectedSquads || []
		);
		const selectedChapters: number[] = flatOptions(
			this.state?.selectedChapters || []
		);
		const selectedPages: number[] = flatOptions(
			this.state?.selectedPages || []
		);
		const selectedRoles: string[] = this.state.selectedRoles.map((o) =>
			o.toString()
		);

		return (
			<Form onSubmit={this.onSubmit}>
				{this.props.errors ? (
					<Message
						header="There were some errors saving this user."
						list={
							typeof this.props.errors === "string"
								? [this.props.errors]
								: Object.values(this.props.errors || {})
						}
					/>
				) : null}
				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.email_address !== undefined
					}
					name="email_address"
					label="Email address"
					required
					value={this.state.user.email_address}
					onChange={this.onEmailChange}
				/>
				{this.renderNameFields()}
				<Form.Dropdown
					error={
						this.props.errors &&
						this.props.errors.roles !== undefined
					}
					label="Roles"
					fluid
					selection
					multiple
					options={types}
					value={selectedRoles}
					name="roles"
					required
					onChange={(e, { value }) =>
						this.onSelectionChange(e, value, "roles")
					}
				/>
				{isEdit && (
					<Form.Dropdown
						error={
							this.props.errors &&
							this.props.errors.squads !== undefined
						}
						label="Squads"
						fluid
						selection
						multiple
						options={squadOptions}
						value={selectedSquads}
						name="squads"
						required
						onChange={(e, { value }) =>
							this.onSelectionChange(e, value, "squads")
						}
					/>
				)}
				{isEdit && (
					<Form.Dropdown
						error={
							this.props.errors &&
							this.props.errors.chapters !== undefined
						}
						label="Chapters"
						fluid
						selection
						multiple
						options={chapterOptions}
						value={selectedChapters}
						name="chapters"
						required
						onChange={(e, { value }) =>
							this.onSelectionChange(e, value, "chapters")
						}
					/>
				)}

				{isEdit && (
					<Form.Dropdown
						error={
							this.props.errors &&
							this.props.errors.chapters !== undefined
						}
						label="Pages"
						fluid
						selection
						multiple
						options={pagesOptions}
						value={selectedPages}
						name="pages"
						required
						onChange={(e, { value }) =>
							this.onSelectionChange(e, value, "pages")
						}
					/>
				)}
				<SegmentActions>
					{isEdit ? (
						<Button
							basic
							type="button"
							content="Cancel"
							onClick={() => history.goBack()}
						/>
					) : null}
					<Button
						primary
						content={isEdit ? "Save" : "Add"}
						type="submit"
					/>
				</SegmentActions>
			</Form>
		);
	}

	render() {
		return <div>{this.renderForm()}</div>;
	}
}

export default connect(
	(state: ApplicationState, ownProps: OwnProps) => ({
		squads: state.squads.squads,
		chapters: state.chapters.chapters,
		user: ownProps.id ? state.users.users[ownProps.id] : ownProps.user,
		isFetching: state.users.isUsersFetching,
		errors: state.users.error,
	}),
	{
		fetchSquads,
		fetchChapters,
		updateUser,
		setUsersFetchingState,
		updateWhitelistUser,
	}
)(UserEditView);
