import qs from "qs";
import { get, post, del } from "./utils";
import { ApiResponse } from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../reducers/filters";
import { ApplicationState } from "../reducers";

export const needScopes = [
	{
		id: 1,
		name: "Individual",
		value: "Individual",
		text: "Individual",
	},
	{
		id: 2,
		name: "Team",
		value: "Team",
		text: "Team",
	},
];

export interface ApiUserFilterObject {
	id: number;
	name: string;
	filter: {};
}

export interface ApiTagObject {
	name: string;
	type: string;
	id: number;
}

export interface ApiDropdownValueObject {
	value: number;
	text: string;
	image?: {
		avatar: boolean;
		src: string;
	};
	type?: string;
	filter?: {
		[key: string]: any;
	};
}

export interface FilterState {
	squads: number[];
	chapters: number[];
	needs: number[];
	assignees: number[];
	accounts: number[];
	query: string;
	archived: boolean;
	users: number[];
	need_scopes: number[];
	labels: number[];
	collaborators?: number[];
	preset: number | null;
	actions?: number[];
	products?: number[];
}

export const emptyFilter: FilterState = {
	squads: [],
	chapters: [],
	needs: [],
	assignees: [],
	accounts: [],
	query: "",
	archived: false,
	users: [],
	need_scopes: [],
	labels: [],
	collaborators: [],
	preset: null,
	products: [],
};

export const filterColors = {
	needs: "#19B5FA",
	squads: "coral",
	chapters: "#7E57C3",
	accounts: "#26A69A",
	assignees: "lightgreen",
	need_scopes: "deepblue",
	users: "darkgreen",
	labels: "purple",
	collaborators: "#FF3366",
	pillars: "cyan",
	products: "pink",
};

export const toKeyValue = (data: any, key = "name") => {
	return data && data.length
		? data.map((item) => {
				let extra = {};
				if (item.avatar) {
					extra = {
						image: item.avatar,
						id: item.id,
					};
				}
				let type = null;
				if (item.type) {
					type = item.type;
				}

				return {
					key: item.id + item[key],
					value: item.id,
					text: item[key],
					type,
					...extra,
				};
		  })
		: [];
};

export const useAddLabelToFilter = (isNeed: boolean) => {
	const dispatch = useDispatch();
	const labels = useSelector(
		(state: ApplicationState) =>
			state.filters[isNeed ? "needs" : "actions"].labels
	);

	const addToLabel = (id: number, isNeed: boolean) => {
		let value = new Set([...labels, id]);
		dispatch(
			setFilter({
				key: "labels",
				value: Array.from(value),
				isNeed,
			})
		);
	};
	return addToLabel;
};

export const hasFilter = (filter: FilterState) => {
	for (var key in filter) {
		if (
			filter[key] &&
			typeof filter[key] === "object" &&
			filter[key].length
		)
			return true;
		else if (typeof filter[key] !== "object" && filter[key]) return true;
	}
	return false;
};

export default {
	list: () => {
		return get("filters") as ApiResponse<ApiUserFilterObject[]>;
	},
	upsert: (
		id: string | number | null,
		name: string,
		selected: {},
		type: string
	) => {
		return post("filters", {
			id,
			name,
			filter: selected,
			type,
		}) as ApiResponse<ApiUserFilterObject[]>;
	},
	delete: (filterId: number | string) => {
		return del(`filters/${filterId}`) as ApiResponse<ApiUserFilterObject[]>;
	},
	getDropdownOptions: (
		type: string,
		search: string,
		all?: boolean,
		ids?: number[],
		needId?: number,
		isCreate?: boolean
	) => {
		if (type === "assignees") {
			type = "users";
		}
		if (type === "need_scopes") {
			return new Promise(() => ({
				data: needScopes,
			}));
		}
		if (type === "preset") {
			type = "filters";
		}
		const query = qs.stringify({
			q: search,
			filter: 1,
			all: all ? 1 : 0,
			ids,
			needId,
			create: isCreate,
		});
		return get(`/${type}?${query}`) as ApiResponse<
			ApiDropdownValueObject[]
		>;
	},
	getFilterTags: (filter: any) => {
		const query = qs.stringify(filter);
		return get(`/tags?${query}`) as ApiResponse<ApiTagObject[]>;
	},
};
