import qs from "qs";
import { ApiUserObject } from "./admin/users";
import { ApiResponse } from "./api";
import { get } from "./utils";

export default {
  list: (q: string = '', squad?: number, role?: number) => {
    const query = qs.stringify({
      q,
      squad_id: squad,
      role_id: role,
    });
    return get(`users?${query}`) as ApiResponse<ApiUserObject[]>;
  }
}