import React from "react";
import "./dropdown-toggle.scss";
import useFilterChange from "../../../lib/useFilterChange";
import useIsNeed from "../../../lib/useIsNeed";
import Switch from "../../Form/Switch";

type DropdownToggleProps = {
	className?: string;
	label?: string;
};

const DropdownToggle = ({ className, label }: DropdownToggleProps) => {
	const isNeed = useIsNeed();
	const [value, onChange] = useFilterChange("archived", isNeed);
	return (
		<div className={`dropdown-toggle ${className || ""}`}>
			<Switch
				type="big"
				value={value}
				onChange={() => onChange(!value)}
			/>
			<span onClick={() => onChange(!value)}>{label}</span>
		</div>
	);
};

export default DropdownToggle;
