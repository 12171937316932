import React, { useState, useEffect } from "react";
import "./createneed.scss";
import Modal from "../../../components/Modal";
import Button from "../../../components/Form/Button";
import Editor from "../../../components/Form/Editor";
import Statuses from "../../../components/Form/Statuses";
import api from "../../../api/api";
import { ApiNeedObject } from "../../../api/needs";
import AssigneePicker from "../../../components/Form/AssigneePicker";
import useFilterChange from "../../../lib/useFilterChange";
import BadgeSelector from "../../../components/BadgeSelector";
import { Dropdown } from "semantic-ui-react";
import KeyResults from "../../../components/Keyresults";

type CreateNeedProps = {
	button: any;
};

export const TYPES = [
	{
		id: "individual",
		name: "Individual",
		value: "Individual",
	},
	{
		id: "team",
		name: "Team",
		value: "Team",
	},
];

const EMPTY_NEED = {
	id: undefined,
	name: "",
	account_id: undefined,
	description: "",
	chapters: undefined,
	squads: undefined,
	pages: undefined,
	time_to_respond: 0,
	type: "Item",
	scope: "Individual",
	assignee_id: undefined,
	timeframe_id: undefined,
	pillars: undefined,
	key_results: [
		{
			id: null,
			name: "",
		},
	],
} as ApiNeedObject;

const CreateNeed = ({ button }: CreateNeedProps) => {
	const [, onChangeQuery] = useFilterChange("query", true);
	const [timeframes, setTimeframes] = useState([] as any);
	const [pillars, setPillars] = useState([] as any);
	const [need, setNeed] = useState(EMPTY_NEED);
	const [fetching, setFetching] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [visible, setVisible] = useState(false);

	const handleNeed = (key, value) => {
		setNeed({
			...need,
			[key]: value,
		});
		let allowed =
			need?.chapters?.length !== 0 ||
			need?.squads?.length !== 0 ||
			need?.pages?.length !== 0;
		if (need.name && need.type && need?.pillars?.length && allowed) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	useEffect(() => {
		api.listTimeframes(true).then((res) => {
			setTimeframes(res);
		});
		api.listPillars(true).then((res) => {
			setPillars(res);
		});
	}, []);

	const handleSave = async () => {
		setFetching(true);
		api.storeNeed(need).then(async (res: any) => {
			setVisible(false);

			if (need.name) {
				onChangeQuery(need.name);
			}
			setNeed(EMPTY_NEED);
		});
	};

	const handleCancel = () => {
		setNeed(EMPTY_NEED);
		setVisible(false);
	};
	return (
		<div className="create-need">
			<div
				onClick={() => setVisible(true)}
				className="create-need_button"
			>
				{button}
			</div>
			<Modal
				title={<div style={{ flex: 1 }}>
					<label style={{ marginBottom: 5, display: 'block' }}>Title <span style={{ color: '#dd0000', fontSize: 16 }}>*</span></label>
					<input
						value={need.name}
						onChange={(e: any) =>
							handleNeed("name", e.target.value)
						}
						style={{ padding: '5px 10px', width: '100%', height: 'auto' }}
					/>
				</div>}
				buttons={<div style={{ backgroundColor: '#f4f4f4' }}>
					<div style={{ fontSize: 12, fontStyle: 'italic', marginBottom: 5 }}>
						Fields marked with an <span style={{ color: '#dd0000' }}>*</span> are mandatory
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							onClick={handleCancel}
							type="transparent"
							key="nb_1"
						>
							Cancel
						</Button>
						<Button
							onClick={handleSave}
							loading={fetching}
							disabled={disabled}
							key="nb_2"
						>
							Save
						</Button>
					</div>
				</div>}
				visible={visible}
				onClose={handleCancel}
			>
				<div className="create-need_wrapper">
					<div className="form">
						{/* <div className="assignee">
							<AssigneePicker
								type="input"
								notOpen
								onChange={(id: any) => {
									handleNeed("assignee_id", id);
								}}
								value={need.assignee_id}
							/>
						</div> */}

						<div className="types">
							<label>Timeframe from</label>
							{timeframes && timeframes.length && (
								<BadgeSelector
									value={need.timeframe_id}
									onChange={(value) =>
										handleNeed("timeframe_id", value.id)
									}
									data={timeframes}
								/>
							)}
						</div>

						<div className="types">
							<label>Timeframe to</label>
							{timeframes && timeframes.length && (
								<BadgeSelector
									value={need.timeframe_id_to}
									onChange={(value) =>
										handleNeed("timeframe_id_to", value.id)
									}
									data={timeframes}
								/>
							)}
						</div>

						<div className="types">
							<label>Goal Type <span style={{ color: '#d00' }}>*</span></label>
							<BadgeSelector
								value={need.scope}
								onChange={(value) =>
									handleNeed("scope", value.id)
								}
								data={TYPES}
							/>
						</div>
						<div className="description">
							<Editor
								placeholder="Outcome/ Objective"
								defaultValue={need.description}
								onDone={(text) =>
									handleNeed("description", text)
								}
							/>
						</div>

						<KeyResults
							forceEdit
							results={need?.key_results || []}
							setResults={(value) =>
								handleNeed("key_results", value)
							}
						/>
						{/* <div className="labels">
							<Labels
								value={need.labels ? need.labels : []}
								placeholder="labels"
								setValue={(labels: any) => {
									handleNeed("labels", labels);
								}}
							/>
						</div> */}
					</div>
					<div className="right-column">
						<div className="assignee">
							<AssigneePicker
								className="assignee-dropdown"
								// notOpen
								onChange={(id: any) => {
									handleNeed("assignee_id", id);
								}}
								value={need.assignee_id}
							/>
						</div>

						{pillars?.length && (<div>
							<label style={{ marginBottom: 5, display: 'block' }}>Pillar(s) <span style={{ color: '#dd0000', fontSize: 16 }}>*</span></label>
							<Dropdown
								multiple
								selection
								search
								clearable
								placeholder="Select pillars"
								value={need.pillars || []}
								onChange={(event: any, data: any) =>
									handleNeed("pillars", data.value)
								}
								className="pillar-dropdown"
								options={pillars.map((i: any) => ({
									text: i.name,
									value: i.id,
								}))}
							/>
						</div>)}

						<p style={{ fontSize: 12, fontStyle: 'italic' }}>Select team(s) mandatory for<br />your goal <span style={{ fontSize: 16, color: '#dd0000' }}>*</span></p>
						<Statuses
							isCreate
							setAction={(o: any) => {
								// Groetjes Jelle
								const key = Object.keys(o)[0];
								handleNeed(key, o[key]);
							}}
							action={need}
							disabled={["Need", "Status"]}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CreateNeed;
