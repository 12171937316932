import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from "redux";
import { pickBy } from "lodash";
import { ApiChapterObject } from "../api/admin/chapters";
import { ApiResponse, ApiList } from "../api/api";

export interface ChaptersState {
  chapters: { [key: number]: ApiChapterObject },
  isFetching: boolean;
  error: any;
};

// Action creators
export const storeChapters = createAction('storeChapters');
export const filterChapters = createAction('filterChapters');
export const setFetchingState = createAction('setFetchingState');
export const setErrors = createAction('setErrors', null, () => ({ isError: true }));

// Async action creators
export function fetchChapters(request: ApiResponse<ApiList<ApiChapterObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeChapters(res.data)))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

export function updateChapter(request: ApiResponse<ApiChapterObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => dispatch(storeChapters([res])))
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

const chaptersReducer = handleActions({
  [storeChapters.toString()]: (state, action) => {
    // state.chapters = action.payload;
    action.payload.forEach((chapter: ApiChapterObject) => {
      state.chapters[chapter.id] = chapter;
    });
    return {
       ...state
    }
  },
  [filterChapters.toString()]: (state, action) => {
     return {
       ...state,
       chapters: pickBy(state.chapters, (v: any, k: string) => {
          return parseInt(k) !== action.payload;
        })
    }
  },
  [setFetchingState.toString()]: (state, action) => {
     return {
       ...state,
       isFetching: action.payload
    }
  },
}, {
  chapters: {},
  isFetching: false,
  error: null,
});

export default chaptersReducer;
