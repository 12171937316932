import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserEditView from "./UserEditView";
import { Segment } from "semantic-ui-react";
import Layout from "../../../components/Layout";

const UserEdit = () => {
	const { id } = useParams();
	const usersLoading = useSelector(
		(state: any) => state.users.isUsersFetching
	);

	return (
		<Layout loading={usersLoading}>
			<Segment>
				<h1>{id ? "Edit" : "Add"} user</h1>
				<UserEditView {...{ id }} />
			</Segment>
		</Layout>
	);
};

export default UserEdit;
