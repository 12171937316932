import React, { useState, useRef, useEffect } from "react";
import "./createaction.scss";
import api from "../../../api/api";
import Modal from "../../../components/Modal";
import Editor from "../../../components/Form/Editor";
import Button from "../../../components/Form/Button";
import { ApiLabelObject } from "../../../api/actions";
import Statuses from "../../../components/Form/Statuses";
import FilePicker from "../../../components/Form/FilePicker";
import Labels from "../../../components/Form/Labels";
import socket from "../../../lib/socket";
import AssigneePicker from "../../../components/Form/AssigneePicker";
import DeadlinePicker from "../../../components/Form/DeadlinePicker";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../reducers";

type CreateActionProps = {
	visible: boolean;
	onClose: (res?: any) => void;
	defaultValue?: any;
	need?: number;
	refetch?: any;
};

const EMPTY_ACTION = {
	id: undefined,
	subject: "",
	assignee_id: undefined,
	deadline: "",
	description: "",
	status: 0,
	chapter_id: undefined,
	need_id: undefined,
	squad_id: undefined,
	account_id: undefined,
	labels: [],
	documents: [],
	collaborators: [],
} as {
	id?: number;
	subject?: string;
	assignee_id?: any;
	deadline?: string;
	description?: string;
	status?: any;
	chapter_id?: any;
	need_id?: any;
	squad_id?: any;
	account_id?: number;
	labels?: ApiLabelObject[];
	documents: any[];
	collaborators?: any[];
};

const CreateAction = ({
	visible,
	onClose,
	defaultValue,
	need,
	refetch,
}: CreateActionProps) => {
	const f = useSelector((state: ApplicationState) => state.filters.actions);
	const [action, setAction] = useState(EMPTY_ACTION);
	const [isFetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [disabled, setDisabled] = useState(true);

	const editorElement = useRef(null);
	const ModalBody = useRef(null);

	const setPrefilledFilters = () => {
		if (f) {
			let prefilled = action;
			let need_id = f && f.needs ? f.needs[0] : undefined;
			if (need) {
				need_id = need;
			}

			prefilled = {
				...prefilled,
				squad_id: f.squads ? f.squads[0] : undefined,
				chapter_id: f.chapters ? f.chapters[0] : undefined,
				need_id,
				account_id: f.accounts ? f.accounts[0] : undefined,
				assignee_id: f.assignees ? f.assignees[0] : undefined,
			};
			setAction(prefilled);
		}
	};

	useEffect(() => {
		if (defaultValue) {
			let prefilled = EMPTY_ACTION;
			prefilled = {
				...prefilled,
				...defaultValue,
			};
			prefilled.deadline = "";
			setAction(prefilled);
		}

		setPrefilledFilters();
		setLoading(false);
	}, [defaultValue, visible]);

	const handleChange = (e: any) => {
		setAction({
			...action,
			[e.target.name]: e.target.value,
		});
		let disabled =
			action.subject ||
			action.assignee_id ||
			action.deadline ||
			action.description
				? false
				: true;
		setDisabled(disabled);
	};

	const handleSave = async () => {
		if (!action.id) {
			setFetching(true);
			api.storeAction(action)
				.then(async (res) => {
					setFetching(false);
					if (refetch) {
						refetch();
					}
					// Waarom event en refetch
					// window.dispatchEvent(
					// 	new CustomEvent("masterboard_action_added", {
					// 		detail: { action: res },
					// 	})
					// );
					socket.emit("action added", res);
					setAction({ ...EMPTY_ACTION });
					onClose(res);
				})
				.catch(() => {
					setFetching(false);
					alert("Something went wrong");
				});
		}
	};

	const handleCancel = () => {
		setAction({ ...EMPTY_ACTION });
		setLoading(true);
		onClose();
	};

	const handleStatusesChange = (change) => {
		setAction({ ...action, ...change });
	};

	return (
		<Modal
			loading={loading}
			title={
				<input
					value={action.subject}
					onChange={handleChange}
					name="subject"
					className="create-action_title"
					placeholder="Click here to add action name"
				></input>
			}
			buttons={[
				<Button
					key="button_1"
					onClick={handleCancel}
					type="transparent"
				>
					Cancel
				</Button>,
				<Button key="button_2" onClick={handleSave} disabled={disabled}>
					{isFetching ? (
						<i className="fal fa-spin fa-spinner-third"></i>
					) : action.id ? (
						"Update"
					) : (
						"Save"
					)}
				</Button>,
			]}
			title_extra={
				<FilePicker
					id={action.id}
					onPick={(doc) =>
						setAction({
							...action,
							documents: action.documents
								? [...action.documents, doc]
								: [doc],
						})
					}
					button={
						<span className="create-action_file">
							<i className="fas fa-paperclip"></i> Attach file
						</span>
					}
				/>
			}
			onClose={handleCancel}
			visible={visible}
		>
			<div ref={ModalBody} className="create-action">
				<div className="form">
					<div className="form-head">
						<AssigneePicker
							label="Assigned Person"
							type="input"
							notOpen
							onChange={(id: any) => {
								setAction({
									...action,
									assignee_id: id,
								});
							}}
							value={action.assignee_id}
						/>
						<DeadlinePicker
							label="Due Date"
							type="input"
							action={action}
							onChange={(value) => {
								setAction({
									...action,
									deadline: value,
								});
							}}
							value={action.deadline || ""}
						/>
					</div>
					<div className="description">
						<Editor
							ref={editorElement}
							placeholder="Description"
							defaultValue={action.description}
							onDone={(text) => {
								setAction({
									...action,
									description: text,
								});
							}}
						/>
					</div>
					<div className="labels">
						<Labels
							value={action.labels ? action.labels : []}
							placeholder="labels"
							setValue={(labels: any) => {
								setAction({
									...action,
									labels,
								});
							}}
						/>
					</div>
					<div className="files">
						<label>Files</label>
						<div className="files-content">
							{action.documents && action.documents.length ? (
								action.documents.map((doc: any) => (
									<div
										key={doc.url}
										className="files-content_item"
									>
										<a target="_blank" href={doc.url}>
											{doc.name}
										</a>
										<i
											onClick={() =>
												setAction({
													...action,
													documents: action.documents.filter(
														(d) => d.url !== doc.url
													),
												})
											}
											className={`fal ${"fa-trash-alt"}`}
										></i>
									</div>
								))
							) : (
								<div className="files-content_empty">
									<p>No documents found</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<Statuses
					setAction={handleStatusesChange}
					action={action}
					disabled={[]}
				/>
			</div>
		</Modal>
	);
};

export default CreateAction;
