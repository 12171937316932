import React from 'react'
import './fetching.scss'

const Fetching = () => {
    return (
        <div className="fetching">
            <div className="fetching-bar">
            </div>
        </div>
    )
}

export default Fetching
