import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./reducers";

import { AuthState } from "./reducers/auth";
import { UsersState } from "./reducers/users";
import { FiltersState } from "./reducers/filters";

export interface AppState {
	auth: AuthState;
	users: UsersState;
	filters: FiltersState;
}

export default createStore(
	combineReducers(rootReducer),
	applyMiddleware(ReduxThunk)
);
