import { ApiResponse } from "../api";
import { get, post, patch, del } from "../utils";

export interface ApiProductObject {
	id: number;
	name: string;
}

export default {
	list: () => {
		return get(`products`) as ApiResponse<ApiProductObject[]>;
	},
	show: (id: Number) => {
		return get(`products/${id}`) as ApiResponse<ApiProductObject>;
	},
	create: (name: string) => {
		return post(`admin/products`, { name }) as ApiResponse<
			ApiProductObject
		>;
	},
	update: (id: number, name: string) => {
		return patch(`admin/products/${id}`, { name }) as ApiResponse<
			ApiProductObject
		>;
	},
	delete: (id: number) => {
		return del(`admin/products/${id}`) as ApiResponse<any>;
	},
};
