import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Input, Popup } from "semantic-ui-react";
import moment from "moment";
import "./comments.scss";
import Switch from "../Form/Switch";
import Message from "../Message";
import api from "../../api/api";
import Avatar from "../Avatar";

type CommentsProps = {
	id: number;
	auth: any;
};

const Comments = ({ id, auth }: CommentsProps) => {
	const [hasLogs, setLogs] = useState(false);
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const body = useRef<HTMLDivElement>(null);

	const fetchLogs = () => {
		if (!id) {
			return;
		}
		api.listActionLogs(id).then((res: any) => {
			setMessages(res.data);
		});
	};

	useEffect(() => {
		fetchLogs();
	}, [id]);

	const scrollToBottom = () => {
		// if (body && body.current)
		// 	body.current.scrollTop = body.current.scrollHeight;
	};

	const handleSubmit = (e) => {
		if (e) e.preventDefault();

		if (!message) {
			return;
		}
		api.storeActionComment(id, { message }).then((res) => {
			fetchLogs();
			setMessage("");
		});
	};

	return (
		<div className="comments">
			<div className="comments-actions">
				<label>Comments</label>
				<Switch
					label={"Show change history"}
					value={hasLogs}
					onChange={(value) => {
						setLogs(value);
						setTimeout(scrollToBottom, 300);
					}}
				/>
			</div>
			<form onSubmit={handleSubmit} className="comments-head">
				<Avatar src={auth.avatar} />
				<Input
					value={message}
					onChange={(e, data) => setMessage(data.value)}
					placeholder="Comment"
				/>
				<button type="submit" style={{ display: "none" }}></button>
			</form>
			<div
				ref={body}
				className={`comments-body ${
					!hasLogs ? "comments-body_compact" : ""
				}`}
			>
				{messages.map((message: any) =>
					message.action === "comment" ? (
						<Message
							key={message.id + "Comment"}
							avatar={message.user.avatar}
							full_name={message.user.full_name}
							created_at={message.created_at}
							content={message.extra.content}
						/>
					) : (
						<div key={message.id + " log"} className="log">
							<span>
								{message?.user?.full_name || ""}{" "}
								{message.message}
							</span>
							<Popup
								key={message.id + "Log"}
								size="tiny"
								inverted
								position="top center"
								trigger={
									<span className="times">
										<span>{message.diff_for_humans}</span>
									</span>
								}
								content={
									<p>
										{moment(message.created_at).format(
											"DD MMM YYYY - HH:mm"
										)}
									</p>
								}
							></Popup>
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default connect((state: any) => {
	return {
		auth: state.auth,
	};
}, {})(Comments);
