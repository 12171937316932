import React, { useState, useEffect, useRef } from "react";
import "./labels.scss";
import { ApiLabelObject } from "../../../api/actions";
import api from "../../../api/api";
import Label from "../../Label";
import { useAddLabelToFilter } from "../../../api/filters";

type LabelsProps = {
	value: ApiLabelObject[];
	placeholder: string;
	setValue: (labels: ApiLabelObject[]) => void;
	show?: boolean;
	changeShowMode?: (value: boolean) => void;
	isNeed?: boolean;
};

const Labels = ({
	value,
	setValue,
	placeholder,
	show,
	changeShowMode,
	isNeed,
}: LabelsProps) => {
	const addLabelToFilter = useAddLabelToFilter(isNeed || false);
	const [cursor, setCursor] = useState(-1);
	const [data, setData] = useState([]);
	const [focused, setFocused] = useState(false);
	const [label, setLabel] = useState("");
	const [showComplete, setComplete] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const inputElement = useRef<HTMLInputElement>(null);

	useEffect(() => {
		fetchLabels();
	}, [label]);

	useEffect(() => {
		document.addEventListener("mousedown", (e) =>
			handleClickOutside(e, showComplete)
		);
		return () => {
			document.removeEventListener("mousedown", (e) =>
				handleClickOutside(e, showComplete)
			);
		};
	}, [showComplete]);

	const handleClickOutside = (event, show) => {
		const allow =
			wrapperRef.current && !wrapperRef.current.contains(event.target);

		if (allow && changeShowMode) {
			setLabel("");
			changeShowMode(true);
		}
		if (allow && show) {
			setComplete(false);
			setFocused(false);
		}
	};

	const fetchLabels = () => {
		if (label) {
			api.listLabels(label).then((res: any) => {
				setData(res);
			});
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Tab") {
			e.preventDefault();
			if (data.length) {
				setCursor(cursor + 1);
			}
			// inputElement?.current?.focus();
			return;
		}
		if (e.key === "Enter" && label) {
			if (cursor !== -1 && options[cursor]) {
				let option: any = options[cursor];
				let text: string = option.text;
				addLabel(text);
			} else {
				addLabel(label);
			}
		} else if (e.keyCode === 8 && label === "") {
			let copyValue = value;
			copyValue.pop();
			setValue(copyValue);
		}

		if (e.keyCode === 38 && cursor > -1) {
			setCursor(cursor - 1);
		} else if (e.keyCode === 40 && cursor < options.length - 1) {
			setCursor(cursor + 1);
		}
	};

	const addLabel = (label) => {
		let color = {};
		let name = "";
		if (typeof label === "string") {
			name = label;
		} else {
			name = label.name;
			color = label.color;
		}
		let item = {
			name: name,
			slug: name.toLowerCase().split(" ").join("-"),
			color: color,
		} as ApiLabelObject;
		setValue([...value, item]);
		setLabel("");
	};

	const handleRemove = (slug: string) => {
		setValue(value.filter((s) => s.slug !== slug));
	};

	let values = value.map((v) => v.name);
	let options = data.filter((d: any) => !values.includes(d.text));

	if (show) {
		return (
			<div
				className={`
                labels-showmode
                ${!values.length && "labels-showmode_empty"}
            `}
			>
				{value.map((v: ApiLabelObject) => (
					<Label
						color={v.color}
						key={v.name + v.slug}
						onClickIcon={() => handleRemove(v.slug)}
						onClick={() =>
							addLabelToFilter(v.id, isNeed ? true : false)
						}
						icon="fal fa-times"
					>
						{v.name}
					</Label>
				))}
				<div
					onClick={() =>
						changeShowMode ? changeShowMode(false) : {}
					}
					className="add-more"
				>
					<span className="icon">
						<i className="fal fa-plus"></i>
					</span>
					<span className="label">Add labels</span>
				</div>
			</div>
		);
	}

	return (
		<div
			ref={wrapperRef}
			onClick={() => {
				if (inputElement && inputElement.current) {
					inputElement.current.focus();
					setFocused(true);
					setComplete(true);
				}
			}}
			className={`label-input 
                ${focused ? "label-input_focus" : ""}
                ${value.length === 0 ? "label-input_empty" : ""}
                ${showComplete ? "label-input_complete" : ""}
            `}
		>
			{value.map((item: ApiLabelObject, index) => (
				<Label
					color={item.color}
					icon="fal fa-times"
					onClickIcon={() => handleRemove(item.slug)}
					onClick={() =>
						addLabelToFilter(item.id, isNeed ? true : false)
					}
					key={item.slug}
				>
					{item.name}
				</Label>
			))}
			<input
				type="text"
				onFocus={() => {
					setFocused(true);
					setComplete(true);
				}}
				placeholder={placeholder}
				onChange={(e) => setLabel(e.target.value)}
				value={label}
				ref={inputElement}
				onKeyDown={handleKeyPress}
			/>
			<div className="autocomplete">
				{options.length && label ? (
					options.map((item: any, index: number) => (
						<div
							onClick={(e) => {
								addLabel(item);
							}}
							key={item.text + item.value}
							className={`autocomplete-item ${
								index === cursor
									? "autocomplete-item_active"
									: ""
							}`}
						>
							{item.text}
						</div>
					))
				) : label ? (
					<div
						onClick={() => addLabel({ name: label })}
						className="autocomplete-item autocomplete-empty"
					>
						Press enter to add "{label}"
					</div>
				) : (
					false
				)}
			</div>
		</div>
	);
};

export default Labels;
