import React from 'react'
import './message.scss';
import moment from 'moment';

type MessageProps = {
    avatar: string,
    full_name: string,
    created_at: any,
    content: string
}

const Message = ({avatar, full_name, created_at, content} : MessageProps) => {
    return (
        <div className="message">
            <div className="avatar">
                <img src={avatar} alt=""/>
            </div>
            <div className="message-wrapper">
                <p className="name">{full_name}</p>
                <div className="message-content">
                    {content}
                </div>
                <p className="date">{moment(created_at).format('DD-MM-YYYY')}</p>
            </div>
        </div>
    )
}

export default Message
