import React, { Component } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../reducers";
import { ApiUserObject } from "../../api/admin/users";
import "./userpicker.scss";
import TogglePicker from "./index";
import { Dropdown, DropdownProps } from "semantic-ui-react";

interface StateProps {
	users: any[];
	currentUserId: number;
}

interface OwnProps {
	noValue?: string;
	dropdownPlaceholder?: string;
	label?: string;
	onChange?: (value: any) => void;
	userId?: number;
	isDropdown?: boolean;
	isKanban?: boolean;
	multiple?: boolean;
	value?: any;
	loading?: boolean;
	[key: string]: any;
}

type Props = StateProps & OwnProps;

class UserPicker extends Component<Props> {
	static defaultProps = {
		noValue: "Not assigned",
		dropdownPlaceholder: "Select assignee",
		label: "Assigned to",
	};
	userToggle?: TogglePicker | null;
	userRef?: DropdownProps | null;

	render() {
		const {
			users,
			currentUserId,
			userId,
			isDropdown,
			isKanban,
			multiple,
			value,
			loading,
			...props
		} = this.props;

		const emptyUser = ({} as unknown) as ApiUserObject;
		let user = emptyUser;
		if (userId) {
			user = users.find((i) => i.value === userId);
		}
		// const user = userId ? stateUsers[userId] : emptyUser;
		// const userVals = Object.values(stateUsers);
		// const me = userVals.find((o) => o.id === currentUserId);
		// const others = userVals.filter((o) => o.id !== currentUserId);

		const getValue = () => {
			if (multiple) {
				return value;
			}
			return user ? user.id : "";
		};

		return (
			<div className="user-picker">
				{!isDropdown ? (
					<TogglePicker
						label={this.props.label}
						displayValue={
							user ? user.full_name : this.props.noValue
						}
						// onOpen={() => this.userRef ? this.userRef.searchRef.focus() : () => {}}
						ref={(r) => (this.userToggle = r)}
						image={user && user.avatar ? user.avatar : undefined}
						{...props}
					>
						<Dropdown
							multiple={multiple || false}
							placeholder={this.props.dropdownPlaceholder}
							search
							selection
							options={users}
							defaultValue={getValue()}
							fluid={!isDropdown}
							selectOnBlur={false}
							clearable
							ref={(r) => (this.userRef = r)}
							onClose={() =>
								this.userToggle && this.userToggle.hideEditor()
							}
							onChange={(e: any, { value }) => {
								if (value === "") {
									this.userToggle &&
										this.userToggle.hideEditor();
								}
								const user = this.props.users[value as number];
								if (this.props.onChange) {
									this.props.onChange(user);
								}
							}}
						/>
					</TogglePicker>
				) : (
					<Dropdown
						multiple={multiple || false}
						placeholder={this.props.dropdownPlaceholder}
						search
						selection
						loading={loading}
						options={users}
						value={getValue()}
						fluid
						selectOnBlur={false}
						clearable
						ref={(r) => (this.userRef = r)}
						onClose={() =>
							this.userToggle && this.userToggle.hideEditor()
						}
						onChange={(e: any, { value }) => {
							if (value === "") {
								this.userToggle && this.userToggle.hideEditor();
							}
							if (multiple) {
								if (this.props.onChange)
									this.props.onChange(value);
							} else {
								const user = this.props.users[value as number];
								if (this.props.onChange) {
									this.props.onChange(user);
								}
							}
						}}
					/>
				)}
			</div>
		);
	}
}

export default connect((state: ApplicationState, ownProps: OwnProps) => ({
	users: state.filters.users,
	currentUserId: state.auth.id,
}))(UserPicker);
