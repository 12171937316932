import React, { useState } from "react";
import { Input } from "semantic-ui-react";
import "./key-results.scss";
import { history } from '../../Routes';
import Button from "../Form/Button";
import api from "../../api/api";
import move from "array-move";
import { Container, Draggable } from "react-smooth-dnd";

type ResultItem = {
	id: number | null;
	subject?: string;
};

const KeyResults = ({
	results,
	setResults,
	onSave,
	forceEdit
}: {
	results: ResultItem[];
	setResults: (results: ResultItem[]) => void;
	onSave?: (val?: ResultItem[]) => void;
	forceEdit?: boolean;
}) => {
	const [ editMode, setEditMode ] = useState(forceEdit || false);

	const handleDelete = (index) => {
		let item = results[index];
		if (item.id) {
			api.destroyAction(item.id);
		}
		setResults(results.filter((item: any, i: number) => index !== i));
	};
	const handleChange = (index, str) => {
		let newItems = [...results];
		newItems[index] = { ...newItems[index], subject: str.length > 100 ? str.substring(0, 100) : str };
		setResults(newItems);
	};

	const handleDrop = ({ removedIndex: old, addedIndex: created }) => {
		const items = [...move(results, old, created)];
		setResults(items);
		if (onSave) {
			setEditMode(false);
			onSave(items)
		};
	};

	return (
		<div className="key-results">
			<label>
				Outputs / Key Results:
				{!forceEdit && <i className="fal fa-pencil status-dropdown-icon" onClick={() => setEditMode(!editMode)}></i>}
			</label>

			{editMode ? (<>
				<form
					onSubmit={(e) => {
						if (e) e.preventDefault();
						setResults([...results, { id: null, subject: "" }]);
					}}
				>
					{results?.length !== 0 && (
						<ul>
							<Container onDrop={handleDrop}>
								{results.map(
									(result: ResultItem, index: number) => (
										<Draggable key={`${index}-result-item`}>
											<Item
												{...{
													result,
													index,
													handleDelete,
													handleChange,
												}}
											/>
										</Draggable>
									)
								)}
							</Container>
						</ul>
					)}
				</form>
				<div className="key-results-buttons">
					<Button
						onClick={() =>
							setResults([...results, { id: null, subject: "" }])
						}
						type="transparent"
						icon="fa-plus"
					>
						Add more
					</Button>
					<div style={{ display: 'flex' }}>
						<Button type="link" onClick={() => setEditMode(false)}>
							Cancel
						</Button>
						{onSave && results?.length !== 0 && (
							<Button
								onClick={() => {
									if (onSave) {
										setEditMode(false);
										onSave();
									}
								}}
							>
								Save
							</Button>
						)}
					</div>
				</div>
			</>) : (
				<ol className="key-results-list">
					{results.map((o, i) => (
						<li
							onClick={()=> history.push(`/output/${o.id}`)}
							key={`res-${i}`}
						>
							{o.subject}
						</li>
					))}
				</ol>
			)}
		</div>
	);
};

type ItemProps = {
	result: ResultItem;
	index: number;
	handleChange: (index, value) => void;
	handleDelete: (index) => void;
};

const Item = ({ result, index, handleChange, handleDelete }: ItemProps) => {
	return (
		<li>
			<span className="number">{index + 1}</span>
			<Input
				value={result.subject}
				onChange={(e, { value }) => {
					handleChange(index, value);
				}}
				placeholder="Metrics"
				style={{ width: '60%'}}
			/>
			<div onClick={() => handleDelete(index)} className="icon">
				<i className="fas fa-trash"></i>
			</div>
		</li>
	);
};

export default KeyResults;
