import { createAction, handleActions } from "redux-actions";
import { Dispatch } from "redux";
import { ApiAccountObject } from "../api/admin/accounts";
import localforage from "localforage";

export interface AccountsState {
	accounts: ApiAccountObject[];
	minimal: Partial<ApiAccountObject>[];
	isFetching: boolean;
	error: any;
}

export const updateAccounts = createAction("UPDATE_ACCOUNTS");
export const updateAccount = createAction("UPDATE_ACCOUNT");
export const fetchAccounts = createAction("FETCH_ACCOUNTS");
export const deleteAccount = createAction("DELETE_ACCOUNT");
export const storeMinimalAccounts = createAction("storeMinimalAccounts");
export const setAccountError = createAction("setAccountError");
export const setAccountFetchingState = createAction("setAccountFetchingState");

export function fetchMinimalAccounts(request: any) {
	return (dispatch: Dispatch) => {
		dispatch(fetchAccounts());
		return request
			.then(res => dispatch(storeMinimalAccounts(res)))
			.catch((error: Error) => dispatch(setAccountError(error.message)));
	};
}

const accountsReducer = handleActions(
	{
		UPDATE_ACCOUNTS: (state, action) => {
			return {
				...state,
				accounts: action.payload,
				isFetching: false
			};
		},
		UPDATE_ACCOUNT: (state, action) => {
			const account: ApiAccountObject = action.payload;

			const index = state.accounts.findIndex(a => a.id === account.id);
			if (index !== -1 && account.id) {
				state.accounts[index] = account;
			} else if (account.id) {
				state.accounts.push(account);
			} else {
				throw new Error("Account should have an id");
			}

			state.isFetching = false;
			return { ...state };
		},
		DELETE_ACCOUNT: (state, action) => {
			const id = action.payload;
			return {
				...state,
				accounts: state.accounts.filter(a => a.id !== id),
				isFetching: false
			};
		},
		FETCH_ACCOUNTS: state => {
			return {
				...state,
				isFetching: true
			};
		},
		[storeMinimalAccounts.toString()]: (state, action) => {
			const accounts: ApiAccountObject[] = action.payload;
			localforage.setItem("minimalAccounts", accounts);
			return {
				...state,
				minimal: accounts.map(o => ({
					id: o.id,
					name: o.name,
					logo: o.logo,
					website: o.website
				}))
			};
		},
		[setAccountError.toString()]: (state, action) => {
			try {
				state.error = JSON.parse(action.payload);
			} catch (e) {
				state.error = action.payload;
			}
			return state;
		},
		[setAccountFetchingState.toString()]: (state, action) => {
			if (action.payload) {
				state.error = undefined;
			}
			state.isFetching = action.payload;
			return {
				...state,
				isFetching: action.payload
			};
		}
	},
	{
		accounts: [],
		minimal: [],
		isFetching: false,
		error: undefined
	}
);

export default accountsReducer;
