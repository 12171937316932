import React, { useState, useRef } from "react";
import "./hover-list.scss";
import useBlur from "../../lib/useBlur";

type HoverListProps = {
	element: any;
	children: any;
};

const HoverList = ({ element, children }: HoverListProps) => {
	const [visible, setVisible] = useState(false);
	const wrapper = useRef(null);
	useBlur(wrapper, (val) => setVisible(val));
	return (
		<div
			ref={wrapper}
			className={`hover-list ${visible && "hover-list_active"}`}
		>
			<div
				onClick={() => setVisible(true)}
				className="hover-list-element"
			>
				{element}
			</div>
			<div
				onClick={() => setVisible(false)}
				className="hover-list-children"
			>
				{children}
			</div>
		</div>
	);
};

type HoverListItemProps = {
	children: any;
	onClick: () => void;
};

export const HoverListItem = ({ children, onClick }: HoverListItemProps) => (
	<div onClick={onClick} className="hover-list-item">
		{children}
	</div>
);

export default HoverList;
