import qs from "qs";
import { ApiResponse } from "./api";
import { ApiAccountObject } from "./admin/accounts";
import { get } from "./utils";

export default {
	list: (q = "", qWith: string[] = [], props = {}) => {
		const query = qs.stringify({
			with: qWith.join(","),
			q,
			...props,
		});
		return get(`accounts?${query}`) as ApiResponse<ApiAccountObject[]>;
	},
	show: (id: number, qWith: string[] = [], props = {}) => {
		const query = qs.stringify({
			with: qWith.join(","),
			...props,
		});
		return get(`accounts/${id}?${query}`) as ApiResponse<ApiAccountObject>;
	},
	logs: () => {},
};
