import { createAction, handleActions } from "redux-actions";

interface FilterState {
	squads: number[];
	chapters: number[];
	needs: number[];
	assignees: number[];
	accounts: number[];
	query: string;
	archived: boolean;
	users: number[];
	need_scopes: number[];
	labels: number[];
	preset: number | null;
}

export interface FiltersState {
	actions: FilterState;
	needs: FilterState;
	users: any[];
}

export const emptyFilter: FilterState = {
	squads: [],
	chapters: [],
	needs: [],
	assignees: [],
	accounts: [],
	query: "",
	archived: false,
	users: [],
	need_scopes: [],
	labels: [],
	preset: null,
};

export const setFilter = createAction("setFilters");
export const setUsers = createAction("setUsers");
export const setPresetFilter = createAction("setPresetFilters");
export const clearFilter = createAction("clearFilters");
export const restoreLocalFilters = createAction("restoreLocal");

const filtersReducers = handleActions(
	{
		[setUsers.toString()]: (state, action) => {
			let { users } = action.payload;
			let data = {
				...state,
				users: users ? users : [],
			};
			localStorage.setItem("filters", JSON.stringify(data));
			return {
				...data,
			};
		},
		[setFilter.toString()]: (state, action) => {
			let { key, value, isNeed } = action.payload;
			let type = isNeed ? "needs" : "actions";
			let newFilter = { ...state[type], [key]: value };
			localStorage.setItem(
				"filters",
				JSON.stringify({ ...state, [type]: newFilter })
			);
			return {
				...state,
				[type]: newFilter,
			};
		},
		[setPresetFilter.toString()]: (state, action) => {
			let { filter, isNeed } = action.payload;
			let type = isNeed ? "needs" : "actions";

			localStorage.setItem(
				"filters",
				JSON.stringify({ ...state, [type]: filter })
			);
			return {
				...state,
				[type]: filter,
			};
		},
		[clearFilter.toString()]: (state, action) => {
			let { isNeed } = action.payload;
			let type = isNeed ? "needs" : "actions";
			localStorage.setItem(
				"filters",
				JSON.stringify({ ...state, [type]: emptyFilter })
			);
			return { ...state, [type]: emptyFilter };
		},
		[restoreLocalFilters.toString()]: (state, action) => {
			let filters = localStorage.getItem("filters");
			if (filters) {
				let parsed = JSON.parse(filters) as {
					[key: string]: any;
				};
				return {
					needs: parsed && parsed.needs ? parsed.needs : emptyFilter,
					actions:
						parsed && parsed.actions ? parsed.actions : emptyFilter,
					users: parsed && parsed.users ? parsed.users : [],
				};
			}
			return { ...state };
		},
	},
	{
		needs: emptyFilter,
		actions: emptyFilter,
		users: [],
	}
);

export default filtersReducers;
