import React from "react";
import { Link } from "react-router-dom";
import "./logo.scss";
import { connect } from "react-redux";
import { ApiProfileObject } from "../../../api/auth";
import logo from "../../../images/logo-white.svg";
import { REDIRECT_URL } from "../../../Routes";

const Logo = ({
	auth,
	noLink,
}: {
	auth: ApiProfileObject;
	noLink?: boolean;
}) => {
	if (noLink) {
		return (
			<div className="logo">
				<img src={logo} alt="" />
			</div>
		);
	}
	return (
		<Link to={auth.id ? REDIRECT_URL : "/"} className="logo">
			<img src={logo} alt="" />
		</Link>
	);
};

export default connect(
	(state: any) => ({
		auth: state.auth,
	}),
	{}
)(Logo);
