import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import localforage from "localforage";
import _ from "lodash";
import "./needs-sort.scss";

type SortProps = {
	items: any;
	handleChange: (items: any) => void;
};

const NeedsSort = ({ items, handleChange }: SortProps) => {
	const local_key = "need_sort";
	const [sort, setSort] = useState({
		direction: "ASC",
		by: "name",
	});

	const getLocalFilter = async () => {
		let localSort = (await localforage.getItem(local_key)) as any;
		if (localSort && localSort.direction && localSort.by) {
			setSort({
				...localSort,
			});
		}
	};

	const updateLocalFilter = async () => {
		await localforage.setItem(local_key, sort);
	};

	const filterItems = () => {
		const direction = sort.direction.toLowerCase();
		const by = sort.by || "title";
		if (direction === "created_at") {
			items = _.orderBy(
				items,
				(o) => {
					return new Date(o.created_at).getTime();
				},
				direction
			);
		} else {
			items = _.orderBy(items, by, direction);
		}
		handleChange(items);
	};

	useEffect(() => {
		filterItems();
		updateLocalFilter().then(() => {});
	}, [sort]);

	useEffect(() => {
		getLocalFilter().then(() => {});
	}, []);

	const ByOptions = [
		{ value: "name;ASC", text: "Title: alphabetical order" },
		{ value: "name;DESC", text: "Title: reversed alphabetical order" },
		{
			value: "created_by.first_name;ASC",
			text: "Created by: alphabetical order",
		},
		{
			value: "created_by.first_name;DESC",
			text: "Created by: reversed alphabetical order",
		},
		{ value: "created_at;DESC", text: "Date created: latest first" },
		{ value: "created_at;ASC", text: "Date created: oldest first" },
		{ value: "scope;ASC", text: "Goal Type: alphabetical order" },
		{ value: "scope;DESC", text: "Goal Type: reversed alphabetical order" },
	];

	return (
		<div className="needs-sort">
			<label>Sort by</label>
			<Dropdown
				style={{ width: 300 }}
				placeholder="Sort by"
				selection
				value={`${sort.by};${sort.direction}`}
				options={ByOptions.map((item) => ({
					...item,
					key: item.value,
				}))}
				onChange={(event, data) => {
					let value = data.value as string;
					let s = value.split(";");
					let direction = s[1];
					let by = s[0];
					setSort({
						direction,
						by,
					});
				}}
			/>
		</div>
	);
};

export default NeedsSort;
