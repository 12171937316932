import React from "react";
import "./progress-bar.scss";

const ProgressBar = ({ value }: { value: number }) => {
	return (
		<div className="progress-bar">
			<p>{value <= 1 ? (value * 100) : value}%</p>
			<div className="bar">
				<span
					style={{
						width: `${value <= 1 ? (value * 100) : value}%`,
					}}
				></span>
			</div>
		</div>
	);
};

export default ProgressBar;
