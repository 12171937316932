import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import "./status-dropdown.scss";
import useBlur from "../../../lib/useBlur";
import useDropdownFetch from "../../../lib/useDropdownFetch";
import Badge from "../../Badge";

type StatusDropdownProps = {
	onChange: (value: number[]) => void;
	value?: number[];
	options?: any[];
	label: string;
	multiple?: boolean;
	needId?: number;
	type?: string;
	isCreate?: boolean;
};

const StatusDropdown = ({
	onChange,
	value,
	options,
	label,
	multiple,
	needId,
	type,
	isCreate,
}: StatusDropdownProps) => {
	const [visible, setVisible] = useState(false);
	const [query, setQuery] = useState("");
	const [values, setValues] = useState([] as any);
	const [items, setItems] = useState(options ? options : []);
	const getIds = () => {
		let ids = value
			? value.map((v: any) => {
					// Wat is dit? Wat return je anders?
					if (v && v.id) {
						return v.id;
					} else if (v) {
						return v;
					}
			  })
			: [];
		return ids.filter((id) => id);
	};
	const [fetching, fetchOptions, allItems] = useDropdownFetch({
		type,
		query,
		all: false,
		focused: options ? false : true,
		ids: getIds(),
		needId,
		isCreate,
	}) as [boolean, any[], any[]];
	const dropdownRef = useRef(null);
	useBlur(dropdownRef, (o) => setVisible(o));

	useEffect(() => {
		setItems(fetchOptions);
	}, [fetchOptions]);

	useEffect(() => {
		if (options) {
			setItems(
				options.filter((o) =>
					o.text.toLowerCase().includes(query.toLowerCase())
				)
			);
		}
	}, [query]);

	useEffect(() => {
		let ids = getIds();
		if (options) {
			const items = options.filter(
				(option) =>
					ids.includes(option.value) || ids.includes(option.id)
			);
			setValues(items);
		} else {
			setItems(fetchOptions);
			const val = allItems.filter(
				(option) =>
					ids.includes(option.value) || ids.includes(option.id)
			);
			setValues(val);
		}
	}, [value, options, items]);

	const handleChange = (val) => {
		let ids = getIds();
		onChange(_.xor(ids, [val]));
		if (!multiple) {
			setVisible(false);
		}

		setValues(
			fetchOptions.filter(
				(option) =>
					ids.includes(option.value) || ids.includes(option.id)
			)
		);
		setQuery("");
	};

	return (
		<div
			ref={dropdownRef}
			className={`status-dropdown ${
				visible ? "status-dropdown_visible" : ""
			}`}
		>
			<label
				onClick={() => setVisible(!visible)}
				className="status-dropdown-label"
			>
				{label}
				<i className="fal fa-pencil status-dropdown-icon"></i>
			</label>
			<div
				onClick={() => setVisible(true)}
				className="status-dropdown-values"
			>
				{values.length ? (
					values.map((item) => (
						<Badge
							onClick={() =>
								visible ? handleChange(item.value) : {}
							}
							key={item.value + item.text}
						>
							{item.text}
						</Badge>
					))
				) : (
					<span className="empty">None</span>
				)}
			</div>

			<div className="status-options">
				<div className="status-options-input">
					{values.length
						? values.map((option) => (
								<div
									key={option.value + option.text}
									className={`status-options-input-item`}
								>
									<span className="label">{option.text}</span>
									<i
										onClick={() =>
											handleChange(option.value)
										}
										className="fal fa-times"
									></i>
								</div>
						  ))
						: false}
					<input
						type="text"
						onChange={(e) => setQuery(e.target.value)}
						placeholder={`Search ${label}`}
						value={query}
					/>
				</div>
				<div className="status-options-items">
					{items.map((option) => (
						<div
							onClick={() => handleChange(option.value)}
							key={option.value + option.text}
							className={`status-options-items-item ${
								value?.includes(option.value)
									? "status-options-items-item_active"
									: ""
							}`}
						>
							<span className="label">{option.text}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default StatusDropdown;
