import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { ApplicationState } from "../reducers";
import { setFilter } from "../reducers/filters";
import useIsNeed from "./useIsNeed";

const useFilterChange = (type: string, _isNeed: boolean) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const isNeed = useIsNeed();

	const value = useSelector(
		(state: ApplicationState) =>
			state.filters[isNeed ? "needs" : "actions"][type]
	);
	const [debouncedCallback] = useDebouncedCallback(
		// function
		(q) => {
			updateFilter(q);
		},
		// delay in ms
		1000
	);

	const updateFilter = (value: any) => {
		dispatch(
			setFilter({
				key: type,
				value,
				isNeed,
			})
		);
	};

	const onChange = (value: any) => {
		if (type === "query") {
			debouncedCallback(value);
		} else {
			updateFilter(value);
		}

		if (isNeed) {
			history.push("/outcome");
		} else {
			history.push("/output");
		}
	};
	return [value, onChange] as [any, (value: any) => void];
};

export default useFilterChange;
