import React, { useState, useRef, useEffect } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "./description.scss";
import Editor from "../../Form/Editor";
import useBlur from "../../../lib/useBlur";

const Description = ({
	value,
	noTitle,
	onUpdate,
	label,
}: {
	value: any;
	noTitle?: boolean;
	label?: string,
	onUpdate?: (text: string) => void;
}) => {
	const [copyValue, setCopyValue] = useState(value);
	const [isSmall, setSmall] = useState(false);
	const [edit, setEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const descriptionRef = useRef(null);
	const content = useRef(null);
	useBlur(descriptionRef, (visible) => {
		// setEdit(visible);
	});

	const getMarkdown = () => {
		if (copyValue) {
			try {
				const content = stateToHTML(
					convertFromRaw(JSON.parse(copyValue))
				);
				return content;
			} catch (error) {
				return copyValue;
			}
		}
		return "";
	};

	useEffect(() => {
		setOpen(false);
		setCopyValue(value || "");
		checkHeight();
	}, [value]);

	useEffect(() => {
		setOpen(false);
		setCopyValue(copyValue || "");
		checkHeight();
	}, [copyValue]);

	const checkHeight = () => {
		if (copyValue && content) {
			let { offsetHeight }: any = content.current;
			if (offsetHeight >= 80) {
				setSmall(true);
			} else {
				setSmall(false);
			}
		}
	};

	const EditIcon = () => (
		<i
			onClick={() => setEdit(!edit)}
			className="fal icon-edit fa-pencil"
		></i>
	);

	const renderContent = () => {
		if (edit) {
			return (
				<>
					<Editor
						placeholder={label || "Outcome/ Objective"}
						defaultValue={copyValue}
						onDone={(text) => {
							if (onUpdate) onUpdate(text);
							setEdit(false);
							setCopyValue(text);
						}}
					/>
				</>
			);
		} else if (getMarkdown()) {
			return (
				<>
					<div
						onClick={() => setEdit(!edit)}
						className="description-wrapper"
					>
						<div
							ref={content}
							dangerouslySetInnerHTML={{ __html: getMarkdown() }}
							className="description-content"
						></div>
						{!isSmall && <EditIcon />}
					</div>
					{isSmall && (
						<div
							onClick={() => setOpen(!open)}
							className="readmore"
						>
							<span>Read {open ? "less" : "more"}</span>
							<EditIcon />
						</div>
					)}
				</>
			);
		}
		return (
			<div onClick={() => setEdit(!edit)} className="description-wrapper">
				<div className="description-empty">
					<p>No description provided</p>
				</div>
				<EditIcon />
			</div>
		);
	};

	return (
		<div
			ref={descriptionRef}
			className={`description ${isSmall ? "description-small" : ""} ${
				open ? "description-open" : ""
			}`}
		>
			{!noTitle && <label>{label || 'Outcome/ Objective'}</label>}
			{renderContent()}
		</div>
	);
};

export default Description;
