import config from "../config.json";
import store from "../store";
import { login } from "../reducers/auth";

export function isGoogleUser() {
	const authInstance = gapi.auth2.getAuthInstance();
	const isSignedIn = authInstance.isSignedIn.get();
	return isSignedIn;
}

export function init() {
	window.removeEventListener("mwitGapiLoaded", init);
	if ((window as any).gapi) {
		gapi.load("client:auth2", initClient);
	} else {
		window.addEventListener("mwitGapiLoaded", init);
	}
}

async function initClient() {
	await gapi.client.init({
		apiKey: config.googleDriveApiKey,
		clientId: config.googleClientID,
		// discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
		scope:
			"https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly",
	});
	onSignedInChange();
	gapi.auth2.getAuthInstance().isSignedIn.listen(onSignedInChange);
}

export async function onSignedInChange() {
	const authInstance = gapi.auth2.getAuthInstance();
	const isSignedIn = isGoogleUser();
	// const token = localStorage.getItem("a_token");
	// if (isSignedIn && !token) {
	// 	const user = authInstance.currentUser.get();
	// 	const authResponse = user.getAuthResponse(true);
	// 	store.dispatch(await login(authResponse));
	// }
}

export async function signIn() {
	const auth2 = gapi.auth2.getAuthInstance();
	return auth2.signIn();
}

export function signOut() {
	return gapi.auth2.getAuthInstance().signOut();
}

export default {
	init,
	signIn,
	signOut,
};
