import store from "../store";
import { ApiProfileObject } from "../api/auth";

export default function prefetch() {
	let { auth } = store.getState();
	if ((auth as ApiProfileObject).id) {
		// fetchMinimalFilters();
		// fetchMinimalAccounts(api.listAccounts())
		// fetchUserUsers(api.listUsers());
		// fetchMinimalFilters(FetchApiFilters());
	}
}

export function subscribeToStore() {
	// restoreFromLocal();
	let unsubscribe = store.subscribe(() => {
		if (store.getState().auth) {
			prefetch();
			unsubscribe();
		}
	});
}
