import React from "react";
import { Header } from "semantic-ui-react";
import icon from "../../images/empty-error.png";
import noResults from "../../images/no-results.png";
import loader from "./loader.svg";
import styles from "./styles.module.scss";

interface EmptyStateProps {
	isLoading?: boolean;
	noResults?: boolean;
}

export default function EmptyState(props: EmptyStateProps) {
	return (
		<div className={styles.container}>
			<div>
				{props.isLoading === true ? (
					<div>
						<img src={loader} alt="" />
					</div>
				) : (
					<>
						<img src={props.noResults ? noResults : icon} alt="" />
						<Header as="h1">
							{props.noResults === true
								? "No results found"
								: "Start with creating a filter!"}
						</Header>
						<p>
							Try setting a different filter or select another
							saved filter from the dropdown "My filters" in the
							searchbar.
						</p>
					</>
				)}
			</div>
		</div>
	);
}
