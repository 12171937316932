import React from "react";
import DeadlinePicker from "../Form/DeadlinePicker";
import AssigneePicker from "../Form/AssigneePicker";
import api from "../../api/api";
import { ApiActionObject } from "../../api/actions";

type TodoItemProps = {
	onChange: (data: any) => void;
	onDelete: () => void;
	onAddSub?: () => void;
	action: ApiActionObject;
	lvl: number;
};

const TodoItem = ({ action, onChange, onDelete, lvl, onAddSub }: TodoItemProps) => {
	const classes = ["todo-item"];
	if (lvl > 0) classes.push("todo-item_sub");
	if (action.is_done) classes.push("todo-item_active");

	const handleChange = async (data: any) => {
		api.updateAction(action.id, data).then((res: ApiActionObject) => {
			onChange(res);
		});
	};

	const handleAddSub = async () => {
		if (onAddSub) {
			onAddSub();
		}
	}

	const handleDelete = () => {
		if (
			!window.confirm(
				"Are you sure you want to delete this checklist item?"
			)
		) {
			return;
		}
		onDelete();
		api.destroyAction(action.id);
	};

	return (
		<div className={classes.join(" ")}>
			<div
				onClick={() => {
					handleChange({ is_done: !action.is_done });
				}}
				className="checkbox"
			>
				<i className="fas fa-check"></i>
			</div>
			<div className="todo-item-label">
				<p>{action.subject}</p>
			</div>
			{lvl === 0 && <div onClick={handleAddSub} className="delete-icon">
				<i className="fal fa-plus"></i>
			</div>}
			<div onClick={handleDelete} className="delete-icon">
				<i className="fal fa-trash"></i>
			</div>
			<DeadlinePicker
				popup
				value={action.deadline}
				action={action}
				onChange={(value) => {
					handleChange({ deadline: value });
				}}
			/>
			<AssigneePicker
				iconOnly
				value={action.assignee_id}
				onChange={(id) => handleChange({ assignee_id: id })}
			/>
		</div>
	);
};

export default TodoItem;
