import React, { Component } from 'react';
import { Image, Button, Container, Segment, Message, Menu, Table, Modal, Icon } from 'semantic-ui-react';
import styles from './Proposition.module.scss';
import canvas from './value-proposition-canvas.png';
import canvas2 from './canvas-bc.jpg';
import { withRouter } from 'react-router-dom';
import { FilterState, emptyFilter } from '../../api/filters';
import api from '../../api/api';
import { ApiSquadObject } from '../../api/admin/squads';
import Layout from '../../components/Layout';
const { Cell } = Table;

interface PropositionState {
  tab: number;
  filter: FilterState,
  filtersLoaded: number,
  squad?: ApiSquadObject,
  loaded: boolean,
}

interface MatchParams {
  squadId?: string;
}


class Proposition extends Component<any, PropositionState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tab: 0,
      filter: emptyFilter,
      filtersLoaded: 0,
      squad: undefined,
      loaded: false,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.squadId) {
      api.showSquad(parseInt(this.props.match.params.squadId)).then((squad) => {
        this.setState({ squad, loaded: true });
      });
    } else {
      this.setState({ loaded: true });
    }
  }

  render() {
    const { tab, squad, loaded } = this.state;

    if (!loaded) {
      return null;
    }

    if (squad && squad.content && squad.content.length > 0) {
      return (
        <Layout>
          <Container text>
            <div className={styles.card}>
              {/* <Description value={squad.content} noTitle /> */}
              <div dangerouslySetInnerHTML={{ __html: squad.content }} />
            </div>
            
          </Container>
        </Layout>
      )
    }

    let questions = (
      <ul style={{ fontSize: 16, padding: 30, paddingLeft: 50 }}>
        <li>Waar zijn er mogelijkheden een meer optimale patiëntenzorg te bieden?</li>
        <li>Waar zitten pijnpunten, ook niet direct aan patiëntenzorg gerelateerd, die uw praktijkvoering in de weg staan?</li>
        <li>Wat zijn de speerpunten waarop u wilt ontwikkelen?</li>
      </ul>
    );

    if (this.props.match.params.squadId === '11') {
      questions = (
        <ul style={{ fontSize: 16, padding: 30, paddingLeft: 50 }}>
          <li>Landscaping DLBCL (zie CRM).</li>
          <li>Uitvragen behoefte en rol PROMs binnen DLBCL.</li>
          <li>Bepalen welke waarde een behandeling binnen R/R DLBCL moet hebben.</li>
        </ul>
      )
    }

    if (this.props.match.params.squadId === '13') {
      questions = (<>
        <b>Thema budget:</b><br />
        <ul style={{ fontSize: 16, padding: '0px 30px 30px 50px' }}>
          <li>Hoe gaat het proces van budgetaanvragen vanuit neurologie? (proces, obstakels j/n, wat gaat goed, is dit op MS middelen of specifiek op product gelabeld etc)</li>
          <li>Is er al budget aangevraagd voor 2020? </li>
          <li>Heeft de neuroloog een reële inschatting gemaakt van het verwacht aantal patiënten bij de aanvraag? --> rekening gehouden met bestaande én nieuwe patiënten!</li>
          <li>Hoe bepaal je welke patiënt in aanmerking komt voor Ocrevus?</li>
          <li>Past dit binnen je budgetafspraken?</li>
        </ul>
        <b>Thema zorg:</b><br />
        <ul style={{ fontSize: 16, padding: '0px 30px 30px 50px' }}>
          <li>Waar zijn er mogelijkheden een meer optimale patiëntenzorg te bieden?</li>
          <li>Waar zitten pijnpunten, ook niet direct aan patiëntenzorg gerelateerd, die uw praktijkvoering in de weg staan?</li>
          <li>Wat zijn de speerpunten waarop u wilt ontwikkelen?</li>
        </ul>
        </>);
    }

    return (
        <Layout>
            <div>
                <Container style={{ marginTop: 20, marginBottom: 30 }}>
                <Message warning>
                    <Icon name="exclamation triangle" />
                    Shown information is not valid until squad has updated specificities
                </Message>
                <Menu secondary pointing>
                    <Menu.Item active={tab === 0} link onClick={() => this.setState({ tab: 0 })}>
                    Value Proposition
                    </Menu.Item>
                    <Menu.Item link>
                    {this.props.match.params.squadId === '13' ? (
                        <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1IwHe0_RUgt42Dl4VkCAnEQGCCUDEGMWjpzBiEnbi5dE/edit" style={{ color: '#000000' }}>
                        Fact sheet
                        </a>
                    ) : (
                        <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1CrtllPzjFdJfev2i1l1rIVXiNHeE-zE3H6hPRBmwS90/edit" style={{ color: '#000000' }}>
                        Fact sheet
                        </a>
                    )}
                    </Menu.Item>
                    <Menu.Item active={tab === 2} link onClick={() => this.setState({ tab: 2 })}>
                    Questions
                    </Menu.Item>
                    <Menu.Item active={tab === 3} link onClick={() => this.setState({ tab: 3 })}>
                    Collaboration overview
                    </Menu.Item>
                </Menu>
                </Container>
                {tab === 0 && (
                <Container text>
                    <Segment>
                    <p style={{ fontSize: 16, padding: 30 }}>
                        {this.props.match.params.squadId === '8' ? <Image src={canvas2} /> : <Image src={canvas} />}
                    </p>
                    </Segment>
                </Container>
                )}
                {tab === 2 && (
                <Container text>
                    <Segment>
                    {questions}
                    </Segment>
                </Container>
                )}
                {tab === 3 && (
                <div>
                    <div style={{ display: 'inline-block' }}>
                    
                    </div>
                    <Segment>
                    <Table definition className={styles.table}>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell className={styles.stepArrow}>
                            Monitoring/preventing
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.stepArrow}>
                            <span>Diagnosing</span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.stepArrow}>
                            <span>Preparing</span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.stepArrow}>
                            <span>Intervening</span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.stepArrow}>
                            <span>Recovering/rehabing</span>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            <span>Monitoring/managing</span>
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        <Table.Row>
                            <Cell><b>Roche Solutions</b></Cell>
                            <Cell />
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Roche Diagnostics</a>} size="mini">
                                <Modal.Header>Roche Diagnostics</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Roche ontwikkelt diagnostische oplossingen die een bijdrage leveren aan de kwaliteit van leven van de patiënt en aan een efficiënte zorg.
                                    <br /><Button as="a" rel="noopener noreferrer" href="https://roche-diagnostics.nl/diagnostiek/producten-en-oplossingen/" target="_blank" style={{ margin: '20px 0' }} primary>Naar de website</Button><br />
                                    Voor meer informatie neem contact op met Roche Diagnostics +3136 539 4911
                                </p>
                                </Modal.Content>
                            </Modal><br />
                                <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Genomic Profiling</a>} size="mini">
                                <Modal.Header>Genomic Profiling</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Via Foundation Medicine combineert Roche genomische profielen en data om een zo passend mogelijke behandeloptie te vinden op maat voor de patient.
                                    <br /><Button as="a" rel="noopener noreferrer" href="https://www.foundationmedicine.com/" target="_blank" style={{ margin: '20px 0' }} primary>Naar de website</Button><br />
                                    Voor meer informatie neem contact op met Jeske Timmermans, jeske.timmermans@roche.com, +31650640666
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Decision Support</a>} size="mini">
                                <Modal.Header>Decision Support</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Roche biedt een tool om te ondersteunen in besluitvorming vanuit verschillende disciplines dat kan helpen bij voorbeeld een multidisciplinair overleg. 
                                    <br /><Button as="a" rel="noopener noreferrer" href="https://www.navify.com/" target="_blank" style={{ margin: '20px 0' }} primary>Naar de website</Button><br />
                                    Voor meer informatie neem contact op met Roche Diagnostics +31365394911 of Bart van den Bogaard, bart.van.den_Bogaard@roche.com, +31620811594
                                </p>
                                </Modal.Content>
                            </Modal><br />
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Information Sources</a>} size="mini">
                                <Modal.Header>Information Sources</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Roche merkte dat er behoefte is aan informatie over medische zaken in eenvoudige taal. Naar aanleiding hiervan heeft Roche twee boekjes over borstkanker gemaakt, speciaal voor laaggeletterden. Vragen als ‘Wat is borstkanker, wat doet een oncoloog, wat gebeurt er bij een chemokuur en wat zijn hormoonpillen?’ worden hier op een eenvoudige manier beantwoord.<br /><br />
                                    Voor meer informatie neem contact op met Sietse van Steenis, sietse.van_steenis@roche.com, +31622415250
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Medicines</a>} size="tiny">
                                <Modal.Header>Medicines</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Roche werkt aan een toekomst waarin ongeneeslijke ziektes te genezen zijn. Wij zijn voortdurend bezig met het ontwikkelen van behandelingen en medicijnen om de kwaliteit van leven van patiënten en hun naasten te verhogen. Wij zijn actief in verschillende ziektegebieden. 
                                    <br /><Button as="a" rel="noopener noreferrer" href="https://www.roche.nl/nl/productennew.html" target="_blank" style={{ margin: '20px 0' }} primary>Naar de website</Button><br />
                                    Voor meer informatie neem contact op met;<br />
                                    Roche contact Lung Cancer - Xander van Breda-Vriesman<br />
                                    Roche contact Heamophila - Tam Vo<br />
                                    Roche contact Breast Cancer - Lennart Molenaars<br />
                                    Roche contact Hematology - Marc Hilkuijsen<br />
                                    Roche contact Neurology - Annika Schoenmaker<br />
                                    Roche contact Early pipeline - Anneloes van Walsem<br />
                                    Roche contact Late portfolio - Arthur Storm
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell />
                            <Cell />
                        </Table.Row>
                        <Table.Row>
                            <Cell><b>Stakeholder Collaboration</b></Cell>
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Alliantie Nederland Rookvrij</a>} size="mini">
                                <Modal.Header>Alliantie Nederland Rookvrij</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    We staan met z’n allen steeds vaker stil bij het belang van een gezonde leefstijl. Door voldoende te bewegen, gezond te eten en niet te roken voorkom je namelijk veel ziektes. We weten ook dat roken één van de belangrijkste oorzaken is van kanker. Daarom zetten we ons als partner van Alliantie Nederland Rookvrij in voor een rookvrije generatie.<br /><br />
                                    Voor meer informatie neem contact op met Guido Hallie, guido.hallie@roche.com, +31622415249
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell />
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Medical Education</a>} size="mini">
                                <Modal.Header>Medical Education</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Samen met tal van experts maakt Roche het mogelijk om kennis op te doen en te leren van praktijkervaringen rondom kankerimmuuntherapie met diverse zorgprofessionals.
                                    <br /><Button as="a" rel="noopener noreferrer" href="https://www.immuunpro-symposium.nl/" target="_blank" style={{ margin: '20px 0' }} primary>Naar de website</Button><br />
                                    Voor meer informatie neem contact op met Jacqueline Sosef, Voor meer informatie neem contact op met Karlijn van der List, karlijn.van_der_list@roche.com, +31621106002
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell>
                            <Modal trigger={<a href="/" onClick={(e: any) => e.preventDefault()}>Care@Home</a>} size="mini">
                                <Modal.Header>Care@Home</Modal.Header>
                                <Modal.Content>
                                    <p>
                                    Samen met verschillende partners gaan wij op zoek naar een duurzame oplossing voor complexe (oncologische) behandelingen in de thuissituatie of lokatie buiten het ziekenhuis, op behoefte van de patient.<br /><br />
                                    Voor meer informatie neem contact op met Jacqueline Sosef, jacqueline.sosef@roche.com, +31611340129
                                </p>
                                </Modal.Content>
                            </Modal>
                            </Cell>
                            <Cell />
                            <Cell />
                        </Table.Row>
                        </Table.Body>
                    </Table>
                    </Segment>
                </div>
                )}
            </div>
      </Layout>
    );
  }
}

export default withRouter((Proposition));
