import React from 'react'
import { NavLink } from 'react-router-dom';
import './navitem.scss';

type NavItemProps = {
    to?: string,
    label: string,
    children?: NavItemProps[]
}

const NavItem = ({to, label, children} : NavItemProps) => {

    if(!to){
        return (
            <div 
                className={`nav-item`} 
            >
            <span className="label">
                {label}
            </span>
            {children && children.length && (
                <div className="children">
                    {children.map((child: NavItemProps) => (
                        <NavLink
                            key={child.label + child.to}
                            activeClassName="children-item_active" 
                            className={`children-item`} 
                            to={child.to || false}
                        >
                            <span className="label">
                                {child.label}
                            </span>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
        )
    }

    return (
        <NavLink 
            activeClassName="nav-item_active" 
            className={`nav-item`} 
            to={to}
        >
            <span className="label">
                {label}
            </span>
            {children && children.length && (
                <div className="children">
                    {children.map((child: NavItemProps) => (
                        <NavLink
                            key={child.label + child.to}
                            activeClassName="children-item_active" 
                            className={`children-item`} 
                            to={child.to || false}
                        >
                            <span className="label">
                                {child.label}
                            </span>
                        </NavLink>
                    ))}
                </div>
            )}
        </NavLink>
    )
}

export default NavItem
