import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Table,
	Image,
	Segment,
	Button,
	Grid,
	Header,
	Input,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import UserEditView from "./UserEditView";
import {
	fetchUsers,
	fetchWhitelist,
	updateUser,
	deleteUser,
	setUsersFetchingState,
} from "../../../reducers/users";
import { isRouterBlocked } from "../../../Routes";
import { ApiUserObject } from "../../../api/admin/users";
import api from "../../../api/api";
import Layout from "../../../components/Layout";
import { ApplicationState } from "../../../reducers";

let searchTimer: any;

interface UsersProps {
	users: { [key: number]: ApiUserObject };
	whitelist: { [key: number]: ApiUserObject };
	isUsersFetching: boolean;
	isWhitelistFetching: boolean;
	fetchUsers: (promise: any) => any;
	fetchWhitelist: (promise: any) => any;
	updateUser: (promise: any) => any;
	deleteUser: (obj: any) => any;
	setUsersFetchingState: (state: boolean) => void;
	match: any;
}

export class Users extends Component<UsersProps> {
	state = {
		isSearching: false,
	};
	unlock: any;

	componentDidMount() {
		this.loadUsers();
		this.loadWhitelist();
	}

	async loadUsers(query = "") {
		this.props.fetchUsers(api.admin.listUsers(query, false));
	}

	async loadWhitelist(query = "") {
		this.props.fetchWhitelist(api.admin.listUsers(query, true));
	}

	deleteUser = async (id: number) => {
		if (
			window.confirm(
				"Are you sure you want to delete this user? Linked customer needs and actions will still be available"
			)
		) {
			this.props.deleteUser({
				request: api.admin.destroyUser(id),
				id: id,
			});
		}
	};

	onUserEditClick = () => {
		// The user edit screen starts with loading some data. This allows showing
		// a loader right away.
		if (!isRouterBlocked()) {
			// this.props.setUsersFetchingState(true);
		}
	};

	searchUsers = (query: string) => {
		// TODO: decide if we cache all users and search locally.
		clearTimeout(searchTimer);
		searchTimer = setTimeout(() => {
			this.setState({ isSearching: true });
			this.loadUsers(query).then(() =>
				this.setState({ isSearching: false })
			);
		}, 300);
	};

	renderUsers() {
		let { users } = this.props;

		return Object.keys(users).map((k) => {
			const user = users[parseInt(k)];
			return (
				<Table.Row key={user.email_address}>
					<Table.Cell collapsing>
						{user.avatar && <Image src={user.avatar} avatar />}
					</Table.Cell>
					<Table.Cell>
						<Link
							to={`/users/${user.id}/edit`}
							onClick={this.onUserEditClick}
						>
							{user.first_name} {user.last_name}
						</Link>
					</Table.Cell>
					<Table.Cell collapsing singleLine>
						{user.email_address}
					</Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell collapsing textAlign="right">
						<Link
							to={`/users/${user.id}/edit`}
							onClick={this.onUserEditClick}
						>
							<Button basic icon="pencil" />
						</Link>

						<Button
							onClick={() => user.id && this.deleteUser(user.id)}
							basic
							icon="trash alternate outline"
						/>
					</Table.Cell>
				</Table.Row>
			);
		});
	}

	renderWhitelist() {
		const { whitelist } = this.props;

		return Object.keys(whitelist).map((k) => {
			const user = whitelist[parseInt(k)];
			return (
				<Table.Row key={user.email_address}>
					<Table.Cell>{user.email_address}</Table.Cell>
					<Table.Cell collapsing textAlign="right">
						<Button
							onClick={() => user.id && this.deleteUser(user.id)}
							basic
							icon="trash alternate outline"
						/>
					</Table.Cell>
				</Table.Row>
			);
		});
	}

	render() {
		const { isSearching } = this.state;
		const { isUsersFetching, whitelist } = this.props;

		return (
			<Layout>
				<div style={{ display: 'flex' }}>
					<div style={{ flex: 1 }}>
						<Segment loading={isUsersFetching}>
							<Header as="h2">Users</Header>
							<Input
								fluid
								loading={isSearching}
								placeholder="Search..."
								onChange={(e) => {
									this.searchUsers(e.target.value);
								}}
							/>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell />
										<Table.HeaderCell>
											Name
										</Table.HeaderCell>
										<Table.HeaderCell>
											Email address
										</Table.HeaderCell>
										<Table.HeaderCell>
											Role
										</Table.HeaderCell>
										<Table.HeaderCell textAlign="right">
											Actions
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{this.renderUsers()}
								</Table.Body>
							</Table>
						</Segment>
					</div>
					<div style={{ marginLeft: 30, width: 450 }}>
						<Segment>
							<Header as="h2">Whitelist an user</Header>
							<UserEditView />
						</Segment>
						{Object.keys(whitelist).length > 0 && (
							<Table>
								<Table.Body>
									{this.renderWhitelist()}
								</Table.Body>
							</Table>
						)}
					</div>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	users: state.users.users,
	whitelist: state.users.whitelist,
	isUsersFetching: state.users.isUsersFetching,
	isWhitelistFetching: state.users.isWhitelistFetching,
});

const mapDispatchToProps = {
	fetchUsers,
	fetchWhitelist,
	updateUser,
	deleteUser,
	setUsersFetchingState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
