import React, { useState } from "react";
import "./filepicker.scss";
import { pickFiles, askPermission } from "../../../lib/Drive";
import api from "../../../api/api";
import { ApiGoogleDriveDocumentObject } from "../../../api/actions";

type FilePickerProps = {
	button?: any;
	id?: number;
	onAdd?: (file: ApiGoogleDriveDocumentObject) => void;
	onPick?: (file: ApiGoogleDriveDocumentObject) => void;
};

const FilePicker = ({ button, id, onAdd, onPick }: FilePickerProps) => {
	const [visible, setVisible] = useState(false);

	const handleFilePick = async () => {
		setVisible(true);
		//Ask permission if not ready granted
		askPermission(
			() => {
				pickFiles((data) => {
					if (data && data.docs) {
						handleUpload(data.docs);
					} else if (data && data.action === "cancel") {
						setVisible(false);
					}
				});
			},
			() => {
				// alert("You have to grant permission");
				setVisible(false);
			}
		);
	};

	const handleUpload = async (docs) => {
		const doc = docs[0];
		const driveDocument: ApiGoogleDriveDocumentObject = {
			name: doc.name,
			iconUrl: doc.iconUrl,
			url: doc.url,
		};
		if (onPick) {
			onPick(driveDocument);
		}
		if (id) {
			api.storeActionDocuments(id, driveDocument).then((res: any) => {
				driveDocument.id = res.id;
				if (onAdd) onAdd(res);
			});
		}
		setVisible(false);
	};

	return (
		<div className="file-picker">
			<div onClick={handleFilePick} className="file-picker_button">
				{visible ? (
					<div className="cancel">
						<i className="fas fa-spin fa-spinner-third"></i>
					</div>
				) : (
					button
				)}
			</div>
		</div>
	);
};

export default FilePicker;
