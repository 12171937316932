import { ApiResponse } from "../api";
import { get, post, patch, del } from "../utils";
import { ApiSquadObject } from "./squads";

export default {
	list: () => {
		return get(`pillars`) as ApiResponse<ApiSquadObject[]>;
	},
	show: (id: Number) => {
		return get(`pillars/${id}`) as ApiResponse<ApiSquadObject>;
	},
	create: (name: string) => {
		return post(`admin/pillars`, { name }) as ApiResponse<ApiSquadObject>;
	},
	update: (id: number, name: string) => {
		return patch(`admin/pillars/${id}`, { name }) as ApiResponse<
			ApiSquadObject
		>;
	},
	delete: (id: number) => {
		return del(`admin/pillars/${id}`) as ApiResponse<any>;
	},
};
