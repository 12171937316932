import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./showaction.scss";
import Modal from "../../../components/Modal";
import Statuses from "../../../components/Form/Statuses";
import Description from "../../../components/Layout/Description";
import Button from "../../../components/Form/Button";
import api from "../../../api/api";
import Comments from "../../../components/Comments";
import DeadlinePicker from "../../../components/Form/DeadlinePicker";
import AssigneePicker from "../../../components/Form/AssigneePicker";
import Actions from "../../../components/Form/Actions";
import {
	ApiActionObject,
	ApiGoogleDriveDocumentObject,
} from "../../../api/actions";
import DuplicateList from "../../../components/Form/Actions/DuplicateList";
import socket from "../../../lib/socket";
import GenerateLink from "../../../components/GenerateLink";
import CollaboratorPicker from "../../../components/CollaboratorPicker";
import TodoList from "../../../components/TodoList";

const ShowAction = ({
	visible,
	onClose,
	value,
	refetch,
	updateAction,
	currentUserId,
}) => {
	const [removing, setRemoving] = useState(-1);
	const [showMode, setShowMode] = useState(true);
	const [isFetching, setFetching] = useState(true);
	const [collabFetching, setColabFetching] = useState(false);
	const [showClone, setClone] = useState(false);
	const [action, setAction] = useState({} as ApiActionObject);
	const [collaboratorIds, setCollaboratorsId] = useState([] as number[]);
	useEffect(() => {
		fetchAction();
	}, [value]);
	const isWatching = collaboratorIds.includes(currentUserId);

	const fetchAction = () => {
		setFetching(true);
		if (value.id) {
			api.showAction(value.id, [
				"squads",
				"chapters",
				"need",
				"accounts",
				"pages",
				"documents",
				"labels",
				"collaborators",
				"products",
			]).then((res: ApiActionObject) => {
				let ids = res.collaborators.map((c) => c.id);
				setCollaboratorsId(ids);
				setAction(res);
				setFetching(false);
			});
		}
	};

	const handleUpdate = (newValue) => {
		api.updateAction(action.id, newValue)
			.then((res) => {
				let ids = res.collaborators.map((c) => c.id);
				setCollaboratorsId(ids);
				setAction({ ...res });
				refetch();
			})
			.then(() => sendSocket({ ...action, ...newValue }))
			.catch(console.log);
		// updateAction({ ...action, ...newValue });
	};

	const toggleColabrator = (id: number) => {
		let collaborators = collaboratorIds;
		if (collaborators.includes(id)) {
			collaborators = collaborators.filter((i) => i !== id);
		} else {
			collaborators.push(id);
		}
		setColabFetching(true);
		updateCollabrators(collaborators);
	};

	const updateCollabrators = async (collaborators: number[]) => {
		setCollaboratorsId(collaborators);
		return api
			.updateAction(action.id, { collaborators })
			.then((res) => {
				setAction({ ...res });
				return res;
			})
			.then((a: any) => sendSocket(a))
			.catch(console.log)
			.finally(() => setColabFetching(false));
	};

	const handleClone = (accounts: any[]) => {
		if (!window.confirm("Are you want to clone this action?")) {
			return;
		}

		api.duplicateAction(action.id, accounts)
			.then((res) => {
				setClone(false);
			})
			.then(() => refetch());
	};

	const sendSocket = (action: any) => {
		socket.emit("action changed", action);
	};

	const handleRemoveDocument = (documentId: number) => {
		setRemoving(documentId);
		api.deleteActionDocument(action.id, documentId)
			.then(() => {
				let newAction = {
					...action,
					documents: action.documents
						? action.documents.filter(
								(item: ApiGoogleDriveDocumentObject) =>
									item.id !== documentId
						  )
						: [],
				};
				setAction(newAction);
				setRemoving(-1);
				updateAction(newAction);
			})
			.catch(() => {
				alert("Something went wrong deleting");
				setRemoving(-1);
			});
	};

	const handleStatuses = async (change) => {
		if (change.status && change.status !== action.status) {
			socket.emit("action status change", {
				id: action.id,
				status: change.status,
			});
		} else {
			socket.emit("action changed", { ...action, ...change });
		}
		setAction({ ...action, ...change });
		refetch();
	};

	const handleUnarchive = async () => {
		let newValue = {
			...action,
			archived_at: "",
		};
		await api.updateAction(action.id, { archived_at: "" });
		setAction(newValue);
		refetch();
	};

	return (
		<Modal
			loading={isFetching}
			buttons={!showClone && <Button onClick={onClose}>Close</Button>}
			title={<h1>{action.subject}</h1>}
			title_extra={
				<>
					<Actions
						disabled={action && action.archived_at ? true : false}
						refetch={refetch}
						onAddFile={(file) => {
							setAction({
								...action,
								documents: action.documents
									? [...action.documents, file]
									: [file],
							});
							refetch();
						}}
						showClone={showClone}
						onClickClone={() => setClone(!showClone)}
						onClose={onClose}
						isNeed={false}
						id={action.id}
					/>
				</>
			}
			onClose={() => {
				onClose();
				if (refetch) {
					refetch();
				}
				setShowMode(true);
				setClone(false);
				setAction({} as ApiActionObject);
			}}
			visible={visible}
			alert={
				action && action.archived_at ? (
					<div className="alert-archived">
						<p>
							This Action has been archived.{" "}
							<span onClick={handleUnarchive}>UNARCHIVE</span>
						</p>
					</div>
				) : (
					false
				)
			}
		>
			{showClone ? (
				<DuplicateList
					onClose={() => setClone(false)}
					onSubmit={(accounts: any[]) => handleClone(accounts)}
				/>
			) : (
				<div className="show-action">
					<div className="show-action_info">
						<div className="row">
							<AssigneePicker
								label="Owner"
								value={action.assignee_id}
								onChange={(id) => {
									let newValue = {
										...action,
										assignee_id: id,
									};
									setAction(newValue);
									handleUpdate({ assignee_id: id });
									// refetch();
								}}
							/>
							<DeadlinePicker
								label="Due Date"
								action={action}
								onChange={(value) => {
									handleUpdate({ deadline: value });
								}}
								value={action.deadline || ""}
							/>
						</div>

						<Description
							onUpdate={(text) => {
								handleUpdate({ description: text });
								setAction({
									...action,
									description: text,
								});
							}}
							value={action.description}
							label="Description"
						/>

						<TodoList refetch={refetch} id={action.id} />

						<div className="files">
							<label>Files</label>
							<div className="files-content">
								{action.documents && action.documents.length ? (
									action.documents.map((doc: any) => (
										<div
											key={doc.id + doc.url}
											className="files-content_item"
										>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={doc.url}
											>
												{doc.name}
											</a>
											<i
												onClick={() =>
													handleRemoveDocument(doc.id)
												}
												className={`fal ${
													removing === doc.id
														? "fa-spin fa-spinner-third"
														: "fa-trash-alt"
												}`}
											></i>
										</div>
									))
								) : (
									<div className="files-content_empty">
										<p>No files provided</p>
									</div>
								)}
							</div>
						</div>
						<Comments id={action.id} />
					</div>
					<div style={{ flex: 1 }}>
						<Statuses
							handleUpdate={handleUpdate}
							disabled={[]}
							action={action}
							setAction={handleStatuses}
						/>
						<div className="right-column-wrapper">
							<GenerateLink
								url={`${window.location.origin}/output/${action.id}`}
							/>
						</div>
						<div className="right-column-wrapper">
							<CollaboratorPicker
								updateCollabrators={(ids: number[]) =>
									updateCollabrators(ids)
								}
								collaborators={action.collaborators}
							/>
						</div>
						<div className="right-column-wrapper">
							<Button
								disabled={collabFetching || isFetching}
								onClick={() => toggleColabrator(currentUserId)}
								className="follow-button"
								type={"link"}
								icon={isWatching ? "fa-eye-slash" : "fa-eye"}
							>
								{isWatching ? "Unwatch output" : "Watch output"}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default connect((state: any) => {
	return {
		auth: state.auth,
		currentUserId: state.auth.id,
	};
}, {})(ShowAction);
