import React, { useState, useEffect } from "react";
import localforage from "localforage";
import _ from "lodash";
import "./sort.scss";
import { Dropdown } from "semantic-ui-react";

type SortProps = {
	items: any;
	handleChange: (items: any) => void;
};

const Sort = ({ items, handleChange }: SortProps) => {
	const local_key = `actions_sort`;
	const [sort, setSort] = useState({
		direction: "ASC",
		by: "deadline",
	});

	const getLocalFilter = async () => {
		let localSort = (await localforage.getItem(local_key)) as any;
		if (localSort && localSort.direction && localSort.by) {
			setSort({
				...localSort,
			});
		}
	};

	const updateLocalFilter = async () => {
		await localforage.setItem(local_key, sort);
	};

	const filterItems = () => {
		const by = sort.by;
		const direction = sort.direction.toLowerCase();
		let newValue = {};
		Object.entries(items).map(([status, value]) => {
			if (by === "deadline") {
				newValue[status] = _.orderBy(
					value,
					(o) => {
						return new Date(o.deadline).getTime();
					},
					direction
				);
			} else {
				newValue[status] = _.orderBy(value, by, direction);
			}
		});
		handleChange(newValue);
	};

	useEffect(() => {
		filterItems();
		updateLocalFilter().then(() => {});
	}, [sort]);

	useEffect(() => {
		getLocalFilter().then(() => {});
	}, []);

	const ByOptions = [
		{ value: "deadline;DESC", text: "Deadline: newest first" },
		{ value: "deadline;ASC", text: "Deadline: oldest first" },
		{
			value: "assignee.full_name;ASC",
			text: "Assignee: alphabetical order",
		},
		{
			value: "assignee.full_name;DESC",
			text: "Assignee: reversed alphabetical order",
		},
		{ value: "need.name;ASC", text: "Need: alphabetical order" },
		{ value: "need.name;DESC", text: "Need: reversed alphabetical order" },
	];

	return (
		<div className="sort">
			<label>Sort by</label>
			<Dropdown
				style={{ width: 270 }}
				// style={{ minWidth: "max-content" }}
				placeholder="Sort by"
				selection
				value={`${sort.by};${sort.direction}`}
				options={ByOptions.map((item) => ({
					...item,
					key: item.value,
				}))}
				onChange={(event, data) => {
					let value = data.value as string;
					let s = value.split(";");
					let direction = s[1];
					let by = s[0];
					setSort({
						direction,
						by,
					});
				}}
			/>
		</div>
	);
};

export default Sort;
