import React, { useState } from "react";
import Modal from "../../Modal";
import { Input } from "semantic-ui-react";
import Button from "../../Form/Button";
import "./preset-modal.scss";
import api from "../../../api/api";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../reducers";
type PresetModalProps = {
	visible: boolean;
	onClose: () => void;
	isNeed?: boolean;
	onFiltersChange: (value: any) => void;
};

const PresetModal = ({
	visible,
	onClose,
	isNeed,
	onFiltersChange,
}: PresetModalProps) => {
	const selected = useSelector(
		(state: ApplicationState) => state.filters[isNeed ? "needs" : "actions"]
	);
	const [name, setName] = useState("");
	const [fetching, setFetching] = useState(false);
	const [alert, setAlert] = useState({
		message: "",
		type: "error",
	});
	const handleSubmit = async (e) => {
		if (e) e.preventDefault();
		setFetching(true);
		api.upsertFilter("new", name, selected, isNeed ? "need" : "action")
			.then(async (res) => {
				setAlert({
					message: "Your new filter has been saved.",
					type: "success",
				});
				handleClose();
				closeAlert();
			})
			.catch(() => {
				setAlert({
					message: "Something went wrong.",
					type: "error",
				});
				closeAlert();
			});
	};

	const closeAlert = () => {
		setTimeout(() => {
			setAlert({
				message: "",
				type: "success",
			});
		}, 2000);
	};

	const handleClose = () => {
		setFetching(false);
		setName("");
		onClose();
	};

	return (
		<>
			{alert.message && (
				<div className={`preset-alert preset-alert_${alert.type}`}>
					<p>{alert.message}</p>
				</div>
			)}

			<Modal
				className="preset-modal"
				type={"small"}
				title={<h1>Create new filter preset</h1>}
				buttons={
					<div className="buttons">
						<Button type="transparent" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							isLoading={fetching}
							icon="fa-save"
							disabled={!name}
							onClick={handleSubmit}
						>
							Save Preset
						</Button>
					</div>
				}
				visible={visible}
				onClose={handleClose}
			>
				<form onSubmit={handleSubmit}>
					<Input
						onChange={(e, data) => setName(data.value)}
						value={name}
						placeholder="Filter preset name"
					/>
				</form>
			</Modal>
		</>
	);
};

export default PresetModal;
