import React from "react";
import "./loading.scss";
import oval from "../../../images/oval-blue.svg";

const Loading = ({ type }: { type?: string }) => {
	return (
		<div className="loading">
			<div className={`wrapper ${type ? "wrapper-" + type : ""}`}>
				<img src={oval} alt="" />
				<p>Loading, please wait</p>
			</div>
		</div>
	);
};

export default Loading;
