import { createAction, handleActions } from "redux-actions";
import localforage from "localforage";
import { ApiRole } from "../api/admin/users";
import api from "../api/api";

export interface UpdateProfileProps {
	id: number;
	name: string;
	email: string;
	avatar: string;
	roles: [];
	is_admin: boolean;
}

export interface AuthState {
	isFetching: boolean;
	isLoggedIn: boolean;
	authError: boolean | {};
	accessToken: string;
	tokenId: string;
	expiresAt: number;
	name: string;
	email: string;
	avatar: string;
	id: number;
	roles: ApiRole[];
	is_admin: boolean;
}

export interface AuthLoginProps {
	access_token: string;
	api_key: string;
	user: {
		id: number;
		full_name: string;
		email_address: string;
		avatar: string;
	};
}

// export const login = createAction('AUTH_LOGIN');
export const updateProfile = createAction("UPDATE_PROFILE");
export const updateRoles = createAction("UPDATE_ROLES");
export const updateFetchState = createAction("UPDATE_FETCH_STATE");
export const updateAuthError = createAction("UPDATE_AUTH_ERROR");
export const logout = createAction("AUTH_LOGOUT");

export const login = async (authProps: any) => {
	let user = (await api.authLogin(authProps.id_token)) as any;

	localStorage.setItem("a_token", `${user.access_token}:${user.api_key}`);
	return {
		payload: user,
		type: login.toString(),
	};
};

export const isAdmin = (roles: ApiRole[]) => {
	if (!roles || !roles.length) {
		return false;
	}
	let admin = roles.find((role: ApiRole) => role.name === "admin");
	return admin && admin.name;
};

const authReducer = handleActions(
	{
		[login.toString()]: (state, action) => {
			const response: AuthLoginProps = action.payload;
			let user = response.user as any;
			state.isLoggedIn = user.id || false;
			state.id = user.id;
			state.name = user.full_name;
			state.email = user.email_address;
			state.avatar = user.avatar;
			state.is_admin = user.is_admin;

			if (response.access_token) {
				state.accessToken = response.access_token;
			}
			return {
				id: user.id,
				email: user.email_address,
				avatar: user.avatar,
				is_admin: user.is_admin,
				accessToken: user.access_token,
				name: user.full_name,
				roles: user.roles,
			} as AuthState;
		},
		UPDATE_PROFILE: (state, action) => {
			const profile: UpdateProfileProps = action.payload;
			state.id = profile.id;
			state.name = profile.name;
			state.email = profile.email;
			state.avatar = profile.avatar;
			state.roles = profile.roles;
			state.is_admin = profile.is_admin;

			localforage.setItem("authProfile", action.payload);
		},
		UPDATE_ROLES: (state, action) => {
			state.roles = action.payload;
		},
		UPDATE_FETCH_STATE: (state, action) => {
			state.isFetching = action.payload;
		},
		UPDATE_AUTH_ERROR: (state, action) => {
			state.authError = action.payload;
		},
		[logout.toString()]: (state) => {
			localStorage.setItem("a_token", "");

			return {} as AuthState;
		},
	},
	{}
);

export default authReducer;
