import React, { Component, FormEvent } from "react";
import { withRouter } from "react-router";
import { Segment, Header, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { history } from "../../../Routes";
import { fetchChapters, updateChapter } from "../../../reducers/chapters";
import SegmentActions from "../../../components/SegmentActions/SegmentActions";
import { ApiChapterObject } from "../../../api/admin/chapters";
import api, { ApiResponse, ApiList } from "../../../api/api";
import Layout from "../../../components/Layout";
import { ApplicationState } from "../../../reducers";

interface StateProps {
	chapter?: ApiChapterObject;
	isFetching: boolean;
	errors: any;
}

interface OwnProps {
	match: any;
}

interface DispatchProps {
	updateChapter: (request: ApiResponse<ApiChapterObject>) => void;
	fetchChapters: (request: ApiResponse<ApiList<ApiChapterObject>>) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class ChapterEdit extends Component<Props, {}> {
	async componentDidMount() {
		if (this.props.match.params.id) {
			// const id = parseInt(this.props.match.params.id);
			this.props.fetchChapters(api.admin.listChapters());
			// const result = await api.admin.showChapter(id);
		}
	}

	onSuccess = () => {
		toast({
			type: "success",
			description: "Chapter saved succesfully",
			time: 2500,
		} as any);
		history.push("/chapters");
	};

	onError = () => {
		toast({
			type: "error",
			title: "Something went wrong",
			description: "Please check all required fields",
			time: 0,
		} as any);
	};

	onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);

		const data: ApiChapterObject = {
			id: -1,
			name: formData.get("name") as string,
		};

		if (this.props.match.params.id) {
			this.props.updateChapter(
				api.admin.updateChapter(
					parseInt(this.props.match.params.id),
					data
				)
			);
		} else {
			this.props.updateChapter(api.admin.storeChapter(data));
		}
	};

	renderForm() {
		if (this.props.isFetching) {
			return null;
		}

		return (
			<Form onSubmit={this.onSubmit}>
				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.last_name !== undefined
					}
					name="name"
					label="Name"
					required
					defaultValue={
						this.props.chapter ? this.props.chapter.name : ""
					}
				/>
				<SegmentActions>
					<Button
						basic
						content="Cancel"
						onClick={() => history.push("/chapters")}
						type="button"
					/>
					<Button primary content="Save" type="submit" />
				</SegmentActions>
			</Form>
		);
	}

	render() {
		return (
			<Layout>
				<Segment>
					<Header as="h1">
						{this.props.chapter ? "Edit" : "New"} chapter
					</Header>
					{this.renderForm()}
				</Segment>
			</Layout>
		);
	}
}

export default withRouter(
	connect(
		(state: ApplicationState, ownProps: OwnProps) => ({
			chapter: ownProps.match.params.id
				? state.chapters.chapters[parseInt(ownProps.match.params.id)]
				: undefined,
			isFetching: state.chapters.isFetching,
			errors: {},
		}),
		{ updateChapter, fetchChapters }
	)(ChapterEdit)
);
