import { get  } from '../utils';
import { ApiResponse } from '../api';

export interface ApiDashboardStats {
    categories: string[],
    logins: number[],
}

export default {
    logins: (period) => {
        const query = `?periodFrom=${period.from || ''}&periodTill=${period.till || ''}`;
        return get(`admin/dashboard/logins${query}`) as ApiResponse<ApiDashboardStats>;
    }
};
