import { generateRest, post } from "../utils";

export interface ApiLabelsObject {
	value: string;
	text: string;
	id?: number;
	name: string;
	slug: string;
	needs?: number;
	actions?: number;
	created_at?: string;
	color?: {
		text?: string;
		background?: string;
	};
}

const rest = generateRest<ApiLabelsObject>("admin/labels");
export default {
	...rest,
	merge: (data: { label: number; labels: number[] }) => {
		return post(`admin/labels/merge`, data) as any;
	},
};
