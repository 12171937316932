import React, { useEffect, useState, useCallback } from "react";
import "./tags.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import PresetModal from "../PresetModal";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter, setFilter } from "../../../reducers/filters";
import { ApplicationState } from "../../../reducers";
import useIsNeed from "../../../lib/useIsNeed";
import api from "../../../api/api";
import { REDIRECT_URL } from "../../../Routes";

type Tag = {
	name: string;
	type: string;
	id: number;
};

interface TagsProps {
	colors: {
		[key: string]: string;
	};
	type?: string;
	value?: any;
	isNeed?: any;
	filters?: any;
	handleRemove?: (value: any) => void;
	onFiltersChange: (value: any) => void;
}

const Tags = ({
	colors,
	value,
	type,
	onFiltersChange,
	handleRemove,
}: TagsProps) => {
	const isNeed = useIsNeed();
	const filter = useSelector(
		(state: ApplicationState) => state.filters[isNeed ? "needs" : "actions"]
	);
	// const [filter, _setFilter] = useState(_filter || value || ({} as any));

	const history = useHistory();
	const dispatch = useDispatch();
	const [tags, setTags] = useState([] as Tag[]);
	const [visible, setVisible] = useState(false);

	// useEffect(() => {
	// 	_setFilter(value);
	// }, [value]);

	const handleFetch = useCallback(() => {
		api.getFilterTags(filter).then((res: any) => {
			if (res.data) {
				setTags(res.data);
			}
		});
	}, [filter]);

	useEffect(() => {
		handleFetch();
	}, [filter]);

	const handleClearAll = () => {
		if (!isNeed) {
			history.push("/output");
		} else {
			history.push(REDIRECT_URL);
		}
		dispatch(clearFilter({ isNeed }));
	};

	const handleRemoveFilter = (type: string, id: number) => {
		if (handleRemove) {
			handleRemove({
				...filter,
				[type]: _.xor(filter[type], [id]),
			});
		} else {
			dispatch(
				setFilter({
					isNeed,
					key: type,
					value: filter[type].filter((i) => i !== id),
				})
			);
		}
	};

	return (
		<>
			{type !== "transparent" && (
				<PresetModal
					onFiltersChange={onFiltersChange}
					isNeed={isNeed}
					onClose={() => setVisible(false)}
					visible={visible}
				/>
			)}
			<div
				className={`
                filter-tags 
                ${tags.length ? "filter-tags_visible" : ""}
                ${type ? "filter-tags_" + type : ""}
                js-calculate
            `}
			>
				<div className="tags">
					{tags.map((tag) => (
						<div
							style={{ backgroundColor: colors[tag.type] }}
							onClick={() => handleRemoveFilter(tag.type, tag.id)}
							key={tag.id + tag.name}
							className="tags_item"
						>
							<span>{tag.name}</span>
							<i className="fal fa-times"></i>
						</div>
					))}
				</div>
				{type !== "transparent" && (
					<div className="actions">
						<div
							onClick={() => setVisible(true)}
							className="filter-tags_button"
						>
							<span>
								<i className="fal fa-save"></i>
								Save as Preset
							</span>
						</div>
						<div
							onClick={handleClearAll}
							className="filter-tags_button"
						>
							<span>
								<i className="fal fa-times"></i>
								Clear all
							</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Tags;
