import React from "react";
import "./timeframes-view.scss";
import Layout from "../../../components/Layout";
import useGet from "../../../lib/useGet";
import { Segment, Header, Table } from "semantic-ui-react";
import Button from "../../../components/Form/Button";
import ApiHideSwitch from "../../../components/ApiHideSwitch";
import api from "../../../api/api";

const TimeframesView = () => {
	const [data, loading, , refetch] = useGet("admin/timeframes");
	let items = data?.data || [];

	const handleDelete = (id: number) => {
		if (
			!window.confirm(
				"Are you sure? This timeframe will be lost forever."
			)
		) {
			return;
		}
		api.admin.deleteTimeframe(id).finally(() => refetch());
	};

	return (
		<Layout loading={loading}>
			<Segment>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Header as="h1">Timeframes</Header>
					<Button to="/timeframes/create">Create timeframe</Button>
				</div>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell collapsing>Serial</Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								Actions
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items.map((o: any) => (
							<Table.Row key={o.id}>
								<Table.Cell collapsing>
									<p>{o.serial}</p>
								</Table.Cell>
								<Table.Cell>
									<p>{o.name}</p>
								</Table.Cell>
								<Table.Cell collapsing textAlign="right">
									<div style={{ display: "flex" }}>
										<ApiHideSwitch
											url={`admin/timeframes/${o.id}`}
											value={o.hide}
										/>
										<Button
											type="gray"
											onClick={() => handleDelete(o.id)}
										>
											Delete
										</Button>
										<Button to={`/timeframes/${o.id}/edit`}>
											Edit
										</Button>
									</div>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Segment>
		</Layout>
	);
};

export default TimeframesView;
