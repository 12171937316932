import React, { useState, useEffect } from "react";
import "./needsview.scss";
import api from "../../api/api";
import Layout from "../../components/Layout";
import { hasFilter } from "../../api/filters";
import Button from "../../components/Form/Button";
import NeedRow from "../../components/Needs/NeedRow";
import NeedDetail from "./NeedDetail";
import CreateNeed from "./CreateNeed";
import EmptyState from "../../components/EmptyState";
import NeedsSort from "../../components/Needs/NeedsSort";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers";

const NeedsView = (props: any) => {
	const filter = useSelector(
		(state: ApplicationState) => state.filters.needs
	);
	const search = props.match.params.search;
	const [loading, setLoading] = useState(true);
	const [empty, setEmpty] = useState(false);
	const [needs, setNeeds] = useState([] as any);
	const [need, setNeed] = useState({
		id: null,
		name: "",
	});

	useEffect(() => {
		setLoading(true);
		fetchNeeds().then(() => {
			setLoading(false);
		});
	}, [filter]);

	useEffect(() => {
		setLoading(true);
		fetchNeeds().then(() => {
			setLoading(false);
		});
	}, []);

	const fetchNeeds = async () => {
		if (!hasFilter(filter)) {
			if (search) {
				await appendItem();
			} else {
				setEmpty(true);
			}

			return;
		} else {
			setEmpty(false);
		}
		let res: any = await api.listNeeds(
			filter.query,
			["squads", "chapters", "labels", "pages", "keyResults", "products", "accounts"],
			filter
		);

		if (search && res.data) {
			let prefilled = res.data.find((n: any) => n.id === Number(search));
			if (prefilled && prefilled.id) {
				setNeed(prefilled);
			} else {
				await appendItem();
			}
		}

		if (res) {
			setNeeds(res.data);
		}
		setLoading(false);
	};

	const appendItem = async () => {
		let item = await api.showNeed(search);
		if (item.id) {
			setNeed(item as any);
			setNeeds([item, ...needs]);
			setEmpty(false);
		}
	};

	let wrapperStyle = {};
	if (need.id) {
		wrapperStyle = {
			marginRight: 680,
		};
	}
	return (
		<Layout loading={loading} hasFilter isNeed>
			<div className="needs-view">
				<NeedDetail
					disabled
					onUpdate={(need: any) =>
						setNeeds(
							needs.map((item: any) => {
								if (item.id === need.id) {
									return need;
								} else {
									return item;
								}
							})
						)
					}
					onClose={() => {
						setNeed({ id: null, name: "" });
						fetchNeeds();
					}}
					need={need}
				/>
				<div
					className={`needs-view_wrapper ${
						need && need.id ? "needs-view_wrapper_active" : ""
					}`}
					style={wrapperStyle}
				>
					<div className="needs-view_items">
						<div className="topbar">
							<NeedsSort
								items={needs}
								handleChange={(items) => setNeeds(items)}
							/>
							<CreateNeed
								button={
									<Button icon="fa-plus">
										Create new outcome
									</Button>
								}
							/>
						</div>
						{!empty && needs.length ? (
							needs.map((item: any) => (
								<NeedRow
									assignee={
										item.assignee && item.assignee
											? item.assignee
											: ""
									}
									deleteNeed={() => {
										setNeeds(
											needs.filter(
												(i: any) => i.id !== item.id
											)
										);
									}}
									onClick={() => {
										setNeed(item);
									}}
									isArchived={item.archived_at}
									action_count={item.action_count}
									active={item.id == need.id}
									key={`need_${item.id}`}
									name={item.name}
									badge={item.scope}
									id={item.id}
									pillars={item.pillars}
									labels={item.labels ? item.labels : []}
									timeframe={item.timeframe || undefined}
									progress={item.progress_calc}
								/>
							))
						) : (
							<EmptyState
								noResults={hasFilter(filter)}
								isLoading={loading}
							/>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default NeedsView;
