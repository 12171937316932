import React, { Component } from 'react';
import styles from './TogglePicker.module.scss';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import classNames from 'classnames';
import Avatar from '../Avatar';

interface Props {
  icon?: SemanticICONS;
  label: string;
  displayValue: string;
  image?: string;
  onOpen?: () => void;
  onClose?: () => void;
  small: boolean;
  slim: boolean;
  show?: boolean;
}

interface State {
  isEditing: boolean;
}

export default class TogglePicker extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    icon: 'user outline',
  }
  
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  showEditor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isEditing: true,
    }, this.props.onOpen);
  }

  hideEditor = () => {
    this.setState({
      isEditing: false,
    }, this.props.onClose);
  }

  renderEditor() {
    return (
      <div
        className={classNames({
          [styles.editor]: !this.props.slim,
          [styles.editorSlim]: this.props.slim,
        })}
      >
        {this.props.children}
      </div>
    );
  }

  renderView() {
    let icon = (
      <div className={classNames({
        [styles.leftPlaceholder]: !this.props.slim,
        [styles.leftPlaceholderSlim]: this.props.slim,
      })}>
        <Icon name={this.props.icon} className={styles.icon} />
      </div>
    );

    if (this.props.image) {
      icon = (
        <div>
          <Avatar 
            type="full"
            src={this.props.image}
          />
       
        </div>
      )
    }

    return (
      <button
        className={classNames({
          [styles.view]: true,
          [styles.show]: this.props.show,
          [styles.viewSmall]: this.props.small,
        })}
        onClick={this.showEditor}
      >
        {!this.props.show && <div className={classNames({
          [styles.left]: !this.props.slim,
          [styles.leftSlim]: this.props.slim,
        })}>
          {icon}
        </div>}
        <div
          className={classNames({
            [styles.right]: !this.props.slim,
            [styles.rightSlim]: this.props.slim,
          })}
        >
          {!this.props.slim ? <div className={styles.rightLabel}>{this.props.label}</div> : null}
          {this.props.displayValue}
        </div>
      </button>
    );
  }

  render() {
    if (this.state.isEditing) {
      return this.renderEditor();
    }
    return this.renderView();
  }
}
