import React from "react";
import Layout from "../../../components/Layout";
import useGet from "../../../lib/useGet";
import { Segment, Header, Table } from "semantic-ui-react";
import Button from "../../../components/Form/Button";
import ApiHideSwitch from "../../../components/ApiHideSwitch";
import api from "../../../api/api";

const PagesView = () => {
	const [data, loading, , refetch] = useGet("admin/pages");
	let items = data?.data || [];

	const handleDelete = (id: number) => {
		if (!window.confirm("Are you sure? This page will be lost forever.")) {
			return;
		}
		api.admin.pages.delete(id).finally(() => refetch());
	};

	return (
		<Layout loading={loading}>
			<Segment>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Header as="h1">Pages</Header>
					<Button to="/pages/create">Create page</Button>
				</div>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								Actions
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items.map((o: any) => (
							<Table.Row key={o.id}>
								<Table.Cell>
									<p>{o.name}</p>
								</Table.Cell>
								<Table.Cell collapsing textAlign="right">
									<div style={{ display: "flex" }}>
										<ApiHideSwitch
											url={`admin/pages/${o.id}`}
											value={o.hide}
										/>

										<Button
											type="gray"
											onClick={() => handleDelete(o.id)}
										>
											Delete
										</Button>
										<Button to={`/pages/${o.id}/edit`}>
											Edit
										</Button>
									</div>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Segment>
		</Layout>
	);
};

export default PagesView;
