import { ApiResponse } from "./api";
import { get, post, patch, del } from "./utils";

type ApiTimeframeObject = {
	id: number;
	name: string;
	serial: string;
};

export default {
	list: (isCreate?: boolean) => {
		let url = `timeframes`;
		if (isCreate) url += "?create=1";
		return get(url) as ApiResponse<ApiTimeframeObject[]>;
	},
	show: (id: Number) => {
		return get(`timeframes/${id}`) as ApiResponse<ApiTimeframeObject>;
	},
	create: (name: string, serial: string) => {
		return post(`admin/timeframes`, { name, serial }) as ApiResponse<
			ApiTimeframeObject
		>;
	},
	update: (id: number, name: string, serial: string) => {
		return patch(`admin/timeframes/${id}`, { name, serial }) as ApiResponse<
			ApiTimeframeObject
		>;
	},
	delete: (id: number) => {
		return del(`admin/timeframes/${id}`) as ApiResponse<any>;
	},
};
