import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./kanbancard.scss";
import DeadlinePicker from "../../Form/DeadlinePicker";
import AssigneePicker from "../../Form/AssigneePicker";
import api from "../../../api/api";
import socket from "../../../lib/socket";
import { useThrottle } from "use-throttle";
import ActionDropdown from "../../ActionDropdown";
import moment from "moment";

type KanbanCardProps = {
	content: string;
	onDragStart?: () => void;
	onDragEnd?: () => void;
	color: string;
	assignee_id: number;
	deadline?: string;
	onClick: () => void;
	disabled?: boolean;
	pending?: boolean;
	action: any;
	onChange: (action: any) => void;
	refetch?: () => void;
};

const KanbanCard = ({
	content,
	color,
	onClick,
	pending,
	action,
	refetch,
}: KanbanCardProps) => {
	// const addLabelToFilter = useAddLabelToFilter(false);
	// const dispatch = useDispatch();
	const [fetching, setFetching] = useState("");
	const [first, setFirst] = useState(true);
	const [copyAction, setCopyAction] = useState(action);
	const [assignee, setAssignee] = useState(action.assignee_id);
	const assignee_id = useThrottle(assignee, 1300);
	const history = useHistory();
	const checklist = action.checklist;
	const is_done_count = checklist.reduce(
		(current, item) => current + item.is_done,
		0
	);

	useEffect(() => {
		if (!first) {
			socket.emit("action assignee changed", {
				actionId: action.id,
				assigneeId: assignee_id || undefined,
			});
		} else {
			setFirst(false);
		}
	}, [assignee_id]);

	useEffect(() => {
		setCopyAction(action);
	}, [action]);

	const renderFooter = () => {
		return (
			<div className="kanban-card_actions">
				<DeadlinePicker
					type="kanban"
					action={copyAction}
					onChange={(value) => {
						setCopyAction({
							...copyAction,
							deadline: value,
						});
						api.updateAction(action.id, { deadline: value }).then(
							() => {
								socket.emit("action deadline changed", {
									actionId: action.id,
									deadline: value,
								});
							}
						);
					}}
					value={copyAction.deadline}
				/>
				<div className="kanban-card_actions-icon">
					{/* <i className="fal fa-ellipsis-v"></i> */}
					<ActionDropdown
						isArchived={action.archived_at}
						fetching={fetching}
						handleDuplicate={() => {
							if (
								!window.confirm(
									"Are you sure you want to duplicate this action?"
								)
							) {
								return;
							}
							setFetching("duplicate");
							api.duplicateAction(action.id, [])
								.then(() => setFetching(""))
								.then(() => {
									if (refetch) refetch();
								});
						}}
						handleDelete={() => {
							if (
								!window.confirm(
									"Are you sure you want to delete this action?"
								)
							) {
								return;
							}
							setFetching("delete");
							api.destroyAction(action.id)
								.then(() => setFetching(""))
								.then(() => {
									if (refetch) refetch();

									socket.emit("action deleted", action.id);
								})
								.catch(() => setFetching(""));
						}}
						handleArchive={() => {
							if (
								!window.confirm(
									action.archived_at
										? "Are you sure you want to unarchive this action?"
										: "Are you sure you want to archive this action?"
								)
							) {
								return;
							}
							setFetching("archive");
							api.updateAction(action.id, {
								archived_at: action.archived_at
									? ""
									: moment().format("YYYY-MM-DD HH:mm:ss"),
							})
								.then(() => setFetching(""))
								.then(() => {
									if (refetch) refetch();
								})
								.catch(() => setFetching(""));
						}}
					/>
				</div>
			</div>
		);
	};

	const handleAssigneePicker = async (id) => {
		setCopyAction({ ...copyAction, assignee_id: id });
		await api.updateAction(action.id, { assignee_id: id });
		setAssignee(id);
		if (refetch) refetch();
	};
	return (
		<div
			onClick={onClick}
			className={`kanban-card`}
			style={{
				borderLeftColor: color,
			}}
		>
			{pending && (
				<div className="kanban-card_pending">
					<i className="fal fa-hourglass-start"></i>
				</div>
			)}
			<div className="kanban-card-row kanban-card-spacing">
				<p className="kanban-card-title">{action.subject}</p>
			</div>
			{copyAction?.need && (
				<div
					onClick={(e) => {
						e.stopPropagation();
						history.push(`/outcome/${copyAction?.need.id}`);
					}}
					className="kanban-card_need kanban-card-row kanban-card-spacing"
				>
					<span>
						<i className="fal fa-link"></i>
						{copyAction?.need.name}
					</span>
				</div>
			)}

			<div className="kanban-card-row kanban-card-spacing">
				<div className="icon-group">
					<div className="icon">
						<i className="far fa-comment"></i>
						<span>{action.comment_count}</span>
					</div>
				</div>
				<div className="icon-group">
					<div
						className={`icon ${
							is_done_count === checklist.length
								? "icon-success"
								: ""
						}`}
					>
						<i className="far fa-check-square "></i>
						<span>
							{is_done_count} / {checklist.length}
						</span>
					</div>
				</div>
			</div>
			{/* <div className="kanban-card-hover"> */}
				<div className="kanban-card-row kanban-card-between">
					<div className="kanban-card_user">
						<AssigneePicker
							type="kanban"
							onChange={handleAssigneePicker}
							value={copyAction.assignee_id}
						/>
					</div>
				</div>
				<div className="kanban-card-row">
					{action?.need?.scope && (
						<div className="team-badge">{action.need.scope}</div>
					)}
					{action?.chapters && action.pages.map(o => (
						<div className="team-badge">{o.name}</div>
					))}
					{action?.chapters && action.chapters.map(o => (
						<div className="team-badge">{o.name}</div>
					))}
					{action?.chapters && action.chapters.map(o => (
						<div className="team-badge">{o.name}</div>
					))}
				</div>
			{/* </div> */}

			{renderFooter()}
		</div>
	);
};

export default KanbanCard;
