import qs from "qs";
import { ApiResponse } from "./api";
import { ApiSquadObject } from "./admin/squads";
import { get } from "./utils";

export default {
  list: (q = '', qWith: string[] = ['users'], props = {}) => {
    const query = qs.stringify({
      with: qWith.join(','),
      q,
      ...props,
    });
    return get(`squads?${query}`) as ApiResponse<ApiSquadObject[]>;
  },
  show: (id: number, qWith: string[] = ['users'], props = {}) => {
    const query = qs.stringify({
      with: qWith.join(','),
      ...props,
    });
    return get(`squads/${id}?${query}`) as ApiResponse<ApiSquadObject>;
  },
}
