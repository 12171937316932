import { ApiActionObject } from "../api/actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import { FiltersState } from "../reducers/filters";
import { useState, useEffect, useCallback, useRef } from "react";
import api from "../api/api";

export default function useCheckFilter() {
	const [, _setFilter] = useState({} as FiltersState);
	const reduxFilter: any = useSelector(
		(state: ApplicationState) => state.filters.actions
	);
	const filterRef = useRef({});
	const setFilter = (data: any) => {
		filterRef.current = data;
		_setFilter(data);
	};
	useEffect(() => {
		setFilter(reduxFilter);
		return () => {
			setFilter({} as FiltersState);
		};
	}, [reduxFilter]);

	const checkIfInFilter = useCallback(
		async (action: ApiActionObject) => {
			let result = await api.checkActionFilter(
				action.id,
				filterRef.current
			);
			return result;
		},
		[filterRef]
	);

	return [checkIfInFilter] as [(action: ApiActionObject) => Promise<boolean>];
}
