import React, { useState, useEffect } from "react";
import "./actionsview.scss";
import Layout from "../../components/Layout";
import Kanban, { STATUS_NAMES } from "../../components/Kanban";
import api from "../../api/api";
import { hasFilter } from "../../api/filters";
import CreateAction from "./CreateAction";
import ShowAction from "./ShowAction";
import EmptyState from "../../components/EmptyState";
import { connect, useSelector } from "react-redux";
import Sort from "../../components/Kanban/Sort";
import { ApplicationState } from "../../reducers";
import useCheckFilter from "../../lib/useCheckFilter";

const ActionsView = ({ match, history }) => {
	const [checkIfInFilter] = useCheckFilter();
	const routeId = match.params.id;
	const filter = useSelector(
		(state: ApplicationState) => state.filters.actions
	);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [actions, _setActions] = useState({
		Backlog: [],
		"To-do": [],
		Doing: [],
		Done: [],
	});
	const [selected, setSelected] = useState({ id: undefined });
	const [visible, setVisible] = useState(false);
	const [layout, setLayout] = useState("landscape");
	const [empty, setEmpty] = useState(false);
	const actionsRef = React.useRef(actions);
	const setActions = (data: any) => {
		actionsRef.current = data;
		_setActions(data);
		checkIfEmpty(data);
	};

	//Get actions from server
	const fetchActions = async (filterCheck = true) => {
		if (!hasFilter(filter) && filterCheck) {
			setEmpty(true);
			return;
		}
		return api
			.fetchActions(
				filter.query,
				["need", "squads", "chapters", "pages", "documents"],
				filter
			)
			.then((res: any) => {
				setActions(res);
				checkIfEmpty(res);
				return res;
			});
	};

	const appendItem = async (id) => {
		try {
			let res = await api.showAction(id, [
				"need",
				"squads",
				"chapters",
				"pages",
				"documents",
			]);
			return res;
		} catch (error) {
			return { id: null };
		}
	};

	const checkIfEmpty = (res?: any) => {
		let data = Object.values(actions);
		if (res) {
			data = Object.values(res);
		}
		let items = 0;
		data.map((item: any) => {
			items += item.length;
		});

		setEmpty(items ? false : true);
	};

	const getActionById = (id: number) => {
		let all = [].concat.apply([], Object.values(actionsRef.current)) || [];
		let action: any = all.find((item: any) => item.id === id);
		return action;
	};

	useEffect(() => {
		setFetching(true);
		fetchActions().finally(() => setFetching(false));
	}, [filter]);

	//Fetch action from id
	useEffect(() => {
		if (routeId) {
			appendItem(routeId).then((item: any) => {
				if (item.id && item.status_name) {
					setSelected(item as any);
				}
			});
		} else {
			history.push("/output");
		}
	}, [routeId]);

	//Fetch first time
	useEffect(() => {
		setLoading(true);
		fetchActions().then(async (res) => {
			setLoading(false);
		});
	}, []);

	const updateAction = (action) => {
		const data = actionsRef.current;
		if (action.status_name && data[action.status_name]) {
			setActions({
				...data,
				[action.status_name]: data[action.status_name].map((i) => {
					if (i.id === action.id) {
						return action;
					}
					return i;
				}),
			});
		}
	};

	const handleAssigneeChange = ({ detail }: any) => {
		const actions = actionsRef.current;
		let obj = {};
		Object.entries(actions).map(([key, value]) => {
			obj[key] = value.map((i: any) => {
				if (i.id === detail.actionId) {
					return {
						...i,
						assignee_id: detail.assigneeId,
					};
				}
				return i;
			});
		});
		setActions(obj);
	};

	const handleDeleteChange = ({ detail }: any) => {
		const data = actionsRef.current;
		let id = detail.id;
		let action = getActionById(id);
		if (action) {
			let newActions = {
				...data,
				[action.status_name]: data[action.status_name].filter(
					(a: any) => a.id !== id
				),
			};
			setActions({
				...newActions,
			});
			checkIfEmpty({ ...newActions });
		}
	};

	const handleDeadlineChange = ({ detail }: any) => {
		let action = getActionById(detail.actionId);
		if (action) {
			action.deadline = detail.deadline;
			updateAction(action);
		}
	};

	const handleActionAdded = ({ detail }: any) => {
		const actions = actionsRef.current;
		setActions({
			...actions,
			[detail.status_name]: [...actions[detail.status_name], detail],
		});
	};

	useEffect(() => {
		window.addEventListener("action added", async ({ detail }: any) => {
			if (await checkIfInFilter(detail)) {
				handleActionAdded({ detail });
			}
		});
		window.addEventListener("action status change", ({ detail }: any) => {
			updateStatus(detail.id, detail.status);
		});
		window.addEventListener(
			"action assignee changed",
			handleAssigneeChange
		);
		window.addEventListener("action changed", ({ detail }: any) =>
			updateAction(detail)
		);
		window.addEventListener("action deleted", handleDeleteChange);
		window.addEventListener(
			"action deadline changed",
			handleDeadlineChange
		);
		return () => {
			window.removeEventListener(
				"action added",
				async ({ detail }: any) => {
					if (await checkIfInFilter(detail)) {
						handleActionAdded({ detail });
					}
				}
			);
			window.removeEventListener(
				"action status change",
				({ detail }: any) => {
					updateStatus(detail.id, detail.status);
				}
			);
			window.removeEventListener(
				"action assignee changed",
				handleAssigneeChange
			);
			window.removeEventListener("action changed", ({ detail }: any) =>
				updateAction(detail)
			);
			window.removeEventListener("action deleted", handleDeleteChange);
			window.removeEventListener(
				"action deadline changed",
				handleDeadlineChange
			);
		};
	}, []);

	const updateStatus = (id: number, status: number) => {
		const data = actionsRef.current;
		let action = getActionById(id);
		if (action) {
			setActions({
				...data,
				[action.status_name]: data[action.status_name].filter(
					(i: any) => i.id !== action.id
				),
				[STATUS_NAMES[status]]: [
					...data[STATUS_NAMES[status]],
					{
						...action,
						status: status,
						status_name: STATUS_NAMES[status],
					},
				],
			});
		}
	};

	const handleClick = (item) => {
		setSelected(item);
	};

	const handleUpdateItem = (items, item) => {
		setActions({
			...items,
			[item.status_name]: items[item.status_name].map((i) => {
				if (i.id === item.id) {
					return item;
				}
				return i;
			}),
		});
	};

	return (
		<Layout fetching={fetching} hasFilter>
			<CreateAction
				visible={visible}
				refetch={fetchActions}
				onClose={() => {
					setVisible(false);
				}}
			/>
			<ShowAction
				updateAction={updateAction}
				visible={selected && selected.id}
				refetch={fetchActions}
				value={selected}
				onClose={() => setSelected({ id: undefined })}
			/>

			<div className="topbar js-calculate">
				<div className="topbar-sort">
					<div
						onClick={() =>
							setLayout(
								layout === "landscape" ? "list" : "landscape"
							)
						}
						className="layout-icon"
					>
						<i
							className={`fal fa-${
								layout === "landscape" ? "list" : "th"
							}`}
						></i>
					</div>
					<Sort
						handleChange={(o: any) => {
							setActions(o);
						}}
						items={actions}
					/>
				</div>
				{/* 
				<Button onClick={() => setVisible(true)} icon="fa-plus">
					Create New Action
				</Button> */}
			</div>
			{empty || loading ? (
				<div className="actions-view">
					<EmptyState
						noResults={hasFilter(filter)}
						isLoading={fetching || loading}
					/>
				</div>
			) : (
				<Kanban
					layout={layout}
					items={actions}
					onClick={handleClick}
					refetch={fetchActions}
					updateItem={handleUpdateItem}
					onChange={(items: any) => {
						setActions(items);
					}}
				/>
			)}
		</Layout>
	);
};

export default connect((state: any) => ({
	currentUserId: state.auth.id,
}))(ActionsView);
