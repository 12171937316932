import qs from "qs";
import config from "../config.json";
import moment from "moment";
import { ApiResponse, ApiList, ApiResponseObject, HttpTypes } from "./api";

const baseUrl = config.apiBaseUrl;

/**
 * Get a Bearer token from Google OAuth
 *
 * @returns {string} A Bearer token.
 */
export const getToken = () => {
	let token = localStorage.getItem("a_token");

	return token || "";
};

/**
 * Execute a API request using `fetch`
 *
 * @export
 * @param {string} path Path to request, api url is prepended.
 * @param {HttpTypes} [method='GET'] Request method
 * @param {RequestInit} [config={}] `fetch` configuration
 * @param {Headers} [headers=new Headers()] Headers that act as a base, defaults to an empty Header object.
 * @returns {(Promise<T & ApiResponseObject>)}
 */
export async function request<T>(
	path: string,
	method: HttpTypes = "GET",
	config: RequestInit = {},
	headers: Headers = new Headers()
): Promise<T & ApiResponseObject> {
	const url = `${baseUrl}/${path}`;
	const abortController = new AbortController();
	let token = getToken();

	headers.append("Authorization", `${token}`);
	headers.append("Access-Control-Allow-Origin", "*");
	if (headers.get("Content-Type") === null) {
		headers.append("Content-Type", "application/json");
	}

	const init: RequestInit = Object.assign(
		{
			method,
			headers,
			signal: abortController.signal,
		},
		config
	);

	const request = new Request(url);

	const response = await fetch(request, init);
	
	const json = await response.json();
	
	if (!response.ok) {
		if (json.code === 401) {
			localStorage.setItem("a_token", "");
			// window.location.href = ''
			if (window.location.pathname !== "/") {
				window.location.href = window.location.origin;
			}
		}
		throw json.errors;
	} else if (!response) {
		throw new Error(
			JSON.stringify({
				errors: {
					unknown: "Unknown error has occured",
				},
			})
		);
	}
	return json;
}

function formatDates(obj: any) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const element = obj[key];
			if (element instanceof Date) {
				obj[key] = moment(element).format("YYYY-MM-DD HH:mm:ss");
			}
			if (element instanceof Object) {
				obj[key] = formatDates(obj[key]);
			}
		}
	}

	return obj;
}

function submitData<T>(method: HttpTypes, path: string, data?: any) {
	const serverReady = formatDates(Object.assign({}, data));

	return request<T>(path, method, {
		body: JSON.stringify(serverReady),
	});
}

export function get<T>(path: string) {
	return request<T>(path);
}

export function del<T>(path: string) {
	return request<T>(path, "DELETE");
}

export function post<T>(path: string, data?: any) {
	return submitData<T>("POST", path, data);
}

export function patch<T>(path: string, data?: any) {
	return submitData<T>("PATCH", path, data);
}

export function put<T>(path: string, data?: any) {
	return submitData<T>("PUT", path, data);
}

export function upload<T>(
	path: string,
	key: string,
	file: File,
	data = new FormData()
) {
	data.append(key, file);

	const headers = new Headers();
	headers.append("Content-Type", "multipart/form-data");

	request<T>(
		path,
		"POST",
		{
			body: data,
		},
		headers
	);
}

interface RestRequets<T> {
	destroy: (id: number) => ApiResponse<{}>;
	list: (q?: string, qWith?: string[], props?: {}) => ApiResponse<ApiList<T>>;
	show: (id: number, qWith?: string[], props?: {}) => ApiResponse<T>;
	store: (data: Partial<T>) => ApiResponse<T>;
	update: (id: number, data: Partial<T>) => ApiResponse<T>;
	put: (id: number, data: Partial<T>) => ApiResponse<T>;
}

/**
 * Generates 5 basic API requests: destroy, list, show, store, update and put.
 *
 * @export
 * @template T API interface
 * @param {string} name Name of the request, e.g. admin/accounts
 * @returns {RestRequets<T>} The generated request functions
 */
export function generateRest<T>(
	name: string,
	defaultWith: string[] = []
): RestRequets<T> {
	const requests: RestRequets<T> = {
		destroy: (id) => del(`${name}/${id}`),
		list: (q = "", qWith = defaultWith, props = {}) => {
			const query = qs.stringify({
				with: qWith.join(","),
				q,
				...props,
			});
			return get(`${name}?${query}`);
		},
		show: (id, qWith = defaultWith, props = {}) => {
			const query = qs.stringify({
				with: qWith.join(","),
				...props,
			});
			return get(`${name}/${id}?${query}`);
		},
		store: (data) => post(name, data),
		update: (id, data) => patch(`${name}/${id}`, data),
		put: (id, data) => put(`${name}/${id}`, data),
	};

	return requests;
}
