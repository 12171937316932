export async function listFiles() {
	return gapi.client.load("drive", "v3", undefined).then(() => {
		return gapi.client.drive.files.list({
			corpora: "allDrives",
			pageSize: 12,
			fields:
				"nextPageToken, files(id, name, mimeType, iconLink, hasThumbnail, createdTime, fullFileExtension)",
		});
	});
}

export async function pickFiles(callback: any) {
	const authInstance = gapi.auth2.getAuthInstance();
	const currentUser = authInstance.currentUser.get();
	const { access_token } = currentUser.getAuthResponse(true);
	const developerKey = "AIzaSyCblWfkmGoxO_2mFZujsVfUSOItGazmt24";

	const view = new google.picker.DocsView(google.picker.ViewId.DOCS)
		.setParent("root")
		.setEnableDrives(true);

	// const shared_view = new google.picker.DocsView(google.picker.ViewId.DOCS);

	const picker = new google.picker.PickerBuilder()
		.enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
		.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
		.addView(google.picker.ViewId.DOCS)
		.addView(view)
		.setOAuthToken(access_token)
		.setDeveloperKey(developerKey)
		.setCallback(callback)
		.build();

	picker.setVisible(true);
}

export function askPermission(callback, onError) {
	const options = new gapi.auth2.SigninOptionsBuilder({
		scope: "https://www.googleapis.com/auth/drive",
	});
	let googleUser = gapi.auth2.getAuthInstance().currentUser.get();
	googleUser.grant(options).then(callback, onError);
}
