import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Table, Input, Dropdown } from "semantic-ui-react";
import "./labels-view.scss";
import Layout from "../../../components/Layout";
import Button from "../../../components/Form/Button";
import CreateModal from "./CreateModal";
import MergeModal from "./MergeModal";
import api from "../../../api/api";
import { ApiLabelsObject } from "../../../api/admin/labels";
import Label from "../../../components/Label";
import moment from "moment";

const LabelsView = () => {
	const [data, setData] = useState([] as any);
	const [fetching, setFetching] = useState(true);
	const [addModal, setAddModal] = useState(false);
	const [mergeModal, setMergeModal] = useState(0);
	const [sort, setSort] = useState({
		by: "name",
		direction: "ASC",
	});
	const [query, setQuery] = useState("");
	const [selected, setSelected] = useState({
		id: undefined,
		text: "",
		color: {
			text: "",
			background: "",
		},
	} as any);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		setData(handleSort(data));
	}, [sort]);

	const refetch = () => {
		setFetching(true);
		setQuery("");
		return api.admin
			.listLabels()
			.then((res: any) => {
				setData(handleSort(res));
				setFetching(false);
			})
			.catch(() => {
				setFetching(false);
			});
	};

	const handleEdit = (item: ApiLabelsObject) => {
		setSelected({
			id: item.id,
			text: item.name,
			color: item.color,
		});
		setAddModal(true);
	};

	const handleDelete = (item: ApiLabelsObject) => {
		if (
			!window.confirm(
				`Are you sure you wish to delete this label? The label will be removed from all linked Actions and Needs`
			) ||
			!item.id
		) {
			return;
		}
		api.admin
			.deleteLabel(item.id)
			.then(() => setData(data.filter((i) => i.id !== item.id)));
	};

	const handleSort = (items) => {
		const direction = sort.direction.toLowerCase();
		const by = sort.by || "name";
		let values = _.orderBy(
			items,
			[
				(item) =>
					typeof item[by] === "string"
						? item[by].toLowerCase()
						: item[by],
			],
			direction
		);
		return values;
	};

	const OPTIONS = [
		{ value: "name;ASC", text: "Label: alphabetical order" },
		{ value: "name;DESC", text: "Label: reversed alphabetical order" },
		{ value: "needs;DESC", text: "Needs: biggest first" },
		{ value: "needs;ASC", text: "Needs: smallest first" },
		{ value: "actions;DESC", text: "Actions: biggest first" },
		{ value: "actions;ASC", text: "Actions: smallest first" },
	];

	return (
		<>
			<CreateModal
				onClose={() => setAddModal(false)}
				visible={addModal}
				selected={selected}
				setData={(data) => setData(handleSort(data))}
				data={data}
			/>
			<MergeModal
				labels={data}
				onClose={() => setMergeModal(0)}
				visible={mergeModal !== 0}
				id={mergeModal}
				refetch={refetch}
			/>
			<Layout loading={fetching} className="labels-view">
				<div className="labels-view-head">
					<div className="title-row">
						<h2>Labels</h2>
						<Dropdown
							value={`${sort.by};${sort.direction}`}
							onChange={(event, data) => {
								let value = data.value as string;
								let s = value.split(";");
								let direction = s[1];
								let by = s[0];
								setSort({
									direction,
									by,
								});
							}}
							options={OPTIONS}
							fluid
							selection
						/>
						<Input
							value={query}
							onChange={(event, data: any) =>
								setQuery(data.value)
							}
							icon="search"
							placeholder="Search labels.."
						/>
					</div>
					<Button onClick={() => setAddModal(true)} icon={"fa-plus"}>
						Add label
					</Button>
				</div>
				<div className="labels-view-body">
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Label</Table.HeaderCell>
								<Table.HeaderCell>
									Attached need
								</Table.HeaderCell>
								<Table.HeaderCell>
									Attached actions
								</Table.HeaderCell>
								<Table.HeaderCell>Created at</Table.HeaderCell>
								<Table.HeaderCell textAlign="right">
									Actions
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{data &&
								data.length &&
								data
									.filter((item: ApiLabelsObject) =>
										item.name
											.toLowerCase()
											.includes(query.toLowerCase())
									)
									.map((item: ApiLabelsObject) => (
										<Table.Row key={item.id}>
											<Table.Cell>
												<Label color={item.color}>
													{item.name}
												</Label>
											</Table.Cell>
											<Table.Cell>
												<b>{item.needs || 0}</b>
											</Table.Cell>
											<Table.Cell>
												<b>{item.actions || 0}</b>
											</Table.Cell>
											<Table.Cell>
												{moment(item.created_at).format(
													"DD MMM YYYY - HH:mm"
												) || ""}
											</Table.Cell>
											<Table.Cell textAlign="right">
												<div className="actions-row">
													<Button
														onClick={() =>
															handleEdit(item)
														}
														icon="fa-pencil"
													></Button>
													<Button
														onClick={() =>
															setMergeModal(
																item.id || 0
															)
														}
														icon="fa-code-merge"
													></Button>
													<Button
														onClick={() =>
															handleDelete(item)
														}
														icon="fa-trash"
													></Button>
												</div>
											</Table.Cell>
										</Table.Row>
									))}
						</Table.Body>
					</Table>
				</div>
				{/* <div className="labels-view-body">
					{data &&
						data.length &&
						data
							.filter((item: ApiLabelsObject) =>
								item.name
									.toLowerCase()
									.includes(query.toLowerCase())
							)
							.map((item: ApiLabelsObject) => (
								<div key={item.id} className="label-row">
									<div className="name">
										<Label color={item.color}>
											{item.name}
										</Label>
									</div>
									<div className="relations">
										<span className="relation">
											<b>Needs:</b>
											{item.needs || 0}
										</span>
										<span className="relation">
											<b>Actions:</b>
											{item.actions || 0}
										</span>
										<span className="relation">
											<b>Created at:</b>
											{moment(item.created_at).format(
												"DD MMM YYYY - HH:mm"
											) || ""}
										</span>
									</div>
									<div className="label-row-actions">
										<HoverList
											element={
												<i className="fas fa-ellipsis-v" />
											}
										>
											<HoverListItem
												onClick={() => handleEdit(item)}
											>
												<i className="fal fa-pencil"></i>{" "}
												Edit
											</HoverListItem>
											{item.id && (
												<HoverListItem
													onClick={() =>
														setMergeModal(
															item.id || 0
														)
													}
												>
													<i className="fal fa-code-merge"></i>{" "}
													Merge
												</HoverListItem>
											)}
											<HoverListItem
												onClick={() =>
													handleDelete(item)
												}
											>
												<i className="fal fa-trash"></i>
												Delete
											</HoverListItem>
										</HoverList>
									</div>
								</div>
							))}
				</div> */}
			</Layout>
		</>
	);
};

export default LabelsView;
