import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./modal.scss";
import Loading from "../Layout/Loading";

type ModalProps = {
	onClose: (visible: boolean) => void;
	visible: boolean;
	children?: any;
	title?: any;
	title_extra?: any;
	buttons?: any;
	error?: {
		message: string;
		type: string;
	};
	loading?: boolean;
	type?: string;
	className?: string;
	alert?: any;
};

const Modal = ({
	onClose,
	visible,
	children,
	title,
	title_extra,
	buttons,
	loading,
	error,
	type,
	className,
	alert,
}: ModalProps) => {
	const [fetching, setFetching] = useState(loading);
	const [popup, setPopup] = useState(false);
	const [maxHeight, setMaxHeight] = useState("auto");
	const headRef = useRef(null as any);

	const handleClick = (event: React.MouseEvent) => {
		onClose(false);
	};

	const calculateHeight = () => {
		if (!visible) {
			return;
		}
		const windowHeight = window.innerHeight;
		let head = headRef.current;
		let headHeight = head.clientHeight;
		let top = head.getBoundingClientRect().top;
		let offset = top + headHeight;
		let max = windowHeight - offset - 20;
		setMaxHeight(`${max}px`);
	};

	useLayoutEffect(() => {
		calculateHeight();
		window.addEventListener("resize", calculateHeight);
		return () => {
			window.removeEventListener("resize", calculateHeight);
		};
	});

	useEffect(() => {
		if (error && error.message) {
			setPopup(true);
			setTimeout(() => {
				setPopup(false);
			}, 2500);
		}
		document.addEventListener(
			"keydown",
			(event) => {
				if (event.keyCode === 27) {
					onClose(false);
				}
			},
			false
		);
	}, [error]);

	useEffect(() => {
		if (visible) {
			setFetching(true);
			setTimeout(() => {
				setFetching(false);
			}, 500);
		}
	}, [visible]);

	const modalClassName = `
		modal ${type ? "modal-" + type : ""} 
		${className || ""}
		${alert ? "modal-has-alert" : ""}
	`;

	return (
		<AnimatePresence exitBeforeEnter>
			{visible ? (
				<>
					<motion.div
						onClick={handleClick}
						className={`shadow is-visible-shadow`}
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.4 }}
						exit={{ opacity: 0 }}
					/>
					<div className={modalClassName}>
						<motion.div
							initial={{ opacity: 0, scale: 0.4 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, y: -300 }}
							className="modal-wrapper"
						>
							<div ref={headRef} className="modal-head">
								{title ? title : false}
								{title_extra ? title_extra : false}
							</div>
							{alert || false}
							<div style={{ maxHeight }} className="modal-body">
								{popup && (
									<div
										className={`modal-popup ${
											error && error.type
												? "modal-popup_" + error.type
												: ""
										}`}
									>
										<p>{error?.message}</p>
									</div>
								)}
								<div className="modal-body_content">
									{fetching || loading ? (
										<div className="modal-body_loading">
											<Loading type={"transparent"} />
										</div>
									) : (
										children
									)}
								</div>
								{buttons ? (
									<div className="modal-footer">
										{buttons}
									</div>
								) : (
									false
								)}
							</div>
						</motion.div>
					</div>
				</>
			) : (
				false
			)}
		</AnimatePresence>
	);
};

export default React.memo(Modal);
