import accounts from "./accounts";
import dashboard from "./dashboard";
import squads from "./squads";
import users from "./users";
import chapters from "./chapters";
import labels from "./labels";
import pillars from "./pillars";
import products from "./products";

export default {
	accounts,
	dashboard,
	squads,
	users,
	chapters,
	labels,
	pillars,
	products,
};
