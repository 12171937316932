import { ApiUserObject } from "./users";
import { generateRest } from "../utils";

export interface ApiSquadObject {
	id: number;
	name: string;
	users?: ApiUserObject[];
	content?: string;
	drive_url?: string;
}

export default generateRest<ApiSquadObject>("admin/squads", ["users"]);
