import { useState, useEffect, useCallback } from "react";
import { get } from "../api/utils";

const useGet = (route?: string) => {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState({});
	const [error, setError] = useState(null as any);

	const fetchGet = useCallback(async () => {
		if (route) {
			setLoading(true);
			try {
				let response = await get(route);
				setResult(response);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, [route]);

	useEffect(() => {
		fetchGet();
	}, [route, fetchGet]);

	return [result, loading, error, fetchGet];
};

export default useGet;
