import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import qs from 'qs';
import { Input, Message } from "semantic-ui-react";
import store from "../../store";
import {
	updateProfile,
	updateFetchState,
	updateAuthError,
} from "../../reducers/auth";
import api from "../../api/api";
import "./login.scss";
import { ApplicationState } from "../../reducers";
import Button from "../../components/Form/Button";
import Logo from "../../components/Layout/Logo";
import Auth, { onSignedInChange } from "../../lib/Auth";
import { fetchUserUsers } from "../../reducers/users";
import { setUsers } from "../../reducers/filters";
import { REDIRECT_URL } from "../../Routes";

const LoginView = (props: any) => {
	const dispatch = useDispatch();
	const [fetching, setFetching] = useState(true);
	const [preloading, setPreloading] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [error, setError] = useState("");
	const [showMessage, setShowMessage] = useState(false);
	useEffect(() => {
		const token = qs.parse(window.location.search.substring(1));

		if (token && token._setToken) {
			localStorage.setItem('a_token', token._setToken as string);
			window.location.href = '/';
			return;
		}

		checkProfile();
	}, []);

	const checkProfile = async () => {
		api.showAuthProfile()
			.then((profile) => {
				if (profile.id) {
					store.dispatch(
						updateProfile({
							id: profile.id,
							name: profile.full_name,
							email: profile.email_address,
							avatar: profile.avatar,
							roles: profile.roles,
							is_admin: profile.is_admin,
						})
					);
					redirectToNeeds();
				} else {
					setFetching(false);
				}
			})
			.catch((error) => {
				setFetching(false);
			});
	};

	const redirectToNeeds = () => {
		setPreloading("Loading users");
		api.getDropdownOptions("users", "", true)
			.then(({ data }: any) => {
				dispatch(setUsers({ users: data }));
				setTimeout(() => {
					props.history.push(REDIRECT_URL);
				}, 500);
			})
			.finally(() => setPreloading(""));
	};

	const handleLogin = () => {
		setFetching(true);
		api.requestLogin(emailAddress).then(() => {
			setFetching(false);
			setShowMessage(true);
		}).catch(() => {
			setFetching(false);
			setShowMessage(true);
		})
		// Auth.signIn()
		// 	.then(async (res) => {
		// 		onSignedInChange().then(() => {
		// 			redirectToNeeds();
		// 		});
		// 	})
		// 	.catch((e) => {
		// 		setFetching(false);
		// 		setError("Something went wrong, try again.");
		// 	});
	};

	return (
		<div className="login">
			<div className="login-wrapper">
				<div className="login-wrapper_head">
					<Logo />
				</div>
				{preloading ? (
					<div className="login-wrapper_body">
						<div className="preloading">
							<h4>{preloading}</h4>
						</div>
					</div>
				) : (
					<div className="login-wrapper_body">
						{showMessage ? (
							<Message
								success
								content="An email has been sent with a login link"
							/>
						) : (<>
							<p style={{ textAlign: 'center' }}>Enter your email address below and we'll send you a magic link to log you in to Masterboard instantly!</p>
							<p>
								{props.filters.fetching
									? "Fetching filters..."
									: ""}
							</p>

							<Input
								placeholder="Your Roche email"
								style={{ marginBottom: 10 }}
								value={emailAddress}
								onChange={(e: any, data: any) => setEmailAddress(data.value)}
							/>

							<Button
								brand
								isLoading={fetching}
								onClick={handleLogin}
							>
								Send me the magic link
							</Button>
						</>)}
					</div>
				)}

				<div
					className={`login-wrapper_error ${
						error ? "login-wrapper_error_visible" : ""
					}`}
				>
					<div className="message">{error}</div>
				</div>
			</div>
		</div>
	);
};

export default connect(
	(state: ApplicationState) => {
		return {
			auth: state.auth,
			filters: state.filters,
		};
	},
	{ updateFetchState, updateAuthError, fetchUserUsers }
)(LoginView);
