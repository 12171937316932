import React, { useRef, useState, useEffect } from "react";
import useBlur from "../../lib/useBlur";
import "./collaborator-picker.scss";
import Avatar from "../Avatar";
import UserPicker from "../TogglePicker/UserPicker";

type CollaboratorPickerProps = {
	collaborators: any[];
	updateCollabrators: (ids: number[]) => any;
};

const CollaboratorPicker = ({
	collaborators,
	updateCollabrators,
}: CollaboratorPickerProps) => {
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [ids, setIds] = useState([] as number[]);
	const wrapper = useRef(null);
	useBlur(wrapper, () => setVisible(false));

	useEffect(() => handleIds(), []);
	useEffect(() => handleIds(), [collaborators]);
	const handleIds = () => {
		const items = collaborators.map((user) => user.id);
		setIds(items);
	};

	return (
		<div ref={wrapper} className="collaborator-picker">
			<label>Watchers</label>
			{visible ? (
				<div className="collaborator-picker-input">
					<UserPicker
						dropdownPlaceholder="Select collaborator"
						value={ids}
						loading={loading}
						onChange={(users: any) => {
							setLoading(true);
							updateCollabrators(users).finally(() =>
								setLoading(false)
							);
						}}
						multiple
						isDropdown
					/>
				</div>
			) : (
				<div className="collaborator-picker-avatars">
					{collaborators.map((user) => (
						<Avatar
							key={user.id + user.avatar}
							name={user.full_name}
							src={user.avatar}
						/>
					))}
					<div onClick={() => setVisible(true)} className="add-icon">
						<i className="fal fa-plus"></i>
					</div>
				</div>
			)}
		</div>
	);
};

CollaboratorPicker.defaultProps = {
	collaborators: [],
};

export default CollaboratorPicker;
