import React, { Component, FormEvent } from "react";
import { withRouter } from "react-router";
import { Segment, Header, Form, Button } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import { connect } from "react-redux";
import { history } from "../../../Routes";
import { fetchAccounts, updateAccount } from "../../../reducers/accounts";
import SegmentActions from "../../../components/SegmentActions/SegmentActions";
import { ApiAccountObject } from "../../../api/admin/accounts";
import api from "../../../api/api";
import Layout from "../../../components/Layout";
import { ApplicationState } from "../../../reducers";

interface AccountEditProps {
	account?: ApiAccountObject;
	isFetching: boolean;
	updateAccount: (account: ApiAccountObject) => void;
	fetchAccounts: () => void;
	id?: string;
	match: any;
}

class AccountEdit extends Component<AccountEditProps, any> {
	async componentDidMount() {
		if (this.props.match.params.id) {
			const id = parseInt(this.props.match.params.id);
			this.props.fetchAccounts();
			const result = await api.admin.showAccount(id);
			this.props.updateAccount(result);
		}
	}

	onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		if (!this.props.match.params.id) {
			return;
		}
		const id = parseInt(this.props.match.params.id);

		this.props.fetchAccounts();
		const data: ApiAccountObject = {
			id: -1,
			uci: formData.get("uci") as string,
			name: formData.get("name") as string,
			address: formData.get("address") as string,
			city: formData.get("city") as string,
			zipcode: formData.get("zipcode") as string,
			email_address: formData.get("email_address") as string,
			telephone_number: formData.get("telephone_number") as string,
			website: formData.get("website") as string,
		};

		this.props.fetchAccounts();
		api.admin.updateAccount(id, data).then((res) => {
			toast({
				type: "success",
				description: "Squad saved succesfully",
				time: 2500,
			} as any);
			this.props.updateAccount(res as ApiAccountObject);
			history.push("/accounts");
		});
	};

	renderForm() {
		if (this.props.isFetching || !this.props.account) {
			return null;
		}
		const account: ApiAccountObject = this.props.account;
		return (
			<Form onSubmit={this.onSubmit}>
				<Form.Field>
					<label htmlFor="name">UCI</label>
					<input
						type="text"
						defaultValue={account.uci}
						name="uci"
						id="uci"
					></input>
				</Form.Field>
				<Form.Field>
					<label htmlFor="name">Name</label>
					<input
						type="text"
						defaultValue={account.name}
						name="name"
						id="name"
					></input>
				</Form.Field>
				<Form.Field>
					<label htmlFor="address">Address</label>
					<input
						type="text"
						defaultValue={account.address}
						name="address"
						id="address"
					></input>
				</Form.Field>
				<Form.Field>
					<label htmlFor="city">City</label>
					<input
						type="text"
						defaultValue={account.city}
						name="city"
						id="city"
					></input>
				</Form.Field>
				<Form.Field>
					<label htmlFor="zipcode">Zipcode</label>
					<input
						type="text"
						defaultValue={account.zipcode}
						name="zipcode"
						id="zipcode"
					></input>
				</Form.Field>

				<SegmentActions>
					<Button
						basic
						content="Cancel"
						onClick={() => window.history.back()}
						type="button"
					/>
					<Button primary content="Save" type="submit" />
				</SegmentActions>
			</Form>
		);
	}

	render() {
		return (
			<Layout>
				<Segment>
					<Header as="h1">Edit account</Header>
					{this.renderForm()}
				</Segment>
			</Layout>
		);
	}
}

export default withRouter(
	connect(
		(state: ApplicationState, ownProps: AccountEditProps) => ({
			account: state.accounts.accounts.find(
				(a) => a.id === parseInt(ownProps.match.params.id + "")
			),
			isFetching: state.accounts.isFetching,
		}),
		{ updateAccount, fetchAccounts }
	)(AccountEdit)
);
