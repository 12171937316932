import { generateRest, get } from "./utils";

export interface ApiLabelsObject {
	value: string;
	text: string;
	id?: number;
	color?: {
		text?: string;
		background?: string;
	};
}

const rest = generateRest<ApiLabelsObject>("labels");
export default {
	...rest,
	all: async () => get("labels?all=1"),
};
