import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Label from "../../../components/Label";
import { Dropdown } from "semantic-ui-react";
import api from "../../../api/api";
import Button from "../../../components/Form/Button";
import { ApiLabelsObject } from "../../../api/admin/labels";

type CreateModalProps = {
	visible: boolean;
	onClose: () => void;
	labels: ApiLabelsObject[];
	id: number;
	refetch: any;
};

const CreateModal = ({
	visible,
	onClose,
	labels,
	id,
	refetch,
}: CreateModalProps) => {
	const [loading, setLoading] = useState(false);
	const [target, setTarget] = useState(0);
	const [status, setStatus] = useState({
		text: "",
		type: "",
	});

	let label = labels && labels.length && labels.find((i) => i.id === id);
	labels = labels && labels.length ? labels.filter((i) => i.id !== id) : [];
	const dropdownValues =
		labels && labels.length
			? labels.map((item) => ({
					text: item.name,
					value: item.id,
					key: item.id,
			  }))
			: [];

	const handleClose = () => {
		onClose();
	};

	const handleMerge = () => {
		if (!target && target !== undefined) return;

		if (window.confirm("Are you sure you want to merge those labels?")) {
			setLoading(true);
			api.admin
				.mergeLabels({
					label: target,
					labels: [id],
				})
				.then(() => {
					if (refetch) {
						refetch();
					}
					setTarget(0);
					setLoading(false);
					onClose();
				})
				.catch((e) => {
					setStatus({
						text: e.text || "Something went wrong",
						type: "error",
					});
					setLoading(false);
				});
		}
	};

	return (
		<Modal
			className="label-merge-modal"
			onClose={handleClose}
			visible={visible}
			type="small"
			title={<h1>Merge labels</h1>}
			title_extra={<i onClick={handleClose} className="fal fa-times"></i>}
			buttons={
				<div className="buttons">
					<Button type="transparent" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						isLoading={loading}
						icon="fa-code-merge"
						disabled={!target}
						onClick={handleMerge}
					>
						Merge labels
					</Button>
				</div>
			}
		>
			{status.text && (
				<div className={`alert alert-${status.type}`}>
					{status.text || ""}
				</div>
			)}
			<div className="wrapper">
				<div className="form">
					<div className="form-group">
						<label>Selected label</label>
						{label && label.id ? (
							<Label color={label.color}>{label.name}</Label>
						) : (
							false
						)}
					</div>
				</div>
				<div className="form form-center">
					<div className="form-group">
						<label>Select a label to merge in to</label>
						<Dropdown
							onChange={(e, data: any) => setTarget(data.value)}
							value={target}
							fluid
							selection
							search
							placeholder="Select labels to replace"
							options={dropdownValues}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CreateModal;
