import React, { Component, FormEvent } from "react";
import { withRouter } from "react-router";
import { Segment, Header, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { history } from "../../../Routes";
import { fetchSquads, updateSquad } from "../../../reducers/squads";
import SegmentActions from "../../../components/SegmentActions/SegmentActions";
import { ApiSquadObject } from "../../../api/admin/squads";
import api, { ApiResponse, ApiList } from "../../../api/api";
import Layout from "../../../components/Layout";
import Editor from "../../../components/Form/Editor";
import { ApplicationState } from "../../../reducers";

interface StateProps {
	squad?: ApiSquadObject;
	isFetching: boolean;
	errors: any;
}

interface OwnProps {
	match: any;
}

interface SquadState {
	vpcontent: string;
}

interface DispatchProps {
	updateSquad: (request: ApiResponse<ApiSquadObject>) => void;
	fetchSquads: (request: ApiResponse<ApiList<ApiSquadObject>>) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class SquadEdit extends Component<Props, SquadState> {
	// constructor(props: Props) {
	//   super(props);
	//   this.state = {
	//     vpcontent: '',
	//   }
	// }

	async componentDidMount() {
		if (this.props.match.params.id) {
			this.props.fetchSquads(api.admin.listSquads());
		}
	}

	/**
	 * Handle input
	 */
	handleInput = (e: any) => {
		this.setState({ vpcontent: e });
	};

	onSuccess = () => {
		toast({
			type: "success",
			description: "Squad saved succesfully",
			time: 2500,
		} as any);
		history.push("/squads");
	};

	onError = () => {
		toast({
			type: "error",
			title: "Something went wrong",
			description: "Please check all required fields",
			time: 0,
		});
	};

	onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);

		const data: ApiSquadObject = {
			id: -1,
			name: formData.get("name") as string,
			content: this.state.vpcontent,
			drive_url: formData.get("drive_url") as string,
		};

		if (this.props.match.params.id) {
			this.props.updateSquad(
				api.admin.updateSquad(
					parseInt(this.props.match.params.id),
					data
				)
			);
		} else {
			this.props.updateSquad(api.admin.storeSquad(data));
		}
	};

	renderForm() {
		if (this.props.isFetching) {
			return null;
		}

		return (
			<Form onSubmit={this.onSubmit}>
				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.last_name !== undefined
					}
					name="name"
					label="Name"
					required
					defaultValue={this.props.squad ? this.props.squad.name : ""}
				/>
				<div style={{ margin: "20px 0" }}>
					<Editor
						placeholder="Description"
						defaultValue={
							this.props.squad ? this.props.squad.content : ""
						}
						onDone={(text) => {
							this.handleInput(text);
						}}
					/>
				</div>

				<Form.Input
					error={
						this.props.errors &&
						this.props.errors.drive_url !== undefined
					}
					name="drive_url"
					label="Drive url"
					required
					defaultValue={
						this.props.squad ? this.props.squad.drive_url : ""
					}
					style={{
						marginBottom: 20,
					}}
				/>

				<SegmentActions>
					<Button
						basic
						content="Cancel"
						onClick={() => history.push("/squads")}
						type="button"
					/>
					<Button primary content="Save" type="submit" />
				</SegmentActions>
			</Form>
		);
	}

	render() {
		return (
			<Layout>
				<Segment>
					<Header as="h1">
						{this.props.squad ? "Edit" : "New"} squad
					</Header>
					{this.renderForm()}
				</Segment>
			</Layout>
		);
	}
}

export default withRouter(
	connect(
		(state: ApplicationState, ownProps: OwnProps) => ({
			squad: ownProps.match.params.id
				? state.squads.squads[parseInt(ownProps.match.params.id)]
				: undefined,
			isFetching: state.squads.isFetching,
			errors: {},
		}),
		{ updateSquad, fetchSquads }
	)(SquadEdit)
);
