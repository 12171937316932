import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const useIsNeed = () => {
	const [isNeed, setIsNeed] = useState(false);
	let location = useLocation();
	useEffect(() => {
		if (location.pathname.includes("/outcome")) {
			setIsNeed(true);
		} else {
			setIsNeed(false);
		}
	}, [location]);
	return isNeed;
};
export default useIsNeed;
