import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./filter.scss";
import { filterColors as colors, emptyFilter } from "../../api/filters";
import Tags from "./Tags";
import DropdownOptionV2 from "./DropdownOptionV2";
import { ApplicationState } from "../../reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import Search from "./Search";
import api from "../../api/api";
import DropdownToggle from "./DropdownToggle";
import useIsNeed from "../../lib/useIsNeed";
import { restoreLocalFilters, setUsers } from "../../reducers/filters";

const Filter = ({ currentUserId }: { currentUserId: number }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const auth = useSelector((state: ApplicationState) => state.auth);
	const users = useSelector((state: ApplicationState) => state.filters.users);
	const isNeed: boolean = useIsNeed();
	const [toggled, setToggled] = useState(false);
	const [open, setOpen] = useState(false);
	const [filters, setFilters] = useState({
		presets: [],
		squads: [],
		chapters: [],
		accounts: [],
		needs: [],
		assignees: [],
		users: [],
		labels: [],
		need_scopes: [],
	});
	const [value, setValue] = useState(emptyFilter);
	const [hideFilter, setHideFilter] = useState(false);
	useEffect(() => {
		const path = location.pathname;
		if (path.includes("outcome") || path.includes("output")) {
			setHideFilter(false);
		} else {
			setHideFilter(true);
		}
	}, [location]);

	useEffect(() => {
		if (auth && auth.id) {
			if (!users || !users.length) {
				api.getDropdownOptions("users", "", true).then(
					({ data }: any) => {
						dispatch(setUsers({ users: data }));
					}
				);
			}
		}
	}, [users]);

	useEffect(() => {
		dispatch(restoreLocalFilters());
	}, [isNeed]);

	if (!filters) {
		return null;
	}

	if (hideFilter) {
		return <div></div>;
	}

	return (
		<>
			<div
				className={`filter ${open ? "filter-open" : ""} ${
					toggled ? "filter-toggled" : ""
				} js-calculate`}
			>
				<div className="filter-search">
					<Search />
				</div>
				<div onClick={() => setOpen(!open)} className="filter-more">
					<i className={`fal fa-${open ? "times" : "sliders-v"}`}></i>
				</div>
				<div className="filter-options">
					<div className="filter-options-presets">
						<DropdownOptionV2
							multiple
							placeholder="Select filter preset"
							type="preset"
						/>
					</div>

					<div className="filter-options-items">
						<DropdownOptionV2
							multiple
							placeholder="Select timeframe"
							type="timeframes"
						/>

						<DropdownOptionV2
							color={colors.pillars}
							multiple
							placeholder="Select pillar"
							type="pillars"
						/>

						<DropdownOptionV2
							multiple
							placeholder="Select pages"
							type="pages"
						/>

						<DropdownOptionV2
							color={colors.assignees}
							multiple
							placeholder="Select assignees"
							type="assignees"
						/>
						<DropdownOptionV2
							color={colors.users}
							multiple
							placeholder="Created by"
							type="users"
							className={"filter_hidden"}
						/>
						{!isNeed && (
							<DropdownOptionV2
								className={"filter_hidden"}
								color={colors.collaborators}
								multiple
								placeholder="Select collaborators"
								type="collaborators"
							/>
						)}

						{isNeed && (
							<DropdownOptionV2
								className={"filter_hidden"}
								color={colors.need_scopes}
								multiple
								placeholder="Select goal type"
								type="need_scopes"
							/>
						)}
						<DropdownOptionV2
							className={"filter_hidden"}
							multiple
							placeholder="Select squads"
							type="squads"
						/>
						<DropdownOptionV2
							className={"filter_hidden"}
							color={colors.chapters}
							multiple
							placeholder="Select chapter"
							type="chapters"
						/>
						<DropdownOptionV2
							className={"filter_hidden"}
							color={colors.products}
							multiple
							placeholder="Select products"
							type="products"
						/>
						<DropdownOptionV2
							className={"filter_hidden"}
							color={colors.accounts}
							multiple
							placeholder="Select accounts"
							type="accounts"
						/>
						<DropdownToggle
							label="Archived"
							className="filter_hidden dropdown-option"
						/>
					</div>
				</div>
				<div
					onClick={() => setToggled(!toggled)}
					className="more-button"
				>
					<i
						className={`fal fa-chevron-double-${
							toggled ? "up" : "down"
						}`}
					></i>
					<span>More options</span>
				</div>
			</div>
			<Tags
				onFiltersChange={setFilters}
				colors={colors}
				isNeed={isNeed}
				filters={filters}
				value={value}
			/>
		</>
	);
};

export default connect((state: ApplicationState) => ({
	users: state.users.users,
	currentUserId: state.auth.id,
}))(Filter);
