import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from "redux";
import { pickBy } from "lodash";
import { ApiSquadObject } from "../api/admin/squads";
import { ApiResponse, ApiList } from "../api/api";

export interface SquadsState {
  squads: { [key: number]: ApiSquadObject },
  isFetching: boolean;
  error: any;
};

// Action creators
export const storeSquads = createAction('storeSquads');
export const filterSquads = createAction('filterSquads');
export const setFetchingState = createAction('setFetchingState');
export const setErrors = createAction('setErrors', null, () => ({ isError: true }));

// Async action creators
export function fetchSquads(request: ApiResponse<ApiList<ApiSquadObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => {
        dispatch(storeSquads(res.data)) 
        return res;
      })
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

export function updateSquad(request: ApiResponse<ApiSquadObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setFetchingState(true));
    return request
      .then(res => {
        dispatch(storeSquads([res]))
        return null;
      })
      .catch((error: Error) => dispatch(setErrors(error.message)))
      .finally(() => dispatch(setFetchingState(false)));
  }
}

const squadsReducer = handleActions({
  [storeSquads.toString()]: (state, action) => {
    // state.squads = action.payload;
    action.payload.forEach((squad: ApiSquadObject) => {
      state.squads[squad.id] = squad;
    });
    return {
      ...state,
      
    }
  },
  [filterSquads.toString()]: (state, action) => {
    return {
      ...state,
      squads: pickBy(state.squads, (v: any, k: string) => {
        return parseInt(k) !== action.payload;
      })
    }
  },
  [setFetchingState.toString()]: (state, action) => {
    return {
      ...state,
      isFetching: action.payload
    }
  },
}, {
  squads: {},
  isFetching: false,
  error: null,
});

export default squadsReducer;
