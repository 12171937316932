import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import "./error-boundary.scss";

class ExampleBoundary extends Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { eventId: null, hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<div className="error-boundary">
					<div className="card">
						<h1>Something went wrong</h1>
						<button
							onClick={() =>
								Sentry.showReportDialog({
									eventId: this.state.eventId,
								})
							}
						>
							Report feedback
						</button>
					</div>
				</div>
			);
		}

		//when there's not an error, render children untouched
		return this.props.children;
	}
}

export default ExampleBoundary;
