import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./profilecard.scss";
import { logout } from "../../../reducers/auth";
import store from "../../../store";
import Auth from "../../../lib/Auth";
import Avatar from "../../Avatar";
import ProfileView from "../../../views/ProfileView";
import ExportModal from "./ExportModal";

type ProfileCardProps = {
	history: any;
	auth: {
		avatar?: string;
		name: string;
	};
};

const ProfileCard = withRouter(({ auth, history }: ProfileCardProps) => {
	const [profileVisible, setProfileVisible] = useState(false);
	const [exportVisible, setExportVisible] = useState(false);
	const src = auth.avatar;
	const [open, setOpen] = useState(true);
	const handleLogout = () => {
		Auth.signOut();
		store.dispatch(logout());
		history.push("/");
	};

	const handleProfile = () => setProfileVisible(true);
	return (
		<>
			<ProfileView
				visible={profileVisible}
				close={() => setProfileVisible(false)}
			/>
			<ExportModal
				visible={exportVisible}
				onClose={() => setExportVisible(false)}
			/>
			<div className="profile-card">
				<p className="name">{auth.name}</p>
				<Avatar onClick={() => setOpen(!open)} src={src} />

				<div className={`menu ${open ? "menu-open" : ""}`}>
					<MenuItem
						label="Profile"
						icon={<i className="fal fa-user" />}
						onClick={handleProfile}
					/>
					<MenuItem
						label="Export report"
						icon={<i className="fal fa-arrow-right" />}
						onClick={() => setExportVisible(true)}
					/>
					<MenuItem
						label="Uitloggen"
						icon={<i className="fal fa-sign-out-alt" />}
						onClick={handleLogout}
					/>
				</div>
			</div>
		</>
	);
});

type MenuItemProps = {
	label: string;
	onClick: () => void;
	icon?: any;
};

const MenuItem = ({ label, onClick, icon }: MenuItemProps) => (
	<div onClick={onClick} className="menu-item">
		{icon ? <div className="icon">{icon}</div> : false}
		<p className="label">{label}</p>
	</div>
);

export default connect(
	(state: any) => {
		return {
			auth: state.auth,
		};
	},
	{ logout }
)(ProfileCard);
