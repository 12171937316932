import React from "react";
import "./statuses.scss";
import StatusDropdown from "../StatusDropdown";

// const STATUS = [
// 	{
// 		key: "Backlog",
// 		value: 1,
// 		text: "Backlog",
// 		bg: "#666666",
// 		color: "#fff",
// 	},
// 	{
// 		key: "To Do",
// 		value: 2,
// 		text: "To Do",
// 		bg: "#FD3F38",
// 		color: "#fff",
// 	},
// 	{
// 		key: "Doing",
// 		value: 3,
// 		text: "Doing",
// 		bg: "#FDB03B",
// 		color: "#fff",
// 	},
// 	{
// 		key: "Done",
// 		value: 4,
// 		text: "Done",
// 		bg: "#8AD112",
// 		color: "#fff",
// 	},
// ];

type StatusesProps = {
	setAction: any;
	action: any;
	handleUpdate?: (item: any) => void;
	disabled?: string[];
	isCreate?: boolean;
};

const Statuses = ({
	setAction,
	action,
	disabled,
	handleUpdate,
	isCreate,
}: StatusesProps) => {
	const handleChange = (key, value) => {
		if (handleUpdate) {
			handleUpdate({ [key]: value });
		}
		setAction({
			[key]: value,
		});
	};

	return (
		<div className="statuses">
			<div className="statuses-body">
				{!disabled ||
					(!disabled.includes("Page") && (
						<StatusDropdown
							multiple
							onChange={(pages) => {
								handleChange("pages", pages);
							}}
							value={action.pages}
							label="Page"
							type="pages"
							needId={action.need_id}
							{...{ isCreate }}
						/>
					))}
				{!disabled ||
					(!disabled.includes("Chapter") && (
						<StatusDropdown
							multiple
							onChange={(chapters) => {
								handleChange("chapters", chapters);
							}}
							value={action.chapters}
							label="Chapter"
							type="chapters"
							needId={action.need_id}
							{...{ isCreate }}
						/>
					))}

				{!disabled ||
					(!disabled.includes("Squad") && (
						<StatusDropdown
							multiple
							onChange={(squads) => {
								handleChange("squads", squads);
							}}
							value={action.squads}
							label="Squad"
							type="squads"
							needId={action.need_id}
							{...{ isCreate }}
						/>
					))}
				
				<div style={{ marginTop: 5, marginBottom: 8, border: 'solid 1px #e5e5e5' }} />

				{!disabled ||
					(!disabled.includes("Product") && (
						<StatusDropdown
							multiple
							onChange={(products) => {
								handleChange("products", products);
							}}
							value={action.products}
							label="Product"
							type="products"
							needId={action.need_id}
							{...{ isCreate }}
						/>
					))}
				{!disabled ||
					(!disabled.includes("Accounts") && (
						<StatusDropdown
							multiple
							onChange={(accounts) => {
								handleChange("accounts", accounts);
							}}
							value={action.accounts}
							label="Accounts"
							type="accounts"
							needId={action.need_id}
							{...{ isCreate }}
						/>
					))}
			</div>
		</div>
	);
};

export default Statuses;
