import React, { useState, useEffect } from "react";
import { Draggable } from "react-smooth-dnd";
import _ from "lodash";
import api from "../../api/api";

import "./kanban.scss";
import Column from "./Column";
import KanbanCard from "./KanbanCard";
import socket from "../../lib/socket";
import { connect } from "react-redux";
import moment from "moment";

type KanbanProps = {
	layout: string;
	items: {};
	onChange: (items: any) => void;
	onClick: (item: any) => void;
	updateItem?: (items: any, item: any) => void;
	disabled?: boolean;
	currentUserId: number;
	refetch?: any;
};

export const STATUS_NAMES = ["Backlog", "To-do", "Doing", "Done"];

const Kanban = ({ layout, items, onClick, disabled, refetch }: KanbanProps) => {
	const [data, setData] = useState(items);
	const colors = [/*"#DEDEDE", */"#666666", "#FD3F38", "#FDB03B", "#8AD112"];
	const delayedUpdate = _.debounce(
		(id, status) => handleSocket(id, status),
		100
	);

	useEffect(() => {
		setData(items);
	}, [items]);

	const handleSocket = (id, status) => {
		socket.emit("action status change", {
			id,
			status,
		});
	};

	const handleOnDrop = (key: any, dropResult: any) => {
		const { payload, removedIndex, addedIndex } = dropResult;
		if (removedIndex !== null || addedIndex !== null) {
			const result = { ...data };
			let itemToAdd = payload;

			if (removedIndex !== null) {
				itemToAdd = result[key].splice(removedIndex, 1)[0];
			}

			if (addedIndex !== null) {
				data[key].push({
					...itemToAdd,
					status_name: key,
					status: STATUS_NAMES.indexOf(key),
				});
				data[key].sort((a: any, b: any) => {
					let ad = moment(a.deadline, "YYYY MM DD")
						.toDate()
						.getTime();
					let bd = moment(b.deadline, "YYYY MM DD")
						.toDate()
						.getTime();
					return ad - bd;
				});
				api.updateAction(itemToAdd.id, {
					status: STATUS_NAMES.indexOf(key),
				});
				delayedUpdate(itemToAdd.id, STATUS_NAMES.indexOf(key));
			}
			setData(result);
		}
	};

	const handleChange = (action) => {
		refetch();
	};

	const getChildPayload = (key: string, index: number) => {
		return data[key][index];
	};

	return (
		<div className={`kanban kanban-${layout}`}>
			{Object.keys(data).map((key, index) => (
				<Column
					onDrop={(e) => handleOnDrop(key, e)}
					getChildPayload={(index) => getChildPayload(key, index)}
					key={key + index}
					disabled={disabled}
					status={key}
					layout={layout}
					total={data[key].length}
				>
					{data[key].map((item) => (
						<Draggable
							key={
								item.id +
								item.subject +
								Math.random() +
								"action"
							}
						>
							<KanbanCard
								onChange={handleChange}
								action={item}
								pending={item.pending}
								onClick={() => onClick(item)}
								color={colors[index]}
								content={item.subject}
								deadline={item.deadline}
								refetch={refetch}
								assignee_id={
									item.assignee
										? item.assignee.id
										: item.created_by.id
								}
							/>
						</Draggable>
					))}
				</Column>
			))}
		</div>
	);
};

export default connect((state: any) => ({
	currentUserId: state.auth.id,
}))(Kanban);
