import { createAction, handleActions } from "redux-actions";
import { Dispatch } from "redux";
import { ApiList, ApiResponseObject, ApiResponse } from "../api/api";
import { ApiNeedObject } from "../api/needs";
import { ApiAccountObject } from "../api/admin/accounts";

export interface NeedsState {
	needs: { [key: number]: ApiNeedObject };
	isFetching: boolean;
	openNeedsPanel: boolean;
}

export const updateNeeds = createAction("updateNeeds");
export const updateNeed = createAction("updateNeed");
export const setNeedLoadingStatus = createAction("setNeedLoadingState");
export const resetNeeds = createAction("resetNeeds");
export const openNeedsPanel = createAction("openNeedsPanel");

export function fetchNeeds(request: ApiResponse<ApiList<ApiNeedObject>>) {
	return async (dispatch: Dispatch) => {
		dispatch(setNeedLoadingStatus(true));
		const result = await request;

		const filtered = result.data
			.map(stripAccountData)
			.map(stripCreatedByData);

		dispatch(updateNeeds({ ...result, data: filtered }));
		dispatch(setNeedLoadingStatus(false));
	};
}

export function storeNeed(request: ApiResponse<ApiNeedObject>) {
	return async (dispatch: Dispatch) => {
		dispatch(setNeedLoadingStatus(true));
		const result = await request;
		dispatch(updateNeed(result));
		dispatch(setNeedLoadingStatus(false));
	};
}

function stripAccountData(need: ApiNeedObject) {
	if (!need.account) {
		// If there is no account data, return the need without making a copy. Not
		// making a copy allows for checking if the need is changed (`need !== copy`)
		// and is less of a performance impact.
		return need;
	}

	const copy: ApiNeedObject = { ...need };
	// Check if the need already has an account_id and reuse that.
	copy.account_id = need.account_id ? need.account_id : need.account.id;

	// Unset account data.
	copy.account = undefined;

	if (copy.accounts) {
		if (copy.accounts[0] && typeof copy.accounts[0] === "number") {
			// no-op
		} else {
			copy.accounts = (copy.accounts as ApiAccountObject[]).map(
				o => o.id
			);
		}
	}

	return copy;
}

function stripCreatedByData(need: ApiNeedObject) {
	if (!need.created_at) {
		return need;
	}

	const copy: ApiNeedObject = { ...need };
	if (copy.created_by && typeof copy.created_by !== "number") {
		copy.created_by = copy.created_by.id;
	}
	return copy;
}

const needsReducer = handleActions(
	{
		[updateNeeds.toString()]: (state, action) => {
			const payload = action.payload as ApiList<ApiNeedObject> &
				ApiResponseObject;
			if (payload.data) {
				payload.data.forEach(o => {
					state.needs[o.id ? o.id : -1] = o;
				});
			}
		},
		[updateNeed.toString()]: (state, action) => {
			const payload = action.payload as ApiNeedObject & ApiResponseObject;
			if (payload.id) {
				state.needs[payload.id] = payload;
			}
		},
		[setNeedLoadingStatus.toString()]: (state, action) => {
			state.isFetching = action.payload;
		},
		[resetNeeds.toString()]: state => {
			state.needs = {};
		},
		[openNeedsPanel.toString()]: state => {
			state.openNeedsPanel = !state.openNeedsPanel;
		}
	},
	{
		needs: {},
		isFetching: false,
		openNeedsPanel: false
	}
);

export default needsReducer;
