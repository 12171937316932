import React from "react";
import "./label.scss";

type LabelProps = {
	children?: any;
	type?: string;
	onClick?: (e: any) => void;
	onClickIcon?: () => void;
	color?: {
		text?: string;
		background?: string;
	};
	icon?: string;
};

const Label = ({
	children,
	onClick,
	onClickIcon,
	type,
	color,
	icon,
}: LabelProps) => {
	let style = {
		color: color?.text || "#0072ff",
		backgroundColor: color?.background || "#caedff",
	};

	return (
		<div
			onClick={onClick ? onClick : () => {}}
			style={style}
			className={`custom-label custom-label-${type || "default"}`}
		>
			{children || ""}
			{icon && (
				<i
					onClick={(e) => {
						if (onClickIcon) {
							e.stopPropagation();
							onClickIcon();
						}
					}}
					className={`custom-label-icon ${icon}`}
				/>
			)}
		</div>
	);
};

export default Label;
