import React, { Component } from "react";
import "./Editor.scss";
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
	EditorState,
	convertToRaw,
	convertFromRaw,
	ContentState,
} from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";

interface Props {
	onDone?: (content: string) => void;
	onChange?: (content?: any) => void;
	defaultValue?: string;
	placeholder?: string;
	ref?: any;
}

interface State {
	editorState: any;
}

export default class Editor extends Component<Props> {
	state: State;
	constructor(props: any) {
		super(props);
		let content = EditorState.createEmpty();
		if (this.props.defaultValue) {
			content = this.getEditorContent(this.props.defaultValue);
		}
		this.state = {
			editorState: content,
		};
	}

	getEditorContent(data: any) {
		try {
			// Try parsing EditorState from JSON.
			const editorState = EditorState.createWithContent(
				convertFromRaw(JSON.parse(data))
			);
			return editorState;
		} catch (e) {
			const editorState = EditorState.createWithContent(
				ContentState.createFromText(data)
			);
			return editorState;
		}
	}

	componentWillReceiveProps(newProps: Props) {
		if (newProps.defaultValue !== this.props.defaultValue) {
			this.setState({
				editorState: this.getEditorContent(newProps.defaultValue || ""),
			});
		}
	}

	onChange = (editorState: any) => {
		this.setState({
			editorState,
		});
		if (this.props.onChange) {
			this.props.onChange(JSON.stringify(this.getRaw()));
		}
	};

	getMarkdown() {
		const content = draftToMarkdown(
			convertToRaw(this.state.editorState.getCurrentContent())
		);
		return content;
	}

	getRaw() {
		const content = convertToRaw(
			this.state.editorState.getCurrentContent()
		);
		return content;
	}

	handleOnBlur() {
		if (this.props.onDone) {
			this.props.onDone(JSON.stringify(this.getRaw()));
		}
	}

	render() {
		const { ...props } = this.props;
		// const setEditorReference = (ref) => {
		// 	if (ref) {
		// 		ref.focus();
		// 	}
		// };

		return (
			<div className="editor">
				<DraftEditor
					// editorRef={setEditorReference}
					placeholder={props.placeholder}
					// toolbarOnFocus
					editorState={this.state.editorState}
					onEditorStateChange={this.onChange}
					toolbar={{
						options: ["inline", "list", "link"],
						inline: { inDropdown: false },
						list: { inDropdown: true },
						image: { previewImage: false },
					}}
					onBlur={() => this.handleOnBlur()}
					{...props}
				/>
			</div>
		);
	}
}
