import React, { useState, useRef } from "react";
import useBlur from "../../lib/useBlur";
import "./action-dropdown.scss";

type ActionDropdownProps = {
	handleArchive: () => void;
	handleDelete: () => void;
	handleDuplicate?: () => void;
	fetching?: string;
	isArchived?: boolean;
};

const ActionDropdown = ({
	handleArchive,
	handleDelete,
	handleDuplicate,
	fetching,
	isArchived,
}: ActionDropdownProps) => {
	const [visible, setVisible] = useState(false);
	const element = useRef(null);
	useBlur(element, (val) => setVisible(val));

	return (
		<div ref={element} className="action-dropdown">
			<div
				onClick={(e) => {
					e.stopPropagation();
					setVisible(!visible);
				}}
				className="icon"
			>
				<i className="fas fa-ellipsis-v"></i>
			</div>
			<div
				className={`action-dropdown-items ${
					visible ? "action-dropdown-items_active" : ""
				}`}
			>
				<div
					onClick={(e) => {
						e.stopPropagation();
						handleArchive();
					}}
					className="action-dropdown-items-item"
				>
					<i
						style={{ color: "#0066CC" }}
						className={`fal fa-${
							fetching === "archive"
								? "spin fa-spinner-third"
								: "archive"
						}`}
					></i>
					{isArchived ? "Unarchive" : "Archive"}
				</div>
				{handleDuplicate ? (
					<div
						onClick={(e) => {
							e.stopPropagation();
							handleDuplicate();
						}}
						className="action-dropdown-items-item"
					>
						<i
							style={{ color: "#0066CC" }}
							className={`fal fa-${
								fetching === "duplicate"
									? "spin fa-spinner-third"
									: "clone"
							}`}
						></i>
						Single duplicate
					</div>
				) : (
					false
				)}

				<div
					onClick={(e) => {
						e.stopPropagation();
						handleDelete();
					}}
					className="action-dropdown-items-item"
				>
					<i
						style={{ color: "#FD3F38" }}
						className={`fal fa-${
							fetching === "delete"
								? "spin fa-spinner-third"
								: "trash-alt"
						}`}
					></i>
					Delete
				</div>
			</div>
		</div>
	);
};

export default ActionDropdown;
