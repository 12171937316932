import React, { useState, useEffect } from "react";
import "./todo-list.scss";
import { Input } from "semantic-ui-react";
import Button from "../Form/Button";
import { motion } from "framer-motion";
import TodoForm from "./TodoForm";
import { ApiActionObject } from "../../api/actions";
import TodoItem from "./TodoItem";
import api from "../../api/api";

const spring = {
	type: "spring",
	damping: 20,
	stiffness: 300,
};

const TodoList = ({ id, refetch }: { id: number; refetch?: () => any }) => {
	const [hideCompleted, setHideCompleted] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [addSub, setAddSub] = useState(0);
	const [query, setQuery] = useState("");
	let [items, setItems] = useState([] as ApiActionObject[]);
	let [filteredItems, setFilteredItems] = useState([] as ApiActionObject[]);
	const classes = ["todo-list"];
	if (hideCompleted) classes.push("todo-list-hide-active");

	if (hideCompleted) {
		items = items.filter((i) => !i.is_done);
	}

	useEffect(() => {
		setFilteredItems(items.filter((i) => {
			if (query === '') {
				return true;
			}
			return i.subject.toLowerCase().includes(query.toLowerCase());
		}));
	}, [query, items]);

	const fetch = () => {
		api.listActions("", [], { parent_id: id }).then(
			({ data }: { data: ApiActionObject[] }) => {
				setItems(data);
			}
		);
	}

	useEffect(() => {
		fetch()
	}, [id]);

	useEffect(() => {
		if (!showSearch) {
			setQuery('');
		}
	}, [showSearch]);

	const handleAddTodo = (subject: string, parentId?: number) => {
		setFetching(true);
		setAddSub(0);
		api.storeAction({
			parent_id: parentId || id,
			subject,
		})
			.then((res: ApiActionObject) => {
				fetch();
			})
			.finally(() => setFetching(false));
	};

	return (
		<>
			<label>Checklist</label>
			<div className={classes.join(" ")}>
				<div className="todo-list-head">
					<div className="todo-input">
						<div className="icon" onClick={() => setHideCompleted(!hideCompleted)} title="Hide/Show completed items" style={{ cursor: 'pointer' }}>
							{hideCompleted ? <i className="fal fa-square"></i> : <i className="fal fa-check-square"></i>}
						</div>
						<div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
							<Button onClick={() => setShowSearch(!showSearch)} type="gray">
								<i className={`fal fa-${showSearch ? 'times' : 'search'}`} style={{ margin: 0 }} />
							</Button>
							<Input
								value={query}
								onChange={(e, { value }: any) => setQuery(value)}
								placeholder="Search checklist"
								fluid
								style={{ width: '100%', marginLeft: 10, display: showSearch ? 'inherit' : 'none' }}
							/>
						</div>
					</div>
				</div>
				<ul className="todo-list-body">
					{filteredItems.map((i, index) => (
						<motion.li layoutTransition={spring} key={`check-${i.id}`}>
							<TodoItem
								onDelete={() => {
									let newItems = items;
									newItems.splice(index, 1);
									setItems([...newItems]);
								}}
								onAddSub={() => {
									setAddSub(i.id)
								}}
								action={i}
								onChange={(data: any) => {
									let newItems = items;
									newItems[index] = {
										...newItems[index],
										...data,
									};
									setItems([...newItems]);
								}}
								lvl={0}
							/>
							{i.checklist.map((a, sindex) => (
								<TodoItem
									onDelete={() => {
										fetch()
									}}
									action={a}
									onChange={(data: any) => {
										fetch()
									}}
									lvl={1}
								/>
							))}
							{addSub === i.id && <div style={{ paddingLeft: 30 }}>
								<TodoForm
									loading={fetching}
									onAdd={(subject: string) => handleAddTodo(subject, i.id)}
									simple
									forceVisible={true}
								/>
							</div>}
						</motion.li>
					))}
				</ul>
				<TodoForm loading={fetching} onAdd={handleAddTodo} />
			</div>
		</>
	);
};

export default TodoList;
