import React, { useState, useEffect } from "react";
import "./profile-view.scss";
import api from "../../api/api";
import Modal from "../../components/Modal";
import { ApiSettingsObject } from "../../api/settings";
import Switch from "../../components/Form/Switch";

const ProfileView = ({
	visible,
	close,
}: {
	visible: boolean;
	close: () => void;
}) => {
	const [fetching, setFetching] = useState(true);
	const [data, setData] = useState({} as ApiSettingsObject);

	useEffect(() => {
		if (visible && fetching) {
			api.showSettings()
				.then((res: ApiSettingsObject) => setData(res))
				.finally(() => setFetching(false));
		}
		return () => setFetching(false);
	}, [visible]);

	const handleSettingChange = (key: string, value: any) => {
		api.postSettings({
			[key]: value,
		}).then((res) => {
			setData(res);
		});
	};

	return (
		<Modal
			type="small"
			title_extra={
				<i
					onClick={close}
					className="fal profile-view-close fa-times"
				></i>
			}
			title={<h1>Profile Settings</h1>}
			visible={visible}
			onClose={() => close()}
			loading={fetching}
			className="profile-view"
		>
			<Tabs
				heads={["Notification Settings"]}
				items={[
					<>
						<TabItem
							title="New assignment"
							subtitle="Someone assigned me to a task"
							value={data.mail_assignee_allowed}
							onChange={(value) =>
								handleSettingChange(
									"mail_assignee_allowed",
									value
								)
							}
						/>

						<TabItem
							title="New collaboration invite"
							subtitle="Someone invited you to collaborate on an action"
							value={data.mail_collaboration_allowed}
							onChange={(value) =>
								handleSettingChange(
									"mail_collaboration_allowed",
									value
								)
							}
						/>
					</>,
				]}
			/>
		</Modal>
	);
};

type TabItem = {
	title: string;
	subtitle?: string;
	value: boolean;
	onChange: (value: boolean) => void;
};

const TabItem = ({ title, subtitle, value, onChange }: TabItem) => {
	return (
		<div className="tab-item">
			<div className="tab-item-info">
				<p className="tab-item-title">{title || ""}</p>
				{subtitle && <p className="tab-item-subtitle">{subtitle}</p>}
			</div>
			<Switch value={value} onChange={onChange} type="big" />
		</div>
	);
};

type TabsProps = {
	items: any[];
	heads: string[];
};
const Tabs = ({ items, heads }: TabsProps) => {
	const [selected, setSelected] = useState(0);
	return (
		<div className="tabs">
			<div className="tabs-heads">
				{heads.map((item, index) => (
					<div
						key={`${index}-head`}
						onClick={() => setSelected(index)}
						className={`tabs-heads-item ${
							index === selected ? "tabs-heads-item_active" : ""
						}`}
					>
						{item}
					</div>
				))}
			</div>
			<div className="tabs-body">
				<h2 className="tabs-title">{heads[selected]}</h2>
				{items[selected]}
			</div>
		</div>
	);
};

export default ProfileView;
