import React, { useEffect, useState } from "react";
import { Input, Segment } from "semantic-ui-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Button from "../../../components/Form/Button";
import Layout from "../../../components/Layout";
import api from "../../../api/api";
import "./dashboard.scss";
import config from "../../../config.json";

interface Period {
	from?: string;
	till?: string;
}

const defaultChartOptions = {
	credits: {
		enabled: false,
	},
	chart: {
		type: "line",
		zoomType: "x",
	},
	title: {
		text: "Daily logins",
	},
	xAxis: {
		categories: [],
	},
	yAxis: {
		min: 0,
		title: {
			text: "",
		},
		allowDecimals: false,
	},
	legend: {
		reversed: true,
	},
	series: [],
};

export default () => {
	const [chartOptions, setChartOptions] = useState<any>(defaultChartOptions);
	const [isFetching, setIsFetching] = useState(true);
	const [period, setPeriod] = useState<Period>({
		from: moment().subtract(1, "month").format("YYYY-MM-DD"),
		till: moment().format("YYYY-MM-DD"),
	});

	const updatePeriod = (type: string, value: string) => {
		const op = type === "from" ? "till" : "from";
		setPeriod({
			[type]: value,
			[op]: period[op],
		});
	};

	useEffect(() => {
		setIsFetching(true);
		api.admin.getLogins(period).then((data) => {
			setIsFetching(false);
			setChartOptions({
				xAxis: {
					categories: data.categories,
				},
				series: [
					{
						data: data.logins,
						name: "Logins",
					},
				],
			});
		});
	}, [period]);

	const exportData = () => {
		window.open(
			`${config.apiBaseUrl}/admin/dashboard/export?_token=${
				localStorage.getItem("a_token") || ""
			}`
		);
	};

	return (
		<Layout loading={isFetching}>
			<div className="dashboard-view-head">
				<div className="title-row">
					<h2>Dashboard</h2>
					<Input
						type="date"
						value={period.from || ""}
						onChange={(event, data: any) =>
							updatePeriod("from", data.value)
						}
					/>
					<Input
						type="date"
						value={period.till || ""}
						onChange={(event, data: any) =>
							updatePeriod("till", data.value)
						}
					/>
				</div>
				<Button icon="far fa-file-excel" onClick={() => exportData()}>
					Export
				</Button>
			</div>
			<div className="dashboard-view-body">
				<Segment>
					<HighchartsReact
						highcharts={Highcharts}
						options={chartOptions}
					/>
				</Segment>
			</div>
		</Layout>
	);
};
