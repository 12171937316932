import auth, { AuthState } from "./auth";
import users, { UsersState } from "./users";
import accounts, { AccountsState } from './accounts';
import squads, { SquadsState } from './squads';
import chapters, { ChaptersState } from './chapters';
import needs, { NeedsState } from "./needs";
import needsv2, { NeedsV2State } from "./needsv2";
import actions, { ActionsState } from "./actions";
import filters, { FiltersState } from "./filters";

export interface ApplicationState {
    auth: AuthState;
    users: UsersState;
    accounts: AccountsState;
    squads: SquadsState;
    chapters: ChaptersState;
    needs: NeedsState;
    needsv2: NeedsV2State;
    actions: ActionsState;
    filters: FiltersState;
}

const rootReducer = {
    auth,
    users,
    accounts,
    squads,
    chapters,
    needs,
    needsv2,
    actions,
    filters
};

export default rootReducer;
