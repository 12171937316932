import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import "./deadlinepicker.scss";
import api from "../../../api/api";
import socket from "../../../lib/socket";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { Popup } from "semantic-ui-react";

type DeadlinePickerProps = {
	value?: string;
	onChange: (value: string) => void;
	action?: any;
	type?: string;
	label?: string;
	iconOnly?: boolean;
	popup?: boolean;
};

const DeadlinePicker = ({
	value,
	onChange,
	action,
	type,
	label,
	iconOnly,
	popup,
}: DeadlinePickerProps) => {
	const [visible, setVisible] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.addEventListener("mousedown", (e) => handleClickOutside(e));
		return () => {
			document.removeEventListener("mousedown", (e) =>
				handleClickOutside(e)
			);
		};
	}, []);

	const handleClickOutside = (event) => {
		const allow =
			wrapperRef.current && !wrapperRef.current.contains(event.target);

		if (allow) {
			setVisible(false);
		}
	};

	const handleChange = (date) => {
		let deadline = moment(date).format("YYYY-MM-DD");
		setVisible(false);
		if (action && action.id) {
			action.deadline = deadline;
			socket.emit("action deadline changed", {
				actionId: action.id,
				deadline,
			});
		}
		onChange(deadline);
	};

	const handleClear = () => {
		if (action && action.id) {
			action.deadline = null;
			api.updateAction(action.id, action);
			socket.emit("action deadline changed", {
				actionId: action.id,
				deadline: null,
			});
		}
		onChange("");
		setVisible(false);
	};

	const renderLabel = (force?: boolean) => {
		if (!force && (iconOnly || popup)) {
			return "";
		}
		
		if (value) {
			return moment(value, "YYYY-MM-DD").format("DD MMM YYYY");
		}
		
		return 'No deadline';
	};

	const renderIcon = () => {
		const trigger = <i
			onClick={handleClear}
			className={`fal fa-calendar deadline-picker_icon`}
		></i>

		if (popup) {
			return <Popup
				inverted
				size="tiny"
				trigger={trigger}
				position="bottom center"
				content={renderLabel(true)}
			/>
		}

		return trigger;
	}

	return (
		<div className="deadline-picker-wrapper">
			{label ? <label>{label}</label> : false}
			<div
				ref={wrapperRef}
				onClick={(e) => {
					e.stopPropagation();
					setVisible(true);
				}}
				className={`deadline-picker 
                ${type ? "deadline-picker_" + type : ""} 
                ${value ? "deadline-picker_filled" : ""}
                ${visible ? "deadline-picker_visible" : ""}
            `}
			>
				<DatePicker
					customInput={
						<div className="deadline-picker-label">
							{renderIcon()}
							<span className="label">{renderLabel()}</span>
							{type === "kanban" && !visible && (
								<i className="fal fa-pencil edit-icon"></i>
							)}
							{value && (
								<i
									onClick={handleClear}
									className={`fal fa-times reset-icon deadline-picker_icon `}
								></i>
							)}
						</div>
					}
					onBlur={() => setVisible(false)}
					selected={value ? new Date(value) : new Date()}
					onChange={handleChange}
					iconOnly={iconOnly}
				/>
			</div>
		</div>
	);
};

export default DeadlinePicker;
