import { Component } from "react";
import socket from "../../lib/socket";
import { ApiActionObject } from "../../api/actions";

export default class SocketBindings extends Component {
	socket: any;
	constructor(props) {
		super(props);
		this.socket = socket;
		this.socket.on("action added", (action: any) =>
			this.dispatch("action added", action)
		);
		this.socket.on(
			"action status change",
			(data: { id: number; status: number; senderId: number }) =>
				this.dispatch("action status change", {
					id: data.id,
					status: data.status,
				})
		);
		this.socket.on("action assignee changed", (data: any) =>
			this.dispatch("action assignee changed", data)
		);
		this.socket.on("action changed", (action: ApiActionObject) =>
			this.dispatch("action changed", action)
		);
		this.socket.on("action deleted", (id: number) =>
			this.dispatch("action deleted", { id })
		);
		this.socket.on("action deadline changed", (data: any) =>
			this.dispatch("action deadline changed", data)
		);
	}

	dispatch = (action, detail) => {
		dispatchEvent(new CustomEvent(action, { detail }));
	};

	render() {
		return false;
	}
}
