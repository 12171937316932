import React, { useState, useRef } from "react";
import { Input } from "semantic-ui-react";
import "./generate-link.scss";
import useBlur from "../../lib/useBlur";

const GenerateLink = ({ url }: { url: string }) => {
	const [visible, setVisible] = useState("input");
	const [success, setSuccess] = useState(false);
	const input = useRef(null) as any;
	const element = useRef(null);
	useBlur(element, (val) => setVisible("input"));

	const renderChild = () => {
		if (visible === "input") {
			return (
				<>
					<Input
						ref={input}
						value={url}
						placeholder="Url"
						icon={
							<i
								onClick={() => {
									input.current.select();
									document.execCommand("copy");
									setSuccess(true);
								}}
								className={`${success ? "fas" : "fal"} fa-${
									success ? "check" : "clone"
								} generate-link-icon`}
							></i>
						}
					/>
					{success && (
						<span className="success">
							Link copied to clipboard!
						</span>
					)}
				</>
			);
		}
		return (
			<span
				onClick={() => {
					setVisible("input");
					setSuccess(false);
				}}
				className="generate-link-button"
			>
				Generate Direct Link
			</span>
		);
	};

	return (
		<div ref={element} className="generate-link">
			{renderChild()}
		</div>
	);
};

export default GenerateLink;
