import qs from "qs";
import { ApiResponse } from "./api";
import { ApiChapterObject } from "./admin/chapters";
import { get } from "./utils";

export default {
  list: (q = '', qWith: string[] = ['users'], props = {}) => {
    const query = qs.stringify({
      with: qWith.join(','),
      q,
      ...props,
    });
    return get(`chapters?${query}`) as ApiResponse<ApiChapterObject[]>;
  },
  show: (id: number, qWith: string[] = ['users'], props = {}) => {
    const query = qs.stringify({
      with: qWith.join(','),
      ...props,
    });
    return get(`chapters/${id}?${query}`) as ApiResponse<ApiChapterObject>;
  },
}
