import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./actions.scss";
import FilePicker from "../FilePicker";
import api from "../../../api/api";
import { ApiGoogleDriveDocumentObject } from "../../../api/actions";
import socket from "../../../lib/socket";
import HoverList, { HoverListItem } from "../../HoverList";

type ActionProps = {
	id: number;
	isNeed?: boolean;
	onClose?: () => void;
	refetch?: () => void;
	onClickClone?: () => void;
	onAddFile?: (file: ApiGoogleDriveDocumentObject) => void;
	showClone?: boolean;
	disabled?: boolean;
};

const Actions = ({
	id,
	isNeed,
	onClose,
	refetch,
	onAddFile,
	onClickClone,
	showClone,
	disabled,
}: ActionProps) => {
	const history = useHistory();
	const [loading, setLoading] = useState("");
	const [hide, setHide] = useState(false);

	const handleClone = () => {
		setLoading("clone");
		if (onClickClone) onClickClone();
	};

	const handleSingleClone = () => {
		setLoading("clone");
		if (!window.confirm("Are you want to clone this action?")) {
			return;
		}
		api.duplicateAction(id, [])
			.then((res: any) => {
				if (onClose) onClose();
				setLoading("");
				setTimeout(() => {
					refetchActions();
					if (res.id) {
						history.push(`/output/${res.id}`);
					}
				}, 500);
			})

			.catch(() => setLoading(""));
	};

	const handleArchive = () => {
		if (!window.confirm("Are you want to archive this action?")) {
			return;
		}
		setLoading("archive");
		if (!isNeed) {
			api.updateAction(id, {
				archived_at: moment().format("YYYY-MM-DD HH:mm:ss"),
			})
				.then(() => setLoading(""))
				.then(() => {
					refetchActions();
					if (onClose) onClose();
				})
				.catch(() => setLoading(""));
		}
	};

	const handleTrash = () => {
		if (!window.confirm("Are you want to delete this action?")) {
			return;
		}
		setLoading("delete");

		if (!isNeed) {
			api.destroyAction(id)
				.then(() => setLoading(""))
				.then(() => {
					refetchActions();
					if (onClose) onClose();
					socket.emit("action deleted", id);
				})
				.catch(() => setLoading(""));
		}
	};

	const refetchActions = () => (refetch ? refetch() : {});

	return (
		<div className="actions">
			{!disabled && (
				<HoverList
					element={
						<span className="actions-icon">
							<i className={`fal fa-clone`}></i>
						</span>
					}
				>
					<HoverListItem onClick={handleSingleClone}>
						<i className="fal fa-clone"></i>
						Single duplicate
					</HoverListItem>
					<HoverListItem onClick={handleClone}>
						<i className="fal fa-clone"></i>
						Duplicate to accounts
					</HoverListItem>
				</HoverList>
			)}

			{!disabled && !isNeed && !hide && (
				<FilePicker
					id={id}
					onAdd={(file) => (onAddFile ? onAddFile(file) : {})}
					button={
						<span className="actions-icon">
							<i
								className={`fal ${
									loading === "picker"
										? "fa-spin fa-spinner-third"
										: "fa-paperclip"
								}`}
							></i>
						</span>
					}
				/>
			)}
			{!hide && !disabled && (
				<span onClick={handleArchive} className="actions-icon">
					<i
						className={`fal ${
							loading === "archive"
								? "fa-spin fa-spinner-third"
								: "fa-archive"
						}`}
					></i>
				</span>
			)}
			{!hide && (
				<span
					onClick={handleTrash}
					className="actions-icon actions-icon_secondary"
				>
					<i
						className={`fal ${
							loading === "delete"
								? "fa-spin fa-spinner-third"
								: "fa-trash-alt"
						}`}
					></i>
				</span>
			)}
		</div>
	);
};

export default Actions;
