export enum UserRole {
  Admin = 1,
  SHP = 2,
  SquadMember = 3,
}

export const roleLabels = {
  [UserRole.Admin]: 'Administrator',
  [UserRole.SHP]: 'SHP',
  [UserRole.SquadMember]: 'Squad Member',
};
