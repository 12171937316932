import { ApiRole } from "./admin/users";
import { ApiResponse } from "./api";
import { get, post, patch } from "./utils";

export interface ApiProfileObject {
	id: number;
	full_name: string;
	first_name: string;
	last_name: string;
	email_address: string;
	avatar?: string;
	roles?: ApiRole[];
	server_roles?: ApiRole[];
	is_admin?: boolean;
}

export default {
	profile: () => get("auth/profile") as ApiResponse<ApiProfileObject>,
	patchProfile: (data: any) =>
		patch("auth/profile", data) as ApiResponse<ApiProfileObject>,
	login: (token) => post("auth/login", { token }),
	requestLogin: (emailAddress) => post('auth/request', { email_address: emailAddress }),
};
