import React, { useState, useEffect, useRef } from "react";
import "./needrow.scss";
import { STATUS_COLORS, STATUS_BORDER_COLORS } from "../../../api/actions";
import api from "../../../api/api";
import moment from "moment";
import Avatar from "../../Avatar";
import { useAddLabelToFilter } from "../../../api/filters";
import ProgressBar from "../../ProgressBar";
import Badge from "../../Badge";

type Pillar = {
	id: number;
	name: string;
	position: number;
};

type NeedRowProps = {
	name: string;
	badge: string;
	active: boolean;
	onClick: () => void;
	action_count: any;
	id?: number;
	deleteNeed?: () => void;
	isArchived?: boolean;
	assignee?: {
		avatar?: string;
		full_name?: string;
	};
	timeframe?: {
		name: string;
		id: number;
	};
	labels?: string[];
	pillars?: Pillar[];
	progress: number;
};

const NeedRow = ({
	name,
	badge,
	onClick,
	active,
	action_count,
	deleteNeed,
	id,
	isArchived,
	assignee,
	labels,
	pillars,
	timeframe,
	progress,
}: NeedRowProps) => {
	const addLabelToFilter = useAddLabelToFilter(true);
	const [visible, setVisible] = useState(false);
	const [fetching, setFetching] = useState("");
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.addEventListener("mousedown", (e) =>
			handleClickOutside(e, visible)
		);
		return () => {
			document.removeEventListener("mousedown", (e) =>
				handleClickOutside(e, visible)
			);
		};
	}, []);

	const handleClickOutside = (event, show) => {
		const allow =
			wrapperRef.current && !wrapperRef.current.contains(event.target);
		if (allow) {
			setVisible(false);
		}
	};

	const handleArchive = (e: any) => {
		e.stopPropagation();
		if (window.confirm("Are you sure you want to archive this need?")) {
			setFetching("archive");
			if (id) {
				api.updateNeed(id, {
					archived_at: isArchived
						? ""
						: moment().format("YYYY-MM-DD HH:mm:ss"),
				})
					.then(() => {
						if (deleteNeed) {
							deleteNeed();
						}
						setFetching("");
					})
					.catch(() => {
						setFetching("");
						alert("Something went wrong");
					});
			}
		}
	};

	const handleDelete = (e: any) => {
		e.stopPropagation();

		if (window.confirm("Are you sure you want to delete this need?")) {
			setFetching("delete");
			if (id) {
				api.destroyNeed(id)
					.then(() => {
						if (deleteNeed) {
							deleteNeed();
						}
						setFetching("");
					})
					.catch(() => {
						setFetching("");
						alert("Something went wrong");
					});
			}
		}
	};

	const renderMore = () => (
		<div className="more">
			<div
				onClick={(e) => {
					e.stopPropagation();
					setVisible(!visible);
				}}
				className="icon"
			>
				<i className="fas fa-ellipsis-v"></i>
			</div>
			<div className={`more-items ${visible ? "more-items_active" : ""}`}>
				<div onClick={handleArchive} className="more-items-item">
					<i
						style={{ color: "#0066CC" }}
						className={`fal fa-${
							fetching === "archive"
								? "spin fa-spinner-third"
								: "archive"
						}`}
					></i>
					{isArchived ? "Unarchive" : "Archive"}
				</div>
				<div onClick={handleDelete} className="more-items-item">
					<i
						style={{ color: "#FD3F38" }}
						className={`fal fa-${
							fetching === "delete"
								? "spin fa-spinner-third"
								: "trash-alt"
						}`}
					></i>
					Delete
				</div>
			</div>
		</div>
	);

	return (
		<div
			ref={wrapperRef}
			onClick={onClick}
			className={`need-row ${active ? "need-row_active" : ""}`}
		>
			{timeframe && <Badge>{timeframe.name}</Badge>}
			<h2 className="name">{name}</h2>
			{pillars?.map((p: Pillar) => (
				<Badge key={p.name + p.id}>{p.name}</Badge>
			))}
			{badge && <Badge>{badge}</Badge>}

			<div className="statuses">
				{assignee ? (
					<Avatar
						src={assignee.avatar}
						name={assignee.full_name}
						tooltip={assignee.full_name}
					/>
				) : (
					<Avatar tooltip={"No assignee"} />
				)}
				<div className="statuses-wrapper">
					<ProgressBar value={progress} />
					<div className="status-icons">
						{Object.keys(action_count).map((key, index) => (
							<StatusIcon
								key={key + index + action_count[key]}
								count={action_count[key]}
								color={STATUS_COLORS[index]}
								borderColor={STATUS_BORDER_COLORS[index]}
							/>
						))}
					</div>
				</div>
			</div>
			{renderMore()}
		</div>
	);
};

const StatusIcon = ({
	count,
	color,
	borderColor,
}: {
	count: number;
	color: string;
	borderColor: string;
}) => (
	<div
		style={{ backgroundColor: color, borderColor }}
		className="status-icon"
	>
		<p>{count}</p>
	</div>
);

export default NeedRow;
