import React, { useState, useEffect } from "react";
import "./notification.scss";

const events = {
	mb_alter_filter: "Filter has changed",
	mb_notification: "User has change",
};

const Notification = () => {
	const [visible, setVisible] = useState(false);
	const [message, setMessage] = useState("");
	const [type, setType] = useState("blank");
	useEffect(() => {
		// for (let key of Object.keys(events)) {
		//     window.addEventListener(key, (res: any) => {
		//         setVisible(true);
		//         setMessage(res?.detail?.message || events[key]);
		//         setType(res?.detail?.type || "blank");
		//         setTimeout(() => {
		//             setVisible(false);
		//             setMessage("");
		//             setType("blank");
		//         }, 3000);
		//     });
		// }
	}, []);

	return (
		<div
			className={`notification ${
				visible ? "notification_visible" : ""
			} notification-${type}`}
		>
			<div className="notification-head">
				<i
					onClick={() => setVisible(false)}
					className="fal fa-times"
				></i>
			</div>
			<div className="notification-body">
				<p>{message}</p>
			</div>
		</div>
	);
};

export default Notification;
