import React from "react";
import { withRouter } from "react-router-dom";
import oval from "../../../images/oval.svg";
import "./button.scss";

type ButtonProps = {
	isLoading?: boolean;
	type?: string;
	icon?: string;
	children: any;
	to?: string;
	onClick?: (event: any) => void;
	brand?: boolean;
	disabled?: boolean;
	history: any;
	className?: string;
	buttonType?: "button" | "submit" | "reset" | undefined;
};

const Button = ({
	isLoading,
	brand,
	onClick,
	type,
	icon,
	children,
	disabled,
	to,
	history,
	className,
	buttonType,
}: ButtonProps) => {
	const handleClick = (e) => {
		if (onClick) {
			onClick(e);
		} else if (to) {
			history.push(to);
		}
	};

	return (
		<button
			type={buttonType}
			disabled={disabled || isLoading}
			onClick={handleClick}
			className={`
                button ${type ? "button-" + type : ""} 
                ${icon ? "button-has-icon" : ""}
                ${isLoading ? "button-loading" : ""}
                ${disabled ? "button-disabled" : ""}
                ${icon && !children ? "button-only-icon" : ""}
                ${className || ""}
            `}
		>
			{isLoading ? (
				<img src={oval} alt="" />
			) : (
				<>
					{icon ? (
						<div className="icon">
							<i
								className={`${brand ? "fab" : "fal"} ${icon}`}
							></i>
						</div>
					) : (
						false
					)}
					{children}
				</>
			)}
		</button>
	);
};

export default withRouter(Button);
