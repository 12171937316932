import React, { useState } from "react";
import "./need-type.scss";
import { TYPES } from "../../CreateNeed";

type NeedTypeProps = {
	onChange: (value: any) => void;
	value: string;
};

const NeedType = ({ onChange, value }: NeedTypeProps) => {
	const [edit, setEdit] = useState(false);
	const selected = TYPES.find((i: any) => i.value === value);
	let items = TYPES;
	if (value && selected) {
		items = [selected, ...TYPES.filter((i: any) => i.value !== value)];
	}

	return (
		<div className={`need-type ${edit ? "need-type_edit" : ""}`}>
			<label>Outcome goal</label>
			<div className="need-type-wrapper">
				<div className="need-type-options">
					{items.length &&
						items.map((item: any) => (
							<div
								key={item.value + "need type"}
								onClick={() => {
									if (item.value !== value) {
										onChange(item.value);
									}
									setEdit(false);
								}}
								className={`item ${
									item.value === value ? "item-active" : ""
								}`}
							>
								<span>{item.name}</span>
							</div>
						))}
				</div>
				<i
					onClick={() => setEdit(!edit)}
					className={`fal fa-${edit ? "times" : "pencil"} edit-icon`}
				></i>
			</div>
		</div>
	);
};

export default NeedType;
