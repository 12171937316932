import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Table, Button, Header, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { storeChapters, fetchChapters } from "../../../reducers/chapters";
import { ApiChapterObject } from "../../../api/admin/chapters";
import api, { ApiResponse, ApiList } from "../../../api/api";
import Layout from "../../../components/Layout";
import ApiHideSwitch from "../../../components/ApiHideSwitch";
import { ApplicationState } from "../../../reducers";

interface ChaptersProps {
	chapters: { [key: number]: ApiChapterObject };
	isChaptersFetching: boolean;
	fetchChapters: (request: ApiResponse<ApiList<ApiChapterObject>>) => any;
}

class Chapters extends Component<ChaptersProps> {
	state = {
		isSearching: false,
	};

	async componentDidMount() {
		this.props.fetchChapters(api.admin.listChapters());
	}

	renderData() {
		return Object.values(this.props.chapters).map((o: any) => (
			<Table.Row key={o.id}>
				<Table.Cell>
					<Link to={`/chapters/${o.id}/edit`}>{o.name}</Link>
				</Table.Cell>
				<Table.Cell collapsing textAlign="right">
					<ApiHideSwitch
						url={`admin/chapters/${o.id}`}
						value={o.hide}
					/>
				</Table.Cell>
				<Table.Cell collapsing textAlign="right">
					<Link to={`/chapters/${o.id}/edit`}>
						<Button>Edit</Button>
					</Link>
				</Table.Cell>
			</Table.Row>
		));
	}

	render() {
		return (
			<Layout>
				<Segment>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Header as="h1">Chapters</Header>
						<Link to="/chapters/create">
							<Button
								primary
								icon="plus"
								style={{ marginBottom: "1rem" }}
							/>
						</Link>
					</div>
					<Input
						fluid
						loading={this.state.isSearching}
						placeholder="Search..."
					/>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell collapsing />
								<Table.HeaderCell collapsing textAlign="right">
									Actions
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.renderData()}</Table.Body>
					</Table>
				</Segment>
			</Layout>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({
		chapters: state.chapters.chapters,
		isChaptersFetching: state.chapters.isFetching,
	}),
	{ updateChapters: storeChapters, fetchChapters }
)(Chapters);
