import { generateRest, upload } from "../utils";

export interface ApiAccountObject {
  id: number;
  parent_id?: number;
  parent?: any;
  children?: [];
  uci?: string;
  type?: any;
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  email_address?: string;
  telephone_number?: string;
  website?: string;
  logo?: string;
}

const rest = generateRest<ApiAccountObject>('admin/accounts', ['parent', 'children']);

export default {
  ...rest,
  uploadLogo: (id: number, logo: File) => upload(
    `admin/accounts/${id}/upload-logo`,
    'logo',
    logo
  ),
  uploadImport: (file: File) => upload(
    'admin/accounts/upload-import',
    'file',
    file
  ),
};