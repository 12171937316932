import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Table, Button, Header, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { updateAccounts, fetchAccounts } from "../../../reducers/accounts";
import { ApiAccountObject } from "../../../api/admin/accounts";
import api from "../../../api/api";
import Layout from "../../../components/Layout";
import { ApplicationState } from "../../../reducers";

interface AccountsProps {
	accounts: ApiAccountObject[];
	isAccountsFetching: boolean;
	updateAccounts: (account: ApiAccountObject[]) => void;
	fetchAccounts: () => void;
}

let searchTimer: any;

class Accounts extends Component<AccountsProps> {
	state = {
		isSearching: false,
	};

	async componentDidMount() {
		this.props.fetchAccounts();
		const result = await api.admin.listAccounts();
		this.props.updateAccounts(result.data);
	}

	renderData() {
		return this.props.accounts.map((o: ApiAccountObject) => {
			return (
				<Table.Row key={o.id}>
					<Table.Cell>
						<Link to={`/accounts/${o.id}/edit`}>{o.name}</Link>
					</Table.Cell>
					<Table.Cell collapsing>
						{o.city}
					</Table.Cell>
					<Table.Cell collapsing textAlign="right">
						<Link to={`/accounts/${o.id}/edit`}>
							<Button basic icon="pencil" />
						</Link>
					</Table.Cell>
				</Table.Row>
			);
		});
	}

	searchAccounts = (query: string) => {
		clearTimeout(searchTimer);
		searchTimer = setTimeout(async () => {
			this.setState({ isSearching: true });
			const result = await api.admin.listAccounts(query);
			this.props.updateAccounts(result.data);
			this.setState({ isSearching: false });
		}, 300);
	};

	render() {
		return (
			<Layout>
				<Segment>
					<Header as="h1">Accounts</Header>
					<Input
						fluid
						loading={this.state.isSearching}
						placeholder="Search..."
						onChange={(e) => {
							this.searchAccounts(e.target.value);
						}}
					/>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell collapsing>Location</Table.HeaderCell>
								<Table.HeaderCell collapsing textAlign="right">
									Actions
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.renderData()}</Table.Body>
					</Table>
				</Segment>
			</Layout>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({
		accounts: state.accounts.accounts,
		isAccountsFetching: state.accounts.isFetching,
	}),
	{ updateAccounts, fetchAccounts }
)(Accounts);
