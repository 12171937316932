import { ApiResponse } from "./api";
import { get, post, patch, del } from "./utils";

type ApiPageObject = {
	id: number;
	name: string;
};

export default {
	list: () => {
		return get(`pages`) as ApiResponse<ApiPageObject[]>;
	},
	show: (id: Number) => {
		return get(`pages/${id}`) as ApiResponse<ApiPageObject>;
	},
	create: (name: string) => {
		return post(`admin/pages`, { name }) as ApiResponse<ApiPageObject>;
	},
	update: (id: number, name: string) => {
		return patch(`admin/pages/${id}`, { name }) as ApiResponse<
			ApiPageObject
		>;
	},
	delete: (id: number) => {
		return del(`admin/pages/${id}`) as ApiResponse<any>;
	},
};
