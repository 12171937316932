import { ApiNeedObject } from '../api/needs';
import { createAction, handleActions } from 'redux-actions';

export interface NeedsV2State {
  needsList: ApiNeedObject[],
};

export const setNeeds = createAction('setNeeds');

const needsReducer = handleActions({
  [setNeeds.toString()]: (state, action) => {
    state.needsList = action.payload;
  },
}, {
  needsList: [],
});

export default needsReducer;
