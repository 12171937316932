import { ApiResponse } from "./api";
import { ApiSquadObject } from "./admin/squads";
import { get } from "./utils";

export default {
	list: (isCreate?: boolean) => {
		let url = `pillars`;
		if (isCreate) url += "?create=1";
		return get(url) as ApiResponse<ApiSquadObject[]>;
	},
	show: (id: Number) => {
		return get(`pillars/${id}`) as ApiResponse<ApiSquadObject>;
	},
};
