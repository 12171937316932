import React from "react";
import "./badge.scss";

const Badge = ({
	children,
	onClick,
}: {
	children?: any;
	onClick?: (i?: any) => void;
}) => {
	return (
		<span onClick={onClick} className="badge">
			{children}
		</span>
	);
};

export default Badge;
