import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../Logo";
import "./nav.scss";
import NavItem from "../NavItem";
import ProfileCard from "../ProfileCard";
import { ApplicationState } from "../../../reducers";

const Nav = withRouter(({ history }: { history: any }) => {
	const auth = useSelector((state: ApplicationState) => state.auth);
	const [open, setOpen] = useState(false);
	const [isHome, setHome] = useState(true);

	useEffect(() => {
		setHome(window.location.pathname === "/");
	}, [window.location.pathname]);
	const routes = [
		{
			label: "Outcome",
			to: "/outcome",
		},
		{
			label: "Output",
			to: "/output",
		},
		{
			label: "Value Proposition",
			to: "/value-proposition",
		},
	];

	history.listen(() => setOpen(false));
	if (isHome) {
		return false;
	}
	return (
		<>
			<div className={`nav ${open ? "nav-open" : ""} js-calculate`}>
				<Logo />
				<div className="nav-items">
					{routes.map((route) => (
						<NavItem key={route.to} {...route} />
					))}
					{auth.is_admin && (
						<NavItem
							label="Admin"
							key={undefined}
							children={[
								{ label: "Dashboard", to: "/dashboard" },
								{ label: "Chapters", to: "/chapters" },
								{ label: "Users", to: "/users" },
								{ label: "Squads", to: "/squads" },
								{ label: "Timeframes", to: "/timeframes" },
								{ label: "Pages", to: "/pages" },
								{ label: "Pillars", to: "/pillars" },
								{ label: "Products", to: "/products" },
								{ label: "Accounts", to: "/accounts" },
							]}
						/>
					)}
				</div>
				<div className="nav-burger">
					<i
						onClick={() => setOpen(!open)}
						className={`fal fa-${open ? "times" : "bars"}`}
					></i>
				</div>
				<ProfileCard />
			</div>
		</>
	);
});

export default Nav;
