export type ObjectWithId = { id: number; [key: string]: any } | number;

export default function objectToId(data: ObjectWithId): number {
  if (typeof data === 'number') {
    return data;
  }
  return data.id;
}

export function mapObjectToId(data: ObjectWithId[]): number[] {
  return data.map(objectToId);
}
