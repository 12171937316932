import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import "./dropdownoption.scss";
import api from "../../../api/api";
import useFilterChange from "../../../lib/useFilterChange";
import useIsNeed from "../../../lib/useIsNeed";
import { useDispatch } from "react-redux";
import { setPresetFilter } from "../../../reducers/filters";
import useDropdownFetch from "../../../lib/useDropdownFetch";

type DropdownProps = {
	type: string;
	className?: string;
	placeholder?: string;
	multiple?: boolean;
	color?: string;
	handleChange?: (val: any) => void;
	defaultValue?: any;
};

const DropdownOption = ({
	type,
	className,
	placeholder,
	multiple,
	color,
	handleChange,
	defaultValue,
}: DropdownProps) => {
	const dispatch = useDispatch();
	const isNeed = useIsNeed();
	const [value, onChange] = useFilterChange(type, isNeed);
	const [_value, setValue] = useState(defaultValue || value);
	const [query, setQuery] = useState("");
	const [focused, setFocused] = useState(false);
	let [fetching, options] = useDropdownFetch({
		type,
		query,
		focused,
	}) as [boolean, any[]];

	let selected = {
		text: null,
	};

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handlePresetDelete = (e, data, closePopup = () => {}) => {
		if (e) {
			e.stopPropagation();
		}
		if (window.confirm("Are you sure you want to delete this preset")) {
			api.deleteFilter(data.value).then(() => {
				options = options.filter((o) => o.value !== data.value);
			});
		}
	};
	if (type === "preset") {
		let key = isNeed ? "need" : "action";

		options = options.filter((item) => {
			return item.type === key;
		});

		options = options.map((item) => ({
			...item,
			content: (
				<div className="dropdown-option-item">
					{item.text}
					<i
						onClick={(e) => handlePresetDelete(e, item)}
						className="fal fa-trash-alt"
					></i>
				</div>
			),
		}));
	}
	const handlePresetChange = (value: number[]) => {
		let selected = options.find((o: any) => value.includes(o.value));
		if (selected.filter) {
			dispatch(
				setPresetFilter({
					filter: selected.filter,
					isNeed,
				})
			);
		}
	};

	return (
		<div
			className={`
                dropdown-option
                ${type ? "dropdown-option_" + type : ""}
                ${value ? "dropdown-option_has_value" : ""}
                ${className || ""}
            `}
		>
			{_value && _value.length && type !== "preset" ? (
				<div
					style={{ backgroundColor: color ? color : "#0072ff" }}
					className="dropdown-option_badge"
				>
					{_value.length}
				</div>
			) : (
				false
			)}
			{!query ? (
				<div
					className={`dropdown-option_placeholder ${
						type === "preset" && selected && selected.text
							? "placeholder_value"
							: ""
					}`}
				>
					{type === "preset" && selected && selected.text
						? selected.text
						: placeholder}
				</div>
			) : (
				false
			)}
			<Dropdown
				loading={fetching}
				multiple={multiple}
				closeOnChange
				selectOnBlur={multiple}
				fluid
				search={type !== "preset"}
				selection
				selectOnNavigation
				// lazyLoad
				options={options || []}
				value={_value || []}
				onChange={(event, data: any) => {
					if (handleChange) {
						handleChange(data.value);
						return;
					}

					if (type === "preset") {
						handlePresetChange(data.value);
					} else {
						onChange(data.value);
					}
				}}
				onSearchChange={(e, data: any) => {
					setQuery(data.searchQuery);
				}}
				onFocus={() => {
					setFocused(true);
				}}
				onBlur={() => {
					setFocused(false);
					setQuery("");
				}}
			/>
		</div>
	);
};
export default DropdownOption;
