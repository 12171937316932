import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../reducers";
import { ApiUserObject } from "../../../api/admin/users";
import Avatar from "../../Avatar";
import "./assignee-picker.scss";
import { Dropdown } from "semantic-ui-react";
import { ApiDropdownValueObject } from "../../../api/filters";

type AssigneePickerProps = {
	value?: number;
	onChange: (id?: any) => void;
	users: { [key: number]: ApiUserObject };
	currentUserId: number;
	type?: string;
	label?: string;
	notOpen?: boolean;
	placeholder?: string;
	className?: string;
	iconOnly?: boolean;
};

const AssigneePicker = ({
	value,
	onChange,
	type,
	label,
	notOpen,
	placeholder,
	iconOnly,
	className,
}: AssigneePickerProps) => {
	const items = useSelector((state: ApplicationState) => state.filters.users);
	const [isToggled, setToggled] = useState(type === "input");
	const [search, setSearch] = useState("");
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [assigned_to, setAssigned_to] = useState(
		{} as ApiDropdownValueObject
	);

	useEffect(() => {
		if (notOpen) {
			setToggled(false);
		}
	}, [notOpen]);

	useEffect(() => {
		if (value) {
			let assignee = items.find((item) => item.value === value);
			setAssigned_to(assignee);
		}
	}, [value]);

	const handleChange = (e: any, data: any) => {
		if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			return;
		}
		if (data.value) {
			onChange(data.value);
			setToggled(false);
		}
	};

	const renderContent = () => {
		if (type === "input" || isToggled) {
			return (
				<div ref={wrapperRef} className="assignee-picker-dropdown">
					<Dropdown
						value={value}
						fluid
						selection
						placeholder={placeholder || "Select user to assign"}
						onFocus={() => setToggled(true)}
						searchInput={{ autoFocus: true }}
						options={items
							.filter((i: any) =>
								i.text
									.toLowerCase()
									.includes(search.toLowerCase())
							)
							.splice(0, 20)}
						onChange={handleChange}
						searchQuery={search}
						search
						className={className}
						onSearchChange={(e, { searchQuery }) =>
							setSearch(searchQuery)
						}

						// search={handleSearch}
					/>
					{value ? (
						<span
							onClick={(e) => {
								e.stopPropagation();
								onChange(null);
								setToggled(false);
							}}
							className={`clear`}
						>
							<i className="fal fa-times"></i>
						</span>
					) : (
						false
					)}
				</div>
			);
		}

		if (value && assigned_to?.value) {
			return (
				<>
					<Avatar
						src={
							assigned_to?.image?.src
								? assigned_to.image.src
								: undefined
						}
						name={assigned_to.text}
					/>
					<p className="full_name">
						{assigned_to && assigned_to.text
							? assigned_to.text
							: ""}
					</p>
				</>
			);
		}

		return (
			<>
				<div className="icon-add">
					<i className="far fa-user-plus"></i>
				</div>
				{!iconOnly && <p className="full_name">Owner</p>}
			</>
		);
	};

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				setToggled(true);
			}}
			className={`
                assignee-picker
                ${
					!assigned_to ||
					(!assigned_to.value && !isToggled && type !== "input")
						? "assignee-picker_empty"
						: ""
				}
				${iconOnly ? "assignee-picker-icon-only" : ""}
            `}
		>
			{label ? <label>{label}</label> : false}
			<div className="assignee-picker-wrapper">
				{renderContent()}
				{/* {type === "kanban" && !isToggled && (
					<i className="fal fa-pencil edit-icon"></i>
				)} */}
			</div>
		</div>
	);
};
export default connect((state: ApplicationState) => ({
	users: state.users.users,
	currentUserId: state.auth.id,
}))(AssigneePicker);
