import React, { useState, useEffect } from "react";
import { ChromePicker, GithubPicker } from "react-color";
import Modal from "../../../components/Modal";
import Label from "../../../components/Label";
import { Input } from "semantic-ui-react";
import api from "../../../api/api";
import Button from "../../../components/Form/Button";
import { ApiLabelsObject } from "../../../api/admin/labels";

type CreateModalProps = {
	visible: boolean;
	onClose: () => void;
	selected: {
		id?: number;
		text: string;
		color: {
			text?: string;
			background?: string;
		};
	};
	setData?: (items: ApiLabelsObject[]) => void;
	data?: ApiLabelsObject[];
};

const getContrast = (hexcolor: string) => {
	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === "#") {
		hexcolor = hexcolor.slice(1);
	}
	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor
			.split("")
			.map(function (hex) {
				return hex + hex;
			})
			.join("");
	}
	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0, 2), 16);
	var g = parseInt(hexcolor.substr(2, 2), 16);
	var b = parseInt(hexcolor.substr(4, 2), 16);
	// Get YIQ ratio
	var yiq = (r * 299 + g * 587 + b * 114) / 1000;
	// Check contrast
	return yiq >= 128 ? "black" : "white";
};

const CreateModal = ({
	visible,
	onClose,
	selected,
	setData,
	data,
}: CreateModalProps) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState({
		text: "",
		type: "",
	});
	const [label, setLabel] = useState(selected);

	useEffect(() => {
		setLabel(selected);
	}, [selected]);

	const handleClose = () => {
		setStatus({
			text: "",
			type: "",
		});
		setLabel({
			id: undefined,
			text: "",
			color: {
				text: getContrast("#caedff"),
				background: "#caedff",
			},
		});
		onClose();
	};

	const handleSave = () => {
		if (!label.text) {
			return false;
		}

		setLoading(true);
		api.admin
			.storeLabel(label)
			.then((res) => {
				setStatus({
					text: `Label ${label.id ? "updated" : "added"} succesful`,
					type: "succes",
				});
				setLabel({
					id: undefined,
					text: "",
					color: {
						text: getContrast("#caedff"),
						background: "#caedff",
					},
				});
				setLoading(false);
				handleUpdateOrSave(res);
				handleClose();
			})
			.catch((e) => {
				setStatus({
					text: e.text || "Something went wrong",
					type: "error",
				});
				setLoading(false);
			});
	};

	const handleUpdateOrSave = (item: ApiLabelsObject) => {
		let exist = data ? data.find((i) => i.id === item.id) : false;

		if (!setData) return;
		if (exist && exist.id && data) {
			setData(
				data?.map((i) => {
					if (i.id == item.id) {
						return item;
					}
					return i;
				})
			);
		} else {
			let items = data ? data : [];
			setData([item, ...items]);
		}
		handleClose();
	};

	return (
		<Modal
			className="label-create-modal"
			onClose={handleClose}
			visible={visible}
			type="small"
			title={<h1>{label.id ? "Edit" : "Add"} label</h1>}
			title_extra={<i onClick={handleClose} className="fal fa-times"></i>}
			buttons={
				<div className="buttons">
					<Button type="transparent" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						isLoading={loading}
						icon="fa-save"
						disabled={!label.text}
						onClick={handleSave}
					>
						Save label
					</Button>
				</div>
			}
		>
			{status.text && (
				<div className={`alert alert-${status.type}`}>
					{status.text || ""}
				</div>
			)}
			<div className="wrapper">
				<div className="form">
					<div className="form-group">
						<label>Label</label>
						<Input
							placeholder="Label"
							onChange={(e, data) => {
								setStatus({
									text: "",
									type: "",
								});
								setLabel({
									...label,
									text: data.value,
								});
							}}
							value={label.text}
						/>
					</div>
					<div className="form-group">
						<label>Background color</label>
						<ChromePicker
							color={label.color.background || ""}
							onChangeComplete={(color) =>
								setLabel({
									...label,
									color: {
										text: getContrast(color.hex),
										background: color.hex,
									},
								})
							}
						/>
						<GithubPicker
							color={label.color.background || ""}
							onChangeComplete={(color) =>
								setLabel({
									...label,
									color: {
										text: getContrast(color.hex),
										background: color.hex,
									},
								})
							}
						/>
					</div>
				</div>
				<div className="preview">
					<label>Preview</label>
					<Label color={label.color}>
						{label.text || "Type a label first"}
					</Label>
				</div>
			</div>
		</Modal>
	);
};

export default CreateModal;
