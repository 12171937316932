import React, { useState } from "react";
import "./avatar.scss";

const Avatar = ({
	src,
	onClick,
	type,
	tooltip,
	name,
}: {
	src?: string;
	onClick?: () => void;
	type?: string;
	tooltip?: string;
	name?: string;
}) => {
	const [error, setError] = useState(false);

	const getShortName = () => {
		if (!name) {
			return "";
		}
		let parts = name?.split(" ");
		parts = parts.map((str) => str[0]);
		let result = parts[0] + parts[parts.length - 1];
		return result;
	};

	return (
		<div
			onClick={onClick ? onClick : () => {}}
			className={`
                base-avatar 
				${type ? "base-avatar-" + type : ""}
				${name ? "base-avatar-name" : ""}
            `}
		>
			{!error && src ? (
				<img onError={() => setError(true)} src={src} alt="" />
			) : name ? (
				<span className="name">{getShortName()}</span>
			) : (
				<i className="fas fa-user"></i>
			)}
			{tooltip || name ? (
				<div className="tooltip">{name ? name : tooltip}</div>
			) : (
				false
			)}
		</div>
	);
};

export default Avatar;
