import qs from "qs";
import _ from "lodash";
import localforage from "localforage";
import { ApiResponse, ApiList } from "./api";
import { generateRest, post, get, upload, del } from "./utils";
import { ApiUserObject } from "./admin/users";
import { ApiSquadObject } from "./admin/squads";
import { ApiAccountObject } from "./admin/accounts";
import { ApiChapterObject } from "./admin/chapters";
import { ApiNeedObject } from "./needs";
import { hasFilter } from "./filters";

export const STATUS_COLORS = [
	"rgba(157,157,157,0.5)",
	"rgba(253,63,56,0.5)",
	"rgba(253,176,59,0.5)",
	"rgba(138,209,18,0.5)",
];

export const STATUS_BORDER_COLORS = [
	"#DEDEDE",
	"#9D9D9D",
	"#FD3F38",
	"#FDB03B",
	"#8AD112",
];

export interface ApiLabelObject {
	id: number;
	name: string;
	slug: string;
	color: {
		text?: string;
		background?: string;
	};
}

export interface ApiGoogleDriveDocumentObject {
	id?: number;
	url: string;
	iconUrl: string;
	name: string;
}

export interface ApiActionObject {
	id: number;
	need?: ApiNeedObject;
	comment_count?: number;
	created_by: ApiUserObject;
	need_id: number;
	assignee_id?: number;
	squads?: any[];
	chapters?: any[];
	collaborators: any[];
	assignee?: any; // TODO: define assignee
	squad?: ApiSquadObject;
	chapter?: ApiChapterObject;
	subject: string;
	description?: string;
	deadline?: string;
	status: number;
	status_name?: string;
	accounts: ApiAccountObject[] | number[];
	archived_at?: string;
	documents?: ApiGoogleDriveDocumentObject[];
	labels: ApiLabelObject[];
	is_done: boolean;
	parent_id?: number;
	checklist: ApiActionObject[];
	pages?: any[];
	products?: any[];
}

export interface ApiActionLogObject {
	id: number;
	user_id: number;
	user?: ApiUserObject;
	action: string;
	message: string;
	extra?: {
		from: number | number[];
		to: number | number[];
		content: string;
		[key: string]: any;
	};
	diff_for_humans: string;
	created_at: string;
}

const rest = generateRest<ApiActionObject>("actions");

export default {
	...rest,
	checkActionFilter: async (id: number, props: any) => {
		const query = qs.stringify({
			...props,
		});
		let result: any = await get(`actions/${id}/check-filter?${query}`);
		return result.allowed;
	},
	fetchActions: async (q: string, qWith: string[], props: any = {}) => {
		const query = qs.stringify({
			with: qWith.join(","),
			q,
			...props,
		});
		let items = {
			Backlog: [],
			"To-do": [],
			Doing: [],
			Done: [],
		};
		if (!hasFilter(props)) {
			return items;
		}

		let res: any = await get(`actions?${query}`);

		res.data.map((item) => {
			let status = item.status_name;
			if (items[status]) {
				items[status] = [...items[status], item];
			} else {
				items[status] = [item];
			}
			return item;
		});

		const local_key = "actions_sort";
		const sort: any = await localforage.getItem(local_key);

		let by = sort && sort.by ? sort.by : "deadline";
		let direction = sort && sort.direction ? sort.direction : "ASC";

		let newValue = {};
		Object.entries(items).map(([status, value]) => {
			if (by === "deadline") {
				newValue[status] = _.orderBy(
					value,
					(o) => {
						return new Date(o.deadline).getTime();
					},
					direction
				);
			} else {
				newValue[status] = _.orderBy(value, by, direction);
			}
			return true;
		});

		return newValue;
	},
	storeActionComment: (id: number, data: { message: string }) => {
		return post(`actions/${id}/comment`, data) as ApiResponse<
			ApiActionLogObject
		>;
	},
	listActionLogs: (id: number) => {
		return get(`actions/${id}/logs`) as ApiResponse<
			ApiList<ApiActionLogObject>
		>;
	},
	uploadAttachment: (id: number, file: File) =>
		upload(`actions/${id}/upload-attachment`, "file", file),
	storeDocuments: (id: number, document: ApiGoogleDriveDocumentObject) => {
		return post(`actions/${id}/document`, document) as ApiResponse<
			ApiList<ApiGoogleDriveDocumentObject>
		>;
	},
	deleteDocument: (id: number, documentId: number) => {
		return del(`actions/${id}/document/${documentId}`);
	},
	duplicate: (id: number, accounts: number[]) => {
		return post(`actions/${id}/duplicate`, {
			accounts,
		}) as ApiResponse<ApiActionObject>;
	},
};
