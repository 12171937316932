import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import { Segment, Header, Form } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import useGet from "../../../lib/useGet";
import Button from "../../../components/Form/Button";
import api from "../../../api/api";
import { toast } from "react-semantic-toasts";

const PagesCreateView = () => {
	const { id } = useParams();
	let url = id ? `admin/pages/${id}` : undefined;
	const [data, loading] = useGet(url);
	const [fetching, setFetching] = useState(false);
	const [name, setName] = useState(data?.name || "");

	useEffect(() => {
		if (data?.name) {
			setName(data.name);
		}
	}, [data]);

	const handleError = () => {
		toast({
			type: "error",
			title: "Something went wrong",
			description: "Please check all required fields",
			time: 0,
		});
	};

	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!name) return;

		setFetching(true);
		if (id) {
			api.admin.pages
				.update(Number(id), name)
				.then(() => {
					toast({
						type: "success",
						description: "Page updated succesfully",
						time: 2500,
					} as any);
				})
				.catch(handleError)
				.finally(() => setFetching(false));
		} else {
			api.admin.pages
				.create(name)
				.then(() => {
					toast({
						type: "success",
						description: "Page created succesfully",
						time: 2500,
					} as any);
					setName("");
				})
				.catch(handleError)
				.finally(() => setFetching(false));
		}
	};

	return (
		<Layout loading={loading}>
			<Segment>
				<Header as="h1">{id ? "Edit" : "New"} page</Header>
				<Form onSubmit={handleSubmit}>
					<Form.Input
						label="Name"
						placeholder="Name"
						onChange={(e, { value }) => setName(value)}
						value={name}
						required
						style={{ marginBottom: 10 }}
					/>
					<Button isLoading={fetching} onClick={handleSubmit}>
						{id ? "Edit" : "Save"}
					</Button>
				</Form>
			</Segment>
		</Layout>
	);
};

export default PagesCreateView;
