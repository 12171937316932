import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { SemanticToastContainer } from "react-semantic-toasts";
import * as Sentry from "@sentry/browser";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { subscribeToStore as prefetchSubscribe } from "./lib/prefetch";
import ErrorBoundary from "./components/ErrorBoundary";
import Auth from "./lib/Auth";
import Routes from "./Routes";
import "./base.scss";
import { Provider } from "react-redux";
import store from "./store";
import config from "./config.json";
import SocketBindings from "./components/SocketBindings";

Sentry.init({
	dsn: "https://1edad094413941538861caed2e386c92@sentry.io/5168568",
});

const App = () => {
	const [isFetching, setFetching] = useState(true);
	useEffect(() => {
		window.gapi.load("client:auth", () => {
			setFetching(false);
		});
	}, []);

	if (isFetching) {
		return <div />;
	}

	if (config.disableSentry) {
		return (
			<Provider store={store}>
				<SocketBindings />
				<SemanticToastContainer position="bottom-right" />
				<Routes />
			</Provider>
		);
	}

	return (
		<ErrorBoundary>
			<Provider store={store}>
				<SocketBindings />
				<SemanticToastContainer position="bottom-right" />
				<Routes />
			</Provider>
		</ErrorBoundary>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

declare global {
	interface Window {
		initGapi: () => void;
		__REDUX_DEVTOOLS_EXTENSION__: any;
		gapi: any;
		google: any;
	}
}

Auth.init();
prefetchSubscribe();
