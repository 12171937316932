import { ApiResponse } from "./api";
import { get, post } from "./utils";

export interface ApiSettingsObject {
	mail_assignee_allowed: boolean;
	mail_collaboration_allowed: boolean;
}

export default {
	settings: () => get("auth/settings") as ApiResponse<ApiSettingsObject>,
	postSettings: (data: any) =>
		post("auth/settings", data) as ApiResponse<ApiSettingsObject>,
};
