import React, { useState, useEffect } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import api from "../../../api/api";
import "./needdetail.scss";
import Kanban from "../../../components/Kanban";
import { emptyFilter } from "../../../api/filters";
import Statuses from "../../../components/Form/Statuses";
import ShowAction from "../../ActionsView/ShowAction";
import CreateAction from "../../ActionsView/CreateAction";
import Description from "../../../components/Layout/Description";
import NeedType from "./NeedType";
import EditableTitle from "../../../components/EditableTitle";
import AssigneePicker from "../../../components/Form/AssigneePicker";
import GenerateLink from "../../../components/GenerateLink";
import { useDispatch } from "react-redux";
import { setPresetFilter } from "../../../reducers/filters";
import KeyResults from "../../../components/Keyresults";
import useGet from "../../../lib/useGet";
import { Dropdown } from "semantic-ui-react";
import BadgeSelector from "../../../components/BadgeSelector";

type NeedDetailProps = {
	need: any;
	onClose: () => void;
	history: any;
	disabled?: boolean;
	onUpdate?: (need: any) => void;
};

const NeedDetail = ({ need, onClose, history, onUpdate }: NeedDetailProps) => {
	const dispatch = useDispatch();
	const [timeframes, setTimeframes] = useState([] as any);
	const [pillars, loadingPillars] = useGet("pillars");
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(0);
	const [copyNeed, setCopyNeed] = useState(need as any);
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState({});
	const [selected, setSelected] = useState({ id: undefined });
	
	useEffect(() => {
		fetchActions();
		api.listTimeframes(true).then((res) => {
			setTimeframes(res);
		});
		document.addEventListener(
			"keydown",
			(event) => {
				if (event.keyCode === 27) {
					onClose();
				}
			},
			false
		);
	}, [need]);

	const fetchActions = () => {
		let filters = emptyFilter;
		filters.needs = [need.id];
		if (need.id) {
			setCopyNeed(need);
			setLoading(true);
			api.fetchActions(
				filters.query,
				[
					"need",
					"squads",
					"chapters",
					"pages",
					"documents",
					"products",
				],
				filters
			).then((data) => {
				setItems(data);
				setLoading(false);
			});
		}
	};

	const handleNavigate = () => {
		dispatch(
			setPresetFilter({
				filter: { ...emptyFilter, needs: [need.id] },
				isNeed: false,
			})
		);
		history.push("/output");
	};

	const handleStatuses = (item: any) => {
		setCopyNeed({ ...copyNeed, ...item });
		api.updateNeed(copyNeed.id, item);
	};

	const updateAction = (action) => {
		if (action.status_name && items[action.status_name]) {
			setItems({
				...items,
				[action.status_name]: items[action.status_name].map((i) => {
					if (i.id === action.id) {
						return action;
					}
					return i;
				}),
			});
		}
	};

	const handleUpdateNeed = (key: string, value: any) => {
		let newValue = {
			...copyNeed,
			[key]: value,
		};
		setCopyNeed(newValue);
		if (onUpdate) {
			onUpdate(newValue);
		}
		api.updateNeed(need.id, { [key]: value }).then(() => {});
	};

	const handleNewAction = (action: any) => {
		let action_count = copyNeed.action_count;
		if (action.status_name) {
			action_count[action.status_name] =
				action_count[action.status_name] + 1;
		}
		if (onUpdate) {
			onUpdate({
				...copyNeed,
				action_count,
			});
		}
		setCopyNeed({
			...copyNeed,
			action_count,
		});
	};

	const formatPillarsValue = () => {
		let value = copyNeed?.pillars || [];
		if (value.length) {
			return value.map((i: any) => {
				if (typeof i === "number") {
					return i;
				}
				return i.id;
			});
		}

		return [];
	};

	return (
		<>
			<CreateAction
				need={need.id}
				visible={visible}
				onClose={(action) => {
					if (action) handleNewAction(action);
					setVisible(false);
				}}
				refetch={fetchActions}
			/>
			<ShowAction
				updateAction={updateAction}
				visible={selected && selected.id}
				refetch={fetchActions}
				value={selected}
				onClose={() => setSelected({ id: undefined })}
			/>
			<div
				className={`need-detail ${need.id ? "need-detail_active" : ""}`}
			>
				<div className="need-detail_head">
					<Tabs
						items={["Details", "Linked outputs"]}
						onChange={(index: number) => setTab(index)}
						active={tab}
					/>
					<div onClick={handleNavigate} className="link">
						<i className="fas fa-arrow-right"></i>
						Go to outputs
					</div>
					<div onClick={onClose} className="close">
						<i className="fal fa-times"></i>
					</div>
				</div>
				{tab === 0 && (
					<div className="need-detail_body">
						<label>Assigned person</label>
						<AssigneePicker
							value={copyNeed.assignee_id}
							onChange={(id) =>
								handleUpdateNeed("assignee_id", id)
							}
						/>
						<label>Title</label>
						<EditableTitle
							icon={<i className="fal fa-pencil" />}
							value={copyNeed.name}
							onBlur={(name) => handleUpdateNeed("name", name)}
						/>
						<NeedType
							value={copyNeed.scope}
							onChange={(scope: string) =>
								handleUpdateNeed("scope", scope)
							}
						/>
						<div style={{ marginTop: 10, marginBottom: 15 }}>
							<div>
								<label style={{ marginBottom: -5 }}>Timeframe from</label>
								<BadgeSelector
									value={copyNeed.timeframe_id}
									onChange={(value) =>
										handleUpdateNeed("timeframe_id", value.id)
									}
									data={timeframes}
									editable
								/>
							</div>
							<div style={{ marginTop: 10 }}>
								<label style={{ marginBottom: -5 }}>Timeframe to</label>
								<BadgeSelector
									value={copyNeed.timeframe_id_to}
									onChange={(value) =>
										handleUpdateNeed("timeframe_id_to", value.id)
									}
									data={timeframes}
									editable
								/>
							</div>
						</div>
						<label style={{ marginTop: 10 }}>Pillars</label>
						{pillars?.length && (
							<Dropdown
								loading={loadingPillars}
								multiple
								selection
								search
								clearable
								placeholder="Select pillars"
								value={formatPillarsValue()}
								onChange={(event: any, data: any) => {
									let value = data.value.map((i: number) => {
										let item = pillars.find(
											(n) => n.id === i
										);
										return item;
									});
									handleUpdateNeed("pillars", value);
								}}
								className="pillar-dropdown"
								options={pillars.map((i: any) => ({
									text: i.name,
									value: i.id,
								}))}
							/>
						)}
						{/* <div className="labels">
							<label>Labels</label>
							<div className="labels-box">
								<Labels
									show={showMode}
									changeShowMode={(value) =>
										setShowMode(value)
									}
									value={
										copyNeed.labels ? copyNeed.labels : []
									}
									placeholder="labels"
									setValue={handleLabels}
								/>
							</div>
						</div> */}
						<div className="description">
							<Description
								onUpdate={(text) =>
									handleUpdateNeed("description", text)
								}
								value={copyNeed.description}
							/>
						</div>

						<KeyResults
							results={copyNeed?.key_results || []}
							setResults={(val: any[]) => {
								setCopyNeed({
									...copyNeed,
									key_results: val,
								});
							}}
							onSave={(val) => {
								handleUpdateNeed(
									"key_results",
									val ? val : copyNeed.key_results
								);
							}}
						/>

						<div className="filters">
							<Statuses
								disabled={["Status", "Need"]}
								action={copyNeed}
								setAction={handleStatuses}
							/>
							<div className="filters-info">
								{need.created_by && (
									<div className="filters-info_wrapper">
										<label>Created by</label>
										<p>
											{need.created_by.avatar && (
												<img
													src={need.created_by.avatar}
													alt=""
												/>
											)}
											{need.created_by.full_name}
										</p>
									</div>
								)}

								<div className="filters-info_wrapper">
									<label>Created at</label>
									<p>
										{moment(need.created_at).format(
											"DD MMM YYYY"
										)}
									</p>
								</div>
							</div>
						</div>
						<div className="filters-info_wrapper">
							<GenerateLink
								url={`${window.location.origin}/outcome/${need.id}`}
							/>
						</div>
					</div>
				)}

				{tab === 1 && (
					<NeedDetailBoard
						items={items}
						setItems={setItems}
						setSelected={setSelected}
						openCreate={() => setVisible(true)}
						fetching={loading}
						refetch={fetchActions}
					/>
				)}
			</div>
		</>
	);
};

const NeedDetailBoard = ({
	items,
	setItems,
	setSelected,
	openCreate,
	fetching,
	refetch,
}: {
	items: any;
	setItems: (items: any) => void;
	setSelected: (item: any) => void;
	openCreate: () => void;
	fetching?: boolean;
	refetch: () => void;
}) => {
	// let filters = emptyFilter;
	// filters.needs = [needId];
	// const { fetching, data, setData } = useApi(
	// 	api.fetchActions(filters.query, ["need", "squad", "chapter"], filters),
	// 	[needId]
	// );
	return (
		<div className="need-detail_body">
			{/* <div className="buttons">
				<Button
					type="border"
					icon="fa-plus"
					onClick={() => openCreate()}
				>
					Create new action
				</Button>
			</div> */}

			{!fetching ? (
				<Kanban
					disabled={true}
					onClick={(action: any) => setSelected(action)}
					layout={"block"}
					items={items}
					refetch={refetch}
					onChange={(items: any) => {
						setItems(items);
					}}
				/>
			) : (
				<div className="loading">
					<i className="fal fa-spin fa-spinner-third"></i>
				</div>
			)}
		</div>
	);
};

type TabsProps = {
	items: string[];
	active: number;
	onChange: (index: number) => void;
};

const Tabs = ({ items, active, onChange }: TabsProps) => (
	<div className="need-tabs">
		{items.map((item, index) => (
			<div
				key={item + "Tab"}
				onClick={() => onChange(index)}
				className={`need-tabs_item ${
					active === index ? "need-tabs_item_active" : ""
				}`}
			>
				<h2>{item}</h2>
			</div>
		))}
	</div>
);

export default withRouter(NeedDetail);
