import React, { useState, useEffect } from "react";
import Button from "../Form/Button";
import { Input } from "semantic-ui-react";

const TodoForm = ({
	onAdd,
	loading,
	simple,
	forceVisible,
}: {
	onAdd: (value: string) => void;
	loading?: boolean;
	simple?: boolean;
	forceVisible?: boolean;
}) => {
	const [visible, setVisible] = useState(false);
	const [name, setName] = useState("");

	useEffect(() => {
		if (forceVisible !== undefined) {
			setVisible(forceVisible);
		}
	}, [forceVisible]);

	const handleSubmit = () => {
		if (name) {
			onAdd(name);
		}
		setVisible(false);
		setName("");
	};

	if (!visible) {
		return simple ? null : (
			<Button onClick={() => setVisible(true)} type="gray">
				Add item
			</Button>
		);
	}

	return (
		<div className="form-row">
			<form
				onSubmit={(e) => {
					if (e) {
						e.preventDefault();
					}
					handleSubmit();
				}}
			>
				<Input
					placeholder="Subject"
					onChange={(e, { value }) => setName(value)}
					value={name}
				/>
			</form>
			<Button
				type="gray"
				onClick={() => {
					setVisible(false);
					setName("");
				}}
			>
				Cancel
			</Button>
			<Button
				loading={loading}
				buttonType="submit"
				onClick={handleSubmit}
			>
				Add
			</Button>
		</div>
	);
};

export default TodoForm;
