import React, { useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect, useSelector } from "react-redux";

//Import redux functions
import { updateProfile } from "./reducers/auth";
import store from "./store";
import api from "./api/api";

//Views
import LoginView from "./views/LoginView";
import NeedsView from "./views/NeedsView";
import ActionsView from "./views/ActionsView";
import ValuePropositionView from "./views/ValuePropositionView";
// import AdminView from "./views/AdminView";
import Loading from "./components/Layout/Loading";
import SquadsView from "./views/ValuePropositionView/Squads";
import PrefetchView from "./views/PrefetchView";
import UsersView from "./views/Admin/UsersView/Users";
import UsersEditView from "./views/Admin/UsersView/UserEdit";
import Accounts from "./views/Admin/AccountsView/Accounts";
import AccountEdit from "./views/Admin/AccountsView/AccountEdit";
import Squads from "./views/Admin/SquadsView/Squads";
import SquadEdit from "./views/Admin/SquadsView/SquadEdit";
import Chapters from "./views/Admin/ChaptersView/Chapters";
import ChapterEdit from "./views/Admin/ChaptersView/ChapterEdit";
import Nav from "./components/Layout/Nav";
import Filter from "./components/Filter";
import LabelsView from "./views/Admin/LabelsView";
import DashboardView from "./views/Admin/DashboardView";
import { ApplicationState } from "./reducers";
import TimeframesView from "./views/Admin/TimeframesView";
import TimeframesCreateView from "./views/Admin/TimeframesView/TimeframesCreateView";
import PagesView from "./views/Admin/PagesView";
import PagesCreateView from "./views/Admin/PagesView/PagesCreateView";
import PillarsCreateView from "./views/Admin/PillarsView/PillarsCreateView";
import PillarsView from "./views/Admin/PillarsView";
import ProductsView from "./views/Admin/ProductsView";
import ProductsCreateView from "./views/Admin/ProductsView/ProductsCreateView";

export const REDIRECT_URL = "/outcome";

// Setup history
export const history = createBrowserHistory({
	getUserConfirmation: (message, callback) =>
		callback(window.confirm(message)),
});

let unblockCallback: any;

export function blockRouter(
	message: string = "have have entered some data, are you sure you want to leave the page?"
) {
	if (unblockCallback) {
		unblockCallback();
	}
	unblockCallback = history.block(message);
}

export function unblockRouter() {
	if (unblockCallback) {
		unblockCallback();
		unblockCallback = undefined;
	}
}

export function isRouterBlocked() {
	return !!unblockCallback;
}

export function setRouterBlock(b: boolean, message: string) {
	if (b) {
		blockRouter(message);
	} else {
		unblockRouter();
	}
}

history.listen((e) => {
	unblockRouter();
});

declare global {
	interface Window {
		browserHistory: History;
	}
}

const Routes = () => {
	const auth = useSelector((state: ApplicationState) => state.auth);
	const [fetching, setFetching] = useState(true);
	useEffect(() => {
		api.showAuthProfile()
			.then((profile: any) => {
				store.dispatch(
					updateProfile({
						id: profile.id,
						name: profile.full_name,
						email: profile.email_address,
						avatar: profile.avatar,
						roles: profile.roles,
						is_admin: profile.is_admin,
					})
				);
				setFetching(false);
			})
			.catch((e) => {
				setFetching(false);
			});
	}, []);

	if (fetching) {
		return <Loading />;
	}

	const routes = [] as any[];
	routes.push(<Route path="/" key="login" exact component={LoginView} />);

	if (auth.id) {
		routes.push(
			<Route
				path="/prefetch"
				key="prefetch"
				exact
				component={PrefetchView}
			/>
		);
		routes.push(
			<Route key="outcome" path="/outcome" exact component={NeedsView} />
		);
		routes.push(
			<Route
				key="outcome/search"
				path="/outcome/:search"
				exact
				component={NeedsView}
			/>
		);
		routes.push(
			<Route key="output" path="/output" exact component={ActionsView} />
		);
		routes.push(
			<Route
				key="output/id"
				path="/output/:id"
				exact
				component={ActionsView}
			/>
		);
		routes.push(
			<Route
				key="value-proposition"
				path="/value-proposition"
				exact
				component={SquadsView}
			/>
		);

		routes.push(
			<Route
				key="vpsquadsprop"
				path="/value-propositions/:squadId"
				exact
				component={ValuePropositionView}
			/>
		);
	}

	if (auth.is_admin) {
		routes.push(
			<Route
				path="/dashboard"
				key="dashboard"
				exact
				component={DashboardView}
			/>
		);
		// routes.push(
		// 	<Route path="/admin" key={"admin"} exact component={AdminView} />
		// );
		// routes.push(
		// 	<Route path="/admin" key={"admin"} exact component={AdminView} />
		// );
		routes.push(
			<Route key="labels" path="/labels" exact component={LabelsView} />
		);
		routes.push(
			<Route key="users" exact path="/users" component={UsersView} />
		);
		routes.push(
			<Route
				key="timeframes"
				exact
				path="/timeframes"
				component={TimeframesView}
			/>
		);
		routes.push(
			<Route
				key="timeframes/create"
				exact
				path="/timeframes/create"
				component={TimeframesCreateView}
			/>
		);
		routes.push(
			<Route
				key="timeframes/edit"
				exact
				path="/timeframes/:id?/edit"
				component={TimeframesCreateView}
			/>
		);

		routes.push(
			<Route key="pages" exact path="/pages" component={PagesView} />
		);
		routes.push(
			<Route
				key="pages/create"
				exact
				path="/pages/create"
				component={PagesCreateView}
			/>
		);
		routes.push(
			<Route
				key="pages/edit"
				exact
				path="/pages/:id?/edit"
				component={PagesCreateView}
			/>
		);

		routes.push(
			<Route
				key="products"
				exact
				path="/products"
				component={ProductsView}
			/>
		);
		routes.push(
			<Route
				key="products/create"
				exact
				path="/products/create"
				component={ProductsCreateView}
			/>
		);
		routes.push(
			<Route
				key="products/edit"
				exact
				path="/products/:id?/edit"
				component={ProductsCreateView}
			/>
		);

		routes.push(
			<Route
				key="pillars"
				exact
				path="/pillars"
				component={PillarsView}
			/>
		);
		routes.push(
			<Route
				key="pillars/create"
				exact
				path="/pillars/create"
				component={PillarsCreateView}
			/>
		);
		routes.push(
			<Route
				key="pillars/edit"
				exact
				path="/pillars/:id?/edit"
				component={PillarsCreateView}
			/>
		);

		routes.push(
			<Route
				key="users-edit"
				exact
				path="/users/:id/edit"
				component={UsersEditView}
			/>
		);
		routes.push(
			<Route key="accounts" exact path="/accounts" component={Accounts} />
		);
		routes.push(
			<Route
				key="accounts-edit"
				exact
				path="/accounts/:id/edit"
				component={AccountEdit}
			/>
		);
		routes.push(
			<Route key="squads" exact path="/squads" component={Squads} />
		);
		routes.push(
			<Route
				key="squads-edit"
				exact
				path="/squads/:id/edit"
				component={SquadEdit}
			/>
		);
		routes.push(
			<Route
				key="squads-create"
				exact
				path="/squads/create"
				component={SquadEdit}
			/>
		);
		routes.push(
			<Route key="chapters" exact path="/chapters" component={Chapters} />
		);
		routes.push(
			<Route
				key="chapters-edit"
				exact
				path="/chapters/:id/edit"
				component={ChapterEdit}
			/>
		);
		routes.push(
			<Route
				key="chapters-create"
				exact
				path="/chapters/create"
				component={ChapterEdit}
			/>
		);
	}
	// routes.push(<Route key="fallback" component={LoginView} />);

	return (
		<Router history={history}>
			<Nav />
			<Filter />
			<Switch>{routes}</Switch>
		</Router>
	);
};

export default connect(
	(state: any) => {
		return {
			auth: state.auth,
		};
	},
	{ updateProfile }
)(Routes);
