import { ApiAccountObject } from "./admin/accounts";
import { ApiUserObject } from "./admin/users";
import { generateRest, get } from "./utils";
import { hasFilter } from "./filters";
import { ApiLabelObject } from "./actions";

export type NeedTypes = "Item" | "Hurdle" | "Opportunity" | "Question";
export type NeedScope = "Individual" | "Team";

export interface ApiNeedObject {
	id?: number;
	account_id?: number;
	account?: ApiAccountObject;
	accounts?: ApiAccountObject[] | number[];
	created_by?: ApiUserObject | number;
	name?: string;
	type?: NeedTypes;
	scope?: NeedScope;
	description?: string;
	time_to_respond?: number;
	days_left_to_respond?: number;
	nps?: number;
	created_at?: string;
	action_count?: { [key: string]: number };
	squads?: any[];
	chapters?: any[];
	archived_at?: string;
	labels: ApiLabelObject[];
	assignee_id?: number;
	timeframe_id?: number;
	timeframe_id_to?: number;
	pillars?: any[];
	pages?: any[];
	products?: any[];
	key_results?: any[];
	progress: number;
	progress_calc: number;
}

export interface ApiMinimalNeed {
	id: number;
	name: string;
}

const rest = generateRest<ApiNeedObject>("needs", [
	"account",
	"chapter",
	"products",
]);
export default {
	...rest,
	list: (q = "", qWith: string[] = [], props: any = {}) => {
		if (!hasFilter(props)) {
			throw new Error("no filters");
		}
		return rest.list(q, qWith, props);
	},
	listMinimal: async (query = "") => {
		return get(`needs?minimal=1`).then((res: any) => {
			return res.data.map((item) => ({
				key: item.id,
				value: item.id,
				text: item.name,
			}));
		});
	},
};
