import { find } from "lodash";
import React, { useEffect, useState } from "react";
import "./badge-selector.scss";

const BadgeSelector = ({
	value,
	onChange,
	data,
	editable,
}: {
	value?: any;
	onChange: (v: any) => void;
	data: any[];
	editable?: boolean,
}) => {
	const [selected, setSelected] = useState<string>('None');
	const [edit, setEdit] = useState<boolean>(editable ? false : true);

	useEffect(() => {
		const s = find(data, { id: parseInt(value) });
		setSelected(s ? s.name : 'None');
	}, [value, data]);
	
	return (<div className="badge-selector">
		{editable && !edit ? (<div onClick={() => setEdit(true)} style={{ display: 'flex', alignItems: 'center' }}>
			<div className="badge-selector-item">
				{selected}
			</div>
			<i className="fal fa-pencil status-dropdown-icon" style={{ marginLeft: 0 }}></i>
		</div>) : data.map((i) => (
			<div
				key={i.id + "scope"}
				className={`badge-selector-item ${
					value === i.id && "badge-selector-item-active"
				}`}
				onClick={() => {
					onChange(i);
					setEdit(false)
				}}
			>
				{i.name}
			</div>
		))}
		{editable && edit && <i className="fal fa-times status-dropdown-icon" style={{ marginLeft: 0 }} onClick={() => setEdit(false)}></i>}
	</div>)
};

export default BadgeSelector;
